import React, { Component } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import Paginacao from '../components/Paginacao'
import { Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import GraphQLClient from './../components/GraphQLClient';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AbaseAutocompleteMultipleField } from 'abase-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { getConfig } from './config';

export function ListarTodasNoticias(props) {
  const query = `
    query NoticiaList($start:Int $limit:Int $asn_codigo:[Int]){
      psv_noticias{
        pagedList(sortBy:"ntc_datapublicacao" sortDirection:"desc" start:$start limit:$limit asn_codigo:$asn_codigo){
          data{
              ntc_codigo
              ntc_titulo
              ntc_datapublicacao
              ntc_resumo
              psv_assuntosnoticia{
                asn_codigo
                asn_cor
                asn_titulo
              }
              pgt_codigo{
                pgt_rotaacesso
              }
              arq_capa{
                psv_url,
                arq_fullname
              }
          }
          total
          
        }
      }
    }
    `
  const assuntoQuery = `query AssuntoList {
      psv_assuntonoticia {
        list(sortBy: "asn_titulo", apenasComNoticias: true) {
          asn_codigo
          asn_cor
          asn_titulo
        }
      }
    }`
  const page = props.match.params.page ? +props.match.params.page : 1
  const assuntos = props.match.params.assuntos ? props.match.params.assuntos.split(",").map(x => +x) : []
  
  const setAssuntos = (assuntos) => {
    props.history.push(`/noticias/1/${assuntos.join(",")}`)
  }
  const setPage = page => {
    props.history.push(`/noticias/${page}/${assuntos.join(",")}`)
  }
  const limit = 20
  return (
    <Container className="p-t-b-30" key={page}>
      <Helmet>
        <title>{'Notícias | ' + ((!!props.portalservicos && !!props.portalservicos.psv_headtitle) ? props.portalservicos.psv_headtitle : '')}</title>
      </Helmet>
      <div>
        <div className="colRow p-relative">
          <h3>Notícias</h3>
          <div className="detalhe"></div>
        </div>
        <div className="colRow">
          <GraphQLClient query={assuntoQuery}>
            {({ data, isLoading: loading }) => (
              <div className="m-b-15">
                <React.Fragment>
                  <AbaseAutocompleteMultipleField
                    isLoading={loading}
                    label={"Filtrar por assunto"}
                    data={_.get(
                      data,
                      "psv_assuntonoticia.list",
                      []
                    )}
                    value={_.get(
                      data,
                      "psv_assuntonoticia.list",
                      []
                    ).filter(x => assuntos.indexOf(x.asn_codigo) !== -1)}
                    onChange={values => setAssuntos(values.map(x => x.asn_codigo))}
                    textField={"asn_titulo"}
                    valueField={"asn_codigo"}
                    getOptionValue={x => x.asn_codigo}
                  />
                </React.Fragment>
              </div>
            )}
          </GraphQLClient>
          <GraphQLClient query={query} variables={{
            start: (page - 1) * limit,
            limit: limit,
            asn_codigo: assuntos && assuntos.length > 0 ? assuntos : undefined
          }}>
            {({ data, isLoading, error }) => {

            if (isLoading) {
              return <div className="text-center m-5"><Spinner animation="border" /></div>;
            }
            if (_.get(data, "psv_noticias.pagedList.data", []).length === 0) {
              return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Nenhum registro encontrado</Alert>;
            }
            return (<React.Fragment>
              {_.get(data, "psv_noticias.pagedList.data", []).map(x => (
                <div key={x.ntc_codigo} className="coList p-15">
                  <Row>
                    <Col xs={3} md={3} sm={7} className="wdMobile">
                      <Link to={"/noticia/" + _.get(x, "ntc_codigo") + "/" + _.get(x, "pgt_codigo.pgt_rotaacesso")}><img src={getConfig().urlDownloadImagem + _.get(x, "arq_capa.arq_fullname")} className={"iw100"} /></Link>
                    </Col>
                    <Col xs={9} md={9} sm={5} className="wdMobile mt15">
                      <Link to={"/noticia/" + _.get(x, "ntc_codigo") + "/" + _.get(x, "pgt_codigo.pgt_rotaacesso")}>
                        <h4>{x.ntc_titulo}</h4>
                        <small>Publicada em: <Moment format="DD/MM/YYYY hh:mm:ss">{x.ntc_datapublicacao}</Moment></small>
                        <p>{x.ntc_resumo}</p>
                      </Link>
                      <div className="colRow" style={{minHeight: '25px'}}>
                        {x.psv_assuntosnoticia.map((assunto, i) => (
                          <div className="tagCards" key={i} style={{backgroundColor: assunto.asn_cor, cursor: 'pointer'}} onClick={() => {assuntos.push(assunto.asn_codigo);setAssuntos(assuntos)}}>
                            {assunto.asn_titulo}
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>))}
                <div className="colRow m-t-15">
                  <Paginacao totalPages={_.get(data, "psv_noticias.pagedList.total", 0) > limit ? Math.ceil(_.get(data, "psv_noticias.pagedList.total", 0) / limit) : 1} currentPage={page} onChange={page => setPage(page)}></Paginacao>
                </div>              
            </React.Fragment>)
          }}</GraphQLClient>
        </div>
      </div>
    </Container>
  )
}

export default ListarTodasNoticias