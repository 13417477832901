import gql from "graphql-tag";

export const VIDEO_ALL = gql`
  query VideoList($query: String) {
    psv_videos {
      list(filter: $query, sortBy: "vid_data") {
        vid_codigo
        vid_data
        vid_link
        vid_resumo
        vid_titulo
        asn_codigo{
            asn_codigo
            asn_cor
            asn_titulo
        }
      }
    }
  }
`;

export const VIDEO_DELETE_ONE = gql`
  mutation VideoDelete($id: ID) {
    psv_videos {
      delete(id: [$id]) {
        success
      }
    }
  }
`;

export const VIDEO_SAVE = gql`
  mutation VideoSave($data: Psv_videosInputType) {
    psv_videos {
      save(data: [$data]) {
        vid_codigo
      }
    }
  }
`;

export const VIDEO_QUERY_BY_ID = gql`
  query VideoById($id: ID) {
    psv_videos {
      list(vid_codigo: $id) {
        vid_codigo
        vid_data
        vid_link
        vid_resumo
        vid_titulo
        asn_codigo{
            asn_codigo
            asn_cor
            asn_titulo
        }
      }
    }
  }
`;