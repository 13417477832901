import React, { Component, Fragment } from 'react';
import { Card, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getConfig } from '../pages/config';

import './Cards.css';

class CardNoticia extends Component {
  render() {
    return (
      <Fragment>
        <div className={"m-t-15 minWidth" + (!!this.props.widthCard ? " width" : "")}>
          <a href={getConfig().rotabase + `#/noticia/${this.props.noticia.ntc_codigo}`}>
            <Card.Img variant="top" className="imgFeeds" src={(!!this.props.noticia.arq_capa ? getConfig().urlDownloadImagem + this.props.noticia.arq_capa.arq_fullname : null)} height={215}/>
          </a>
          <div>
            <div className="colRow" style={{minHeight: '45px'}}>
              <a href={getConfig().rotabase + `#/noticia/${this.props.noticia.ntc_codigo}`}>
                <h5 className="text-uppercase m-b-0 m-t-5 t-overflow titulo"> {this.props.noticia.ntc_titulo} </h5>
              </a>
              <small className="text-muted small">
                Notícia incluída em: <b><Moment format="DD/MM/YYYY hh:mm:ss">{this.props.noticia.ntc_datapublicacao}</Moment></b>
              </small>
            </div>
            <div className="colRow" style={{minHeight: '25px'}}>
              {this.props.noticia.psv_assuntosnoticia.map((assunto, i) => (
                <a className="tagCards" key={i} style={{backgroundColor: assunto.asn_cor}} href={getConfig().rotabase + "#/noticias/1/" + assunto.asn_codigo}>
                  {assunto.asn_titulo}
                </a>
              ))}
            </div>
            <div className="colRow" style={{minHeight: '44px'}}>
              {!!this.props.noticia.ntc_resumo && 
              <div className="t-overflow colDivision">
                {this.props.noticia.ntc_resumo}
              </div>}
            </div>
            <div className="m-t-10">
              {!this.props.ocultarLinkTodas && (<a href={getConfig().rotabase + "#/noticias"}>
                <Button variant="outline-dark" className="pull-right" size="sm">
                  <FontAwesomeIcon icon="list"/> Listar Todas
                </Button>
              </a>)}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default CardNoticia
