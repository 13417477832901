import React, { Component, useState } from 'react';
import { Container, Spinner, Alert, Row, Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import GraphQLClient from './../components/GraphQLClient';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getConfig } from './config';
import Moment from 'react-moment';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export function VerAlbum(props) {
    const idAlbum = !!props.match.params.id ? props.match.params.id : null;

    const [indiceImagem, setIndiceImagem] = useState(0);
    const [abrirLightbox, setAbrirLightbox] = useState(false);

    const query = `
    {
        psv_albuns{
            list(alb_codigo: ${idAlbum}){
                alb_codigo,
                alb_data,
                alb_nome,
                psv_albumarquivos{
                    alq_codigo,
                    alq_ordem,
                    arq_codigo{
                        arq_codigo,
                        arq_descricao,
                        arq_nome,
                        arq_fullname
                    }
                }
            }
        }
    }
    `;

    const exibirImagens = (indice) => {
        setIndiceImagem(indice);
        setAbrirLightbox(true);
    }

    return (
        <Container className="p-t-b-30">
            <GraphQLClient query={query}>
            {({ data, isLoading, error }) => {
                if (isLoading) {
                    return <div className="text-center m-5"><Spinner animation="border" /></div>;
                }
                if (_.get(data, "psv_albuns.list", []).length === 0) {
                    return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Nenhum registro encontrado</Alert>;
                }

                let listaFotosAlbum = [];
                _.get(data, "psv_albuns.list[0].psv_albumarquivos", []).map(imagem => {
                    listaFotosAlbum.push(getConfig().urlDownloadImagem + imagem.arq_codigo.arq_fullname);
                });

                return (
                    <div>
                    <Helmet>
                        <title>{`Álbum: ${data.psv_albuns.list[0].alb_nome} | ` + ((!!props.portalservicos && !!props.portalservicos.psv_headtitle) ? props.portalservicos.psv_headtitle : '')}</title>
                    </Helmet>
                    {_.get(data, "psv_albuns.list", []).map(x => (
                        <div key={x.alb_codigo}>
                            <div>
                                <div className="colRow p-relative">
                                    <h3><a href="javascript:history.back()"><FontAwesomeIcon icon="arrow-left"/></a>&nbsp;&nbsp;Album: {x.alb_nome}</h3>
                                    <div className="detalhe"></div>
                                </div>
                                <div className="colRow mb-2">
                                    <small>Cadastrado em: <b><Moment format="DD/MM/YYYY hh:mm:ss">{x.alb_data}</Moment></b></small>
                                </div>
                                <div className="colRow m-t-15">
                                    {x.psv_albumarquivos.map((arquivo, i) => (
                                        <Col sm={3} key={arquivo.alq_codigo} className="rowImages">
                                            <img src={getConfig().urlDownloadImagem + _.get(arquivo, "arq_codigo.arq_fullname")} onClick={() => exibirImagens(i)} />
                                        </Col>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                            {abrirLightbox && (
                            <Lightbox
                                mainSrc={listaFotosAlbum[indiceImagem]}
                                nextSrc={listaFotosAlbum[(indiceImagem + 1) % listaFotosAlbum.length]}
                                prevSrc={listaFotosAlbum[(indiceImagem + listaFotosAlbum.length - 1) % listaFotosAlbum.length]}
                                onCloseRequest={() => setAbrirLightbox(false) }
                                onMovePrevRequest={() => setIndiceImagem((indiceImagem + listaFotosAlbum.length - 1) % listaFotosAlbum.length) }
                                onMoveNextRequest={() => setIndiceImagem((indiceImagem + 1) % listaFotosAlbum.length) }
                            />
                            )}
                    </div>
                    )
                }}
            </GraphQLClient>
        </Container>
    )
}

export default VerAlbum;