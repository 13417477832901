import React, { useState } from 'react';
import { Alert, Spinner, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Lightbox from 'react-image-lightbox';

import { GraphQLClient } from '.';
import { getConfig } from '../pages/config';

import 'react-image-lightbox/style.css';

export default function Album(props){
    
    const [indiceImagem, setIndiceImagem] = useState(0);
    const [abrirLightbox, setAbrirLightbox] = useState(false);

    const exibirImagens = (indice) => {
        setIndiceImagem(indice);
        setAbrirLightbox(true);
    }

    const conteudo = JSON.parse(props.conteudo);

    const query = `
    {
        psv_albuns {
            list(alb_codigo: ${conteudo.alb_codigo}){
              alb_codigo
              alb_data
              alb_nome
              arq_capa{
                arq_fullname
              }
              psv_albumarquivos{
                alq_codigo
                alq_ordem
                arq_codigo{
                  arq_codigo
                  arq_descricao
                  arq_fullname
                  arq_nome
                }
              }
            }
        }
    }
    `;

    return (
        <GraphQLClient query={query}>
        {({ data, isLoading, error }) => {
            if (isLoading) {
                return <div className="text-center m-5"><Spinner animation="border" /></div>;
            }
            if (!data) {
                return <div className="text-center m-5"><Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Album não encontrado!</Alert></div>;
            }

            let listaFotosAlbum = [];
            _.get(data, "psv_albuns.list[0].psv_albumarquivos", []).map(imagem => {
                listaFotosAlbum.push(getConfig().urlDownloadImagem + imagem.arq_codigo.arq_fullname);
            });

            return (
                <div>
                    <div>
                        <div className="colRow p-relative">
                            <h3>{data.psv_albuns.list[0].alb_nome}</h3>
                            <div className="detalhe"></div>
                        </div>
                        <div className="colRow m-t-15">
                        {data.psv_albuns.list[0].psv_albumarquivos.map((arquivo, i) => (
                                <Col sm={3} key={arquivo.alq_codigo} className="rowImages">
                                    <img src={getConfig().urlDownloadImagem + _.get(arquivo, "arq_codigo.arq_fullname")} onClick={() => exibirImagens(i)} />
                                </Col>
                            ))}
                        </div>
                    </div>
                    {abrirLightbox && (
                        <Lightbox
                            mainSrc={listaFotosAlbum[indiceImagem]}
                            nextSrc={listaFotosAlbum[(indiceImagem + 1) % listaFotosAlbum.length]}
                            prevSrc={listaFotosAlbum[(indiceImagem + listaFotosAlbum.length - 1) % listaFotosAlbum.length]}
                            onCloseRequest={() => setAbrirLightbox(false) }
                            onMovePrevRequest={() => setIndiceImagem((indiceImagem + listaFotosAlbum.length - 1) % listaFotosAlbum.length) }
                            onMoveNextRequest={() => setIndiceImagem((indiceImagem + 1) % listaFotosAlbum.length) }
                        />
                        )}
                </div>
            )
        }}
        </GraphQLClient>
    )
}