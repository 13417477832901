import gql from 'graphql-tag';

export const NEWSLETTER_ALL = gql`
query NewsletterList($psv_codigo:Int, $query:String){
    psv_newsletter {
        list(psv_codigo:$psv_codigo, filter:$query) {
          nws_codigo
          nws_assunto
          nws_data
          nws_enviar
          nws_dataenvio
        }
    }
}
`

export const NEWSLETTER_QUERY_BY_ID = gql`
query NewsletterList($id: ID){
    psv_newsletter {
        list(nws_codigo:$id) {
          nws_codigo
          nws_assunto
          nws_data
          nws_enviar
          nws_dataenvio
          nws_mensagem
        }
    }
}
`

export const NEWSLETTER_DELETE_ONE = gql`
mutation NewsletterDelete($id: ID) {
  psv_newsletter {
    delete(id:[$id]) {
      success
    }
  }
}
`

export const NEWSLETTER_SAVE = gql`
mutation NewsletterSave($data: Psv_newsletterInputType) {
  psv_newsletter {
    save(data:[$data]) {
      nws_codigo
    }
    
  }
}
`
