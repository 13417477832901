import React, { Fragment, useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { getConfig } from '../pages/config';

import 'react-image-lightbox/style.css';

export default function Imagem(props) {

    const [abrirLightbox, setAbrirLightbox] = useState(false);

    return (
        <Fragment>
            <img style={{cursor: 'pointer'}} src={getConfig().urlDownloadImagem + props.url} alt="imagem" className="col-12" onClick={() => setAbrirLightbox(true) } />
            {abrirLightbox && (
                <Lightbox
                    mainSrc={getConfig().urlDownloadImagem + props.url}
                    onCloseRequest={() => setAbrirLightbox(false) }
                />
                )}
        </Fragment>
    )
}