import React, { Fragment } from 'react';
import { Container, Spinner, Alert } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import ReactPlayer from 'react-player';

import { getConfig } from './config';
import GraphQLClient from './../components/GraphQLClient';

export default function VerVideo(props) {

    const idVideo = !!props.match.params.id ? props.match.params.id : null;

    const query = `
    {
        psv_videos{
            list(vid_codigo: ${idVideo}){
                vid_data
                vid_link
                vid_resumo
                vid_titulo
                asn_codigo{
                    asn_codigo
                    asn_cor
                    asn_titulo
                }
            }
        }
    }
    `;

    return (
        <Container className="p-t-b-30">
            <GraphQLClient query={query}>
            {({ data, isLoading, error }) => {
                if (isLoading) {
                    return <div className="text-center m-5"><Spinner animation="border" /></div>;
                }
                if (_.get(data, "psv_videos.list", []).length === 0) {
                    return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Vídeo não encontrado</Alert>;
                }
                return (
                    <div>
                        <Helmet>
                            <title>{`Vídeo: ${data.psv_videos.list[0].vid_titulo} | ` + ((!!props.portalservicos && !!props.portalservicos.psv_headtitle) ? props.portalservicos.psv_headtitle : '')}</title>
                        </Helmet>
                        <div className="colRow">
                            <div className="colRow p-relative">
                                <h3>
                                    {_.get(data, "psv_videos.list[0].vid_titulo", "")}
                                </h3>
                                <div className="detalhe"></div>
                            </div>
                            <small className="text-muted">Vídeo cadastrado em: <b><Moment format="DD/MM/YYYY hh:mm:ss">{_.get(data, "psv_videos.list[0].vid_data", "")}</Moment></b></small>
                            {!!_.get(data, "psv_videos.list[0].asn_codigo", null) &&
                                <div className="colRow m-t-15 p-relative">
                                    <Fragment>
                                        <a className="btn b-white btn-sm m-b-15" style={{backgroundColor: _.get(data, "psv_videos.list[0].asn_codigo.asn_cor", null)}} href={getConfig().rotabase + "#/videos/1/" + _.get(data, "psv_videos.list[0].asn_codigo.asn_codigo", null)}>{_.get(data, "psv_videos.list[0].asn_codigo.asn_titulo", null)}</a>&nbsp;
                                    </Fragment>
                                </div>
                            }
                        </div>

                        {!!_.get(data, "psv_videos.list[0].vid_resumo", null) &&
                            <div className="colRow">
                                <p>
                                    {_.get(data, "psv_videos.list[0].vid_resumo", null)}
                                </p>
                            </div>
                        }

                        <div className="colRow">
                            <div style={{height: '450px'}}>
                                <ReactPlayer class="imgFeeds" controls={true} url={_.get(data, "psv_videos.list[0].vid_link", null)} height="100%" width="100%" />
                            </div>
                        </div>

                    </div>
                );
            }}
            </GraphQLClient>
        </Container>
    );
}