import _ from "lodash";


export function redirectBackForm(history, match) {
    const count = (str, ch) => _.countBy(str)[ch] || 0;
    const isInsideAnotherRoute = count(match.pathname, '/') >= 5
    if (isInsideAnotherRoute) {
        const editRegex = /\/\w+\/edit\/[\w\d-]+\/?$/gm
        const deleteRegex = /\/\w+\/delete\/[\w\d-,]+\/?$/gm
        const addRegex = /\/\w+\/add\/?$/gm
        let newRoute = match.pathname
        if (editRegex.test(newRoute)) {
            history.push(newRoute.replace(editRegex, ""))
        } else if (addRegex.test(newRoute)) {
            history.push(newRoute.replace(addRegex, ""))
        } else if (deleteRegex.test(newRoute)) {
            history.replace(newRoute.replace(deleteRegex, ""))
        } else {
            alert("Rota não identificada")
            throw new Error("Rota não identificada")
        }
    } else {

        const editRegex = /\/manutencao-[a-z]+\/[\w\d-]+\/?$/gm
        const deleteRegex = /\/delete\/[\w\d-,]+\/?$/gm
        const addRegex = /\/manutencao-[a-z]+\/?$/gm
        let newRoute = match.pathname
        if (editRegex.test(newRoute)) {
            history.push(newRoute.replace(editRegex, ""))
        } else if (addRegex.test(newRoute)) {
            history.push(newRoute.replace(addRegex, ""))
        } else if (deleteRegex.test(newRoute)) {
            history.replace(newRoute.replace(deleteRegex, ""))
        } else {
            alert("Rota não identificada")
            throw new Error("Rota não identificada")
        }
    }

}