import gql from 'graphql-tag';

export const INSCRITOSNEWSLETTER_ALL = gql`
query InscritosNewslatterList($portalId:Int, $query:String){
    psv_inscritosnewsletter {
        list(psv_codigo:$portalId, filter:$query) {
            inw_codigo
            inw_nome
            inw_email
            inw_datainscricao
            inw_ativo
        }
    }
}
`
export const INSCRITOSNEWSLETTER_SAVE = gql`
mutation InscritosNewslatterSave($data: Psv_inscritosnewsletterInputType) {
  psv_inscritosnewsletter {
    save(data:[$data]) {
      inw_codigo
    }
    
  }
}
`
