import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class Midia extends Component {
  render() {
    return (
      <div className="b-1">
        <ReactPlayer url={this.props.url} controls={true} pip={true} width={'100%'}/>
      </div>
    )
  }
}

export default Midia
