import React from 'react';

export default function Titulo(props) {
  return (
    <div className="colRow text-center p-relative">
        <h2>
            {props.conteudo}
        </h2>
        <div className="detalhe"></div>
    </div>
  );
}
