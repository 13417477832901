import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown, Spinner, Alert } from 'react-bootstrap';
import { GraphQLClient } from './';
import { getConfig } from '../pages/config';

class Menu extends Component {

    renderizaMenuDropDown = (itemMenu, dropConfig) => {
      return (
        <NavDropdown key={itemMenu.itm_codigo} title={itemMenu.itm_nome} drop={dropConfig} id="basic-nav-dropdown">
          {itemMenu.itm_filhos.map((menu, i) => {
            if (!!menu.itm_filhos === false || menu.itm_filhos.length === 0) return <NavDropdown.Item key={i} href={(!!menu.pgt_redirecionar === true ? getConfig().rotabase + '#' + menu.pgt_redirecionar.pgt_rotaacesso : (!!menu.itm_link ? menu.itm_link : '/'))} target={(!!menu.itm_target ? menu.itm_target : '_self')}>{menu.itm_nome}</NavDropdown.Item>;
            else return this.renderizaMenuDropDown(menu, "right");
          })}
        </NavDropdown>
      )
    }

  render() {
    let conteudo = typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo;
    return (
      <GraphQLClient query={`
      {
        psv_menuportal{
          list(msv_codigo:${conteudo.msv_codigo}, itm_ativo:true){
            msv_codigo
            msv_nome
            msv_tipo
            msv_itens
          }
        }
      }
      `}>
      {({ data, isLoading, error }) => {
        if (isLoading) {
          return <div className="text-center m-5"><Spinner animation="border" /></div>;
        }
        if (!data) {
          return <div className="text-center m-5"><Alert variant="warning">Ooops! Menu não Encontrado!</Alert></div>;
        }
        if (data) {
          let estruturaMenu = JSON.parse(data.psv_menuportal.list[0].msv_itens);
          return (
            <Navbar> 
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className={conteudo.msv_tipo > 0 ? "flex-column" : ""}>
                    {estruturaMenu.map((item, i) => {
                        if (!!item.itm_filhos === false || item.itm_filhos.length === 0) return <Nav.Link key={i} href={(!!item.pgt_redirecionar === true ? getConfig().rotabase + '#' + item.pgt_redirecionar.pgt_rotaacesso : (!!item.itm_link ? item.itm_link : '/'))} target={(!!item.itm_target ? item.itm_target : '_self')}>{item.itm_nome}</Nav.Link>;
                        else if (!!item.itm_filhos === true || item.itm_filhos.length > 0) return this.renderizaMenuDropDown(item, "");
                    })}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
          )
        }
        if (error) {
          return <p>{error.message}</p>;
        }
      }}
      </GraphQLClient>
    )
  }
}

export default Menu;
