import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
// import browserHistory from './browserHistory';
import { getConfig } from './config';


const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'none',
    },
    mutate: {
        errorPolicy: 'all',
    },
};



// const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists
//     const user = localStorage.getItem('user');
//     if (!user) {
//         return { headers }
//     }
//     try {
//         const userJson = JSON.parse(user)

//         const token = userJson.token
//         // return the headers to the context so httpLink can read them
//         return {
//             headers: {
//                 ...headers,
//                 authorization: `Bearer ${token}`,
//             }
//         }

//     } catch (e) {
//         return { headers }
//     }
// });

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
    if (networkError) console.error(`[Network error]: ${networkError} ${networkError.statusCode}`);
    if (networkError && (networkError.statusCode === 401 || networkError.statusCode === 403)) {
        // browserHistory.replace("/login")
    }

})

const link = ApolloLink.from([
    // authLink.concat(errorLink),
    errorLink,
    new HttpLink({
        uri: getConfig().apiUrl,
        credentials: 'same-origin'
    })
])
const cache = new InMemoryCache()
const client = new ApolloClient({
    link,
    cache,
    defaultOptions
});

export { client };
