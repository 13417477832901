import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AbaseDateTimePickerField, AbaseTextField, FormDraft } from "abase-components";
import "filepond/dist/filepond.min.css";
import _ from "lodash";
import { default as React } from "react";
import { Query } from "react-apollo";
import { Alert, Badge, Button, Col, Form } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FilePond } from "react-filepond";
import { Field } from "react-final-form";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import * as Yup from "yup";
import { defaultDecorators, redirectBackForm } from "../../services";
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo, showAlertMsg } from "../../services/errorMessage";
import { cast, validate } from "../../services/validate";
import { client } from "../apollo";
import { getConfig } from "../config";
import { ALBUM_QUERY_BY_ID, ALBUM_SAVE } from "./AlbumGql";
import "./ManutencaoAlbum.scss";
const AlbumSchema = Yup.object()
  .shape({
    alb_codigo: Yup.number().nullable(),
    alb_nome: Yup.string()
      .required()
      .label("Nome"),
    alb_data: Yup.date()
      .required()
      .label("Data"),
    arq_capa: Yup.object()
      .shape({
        arq_codigo: Yup.number()
      })
      .noUnknown(),
    psv_albumarquivos: Yup.array(
      Yup.object()
        .shape({
          alq_codigo: Yup.number(),
          alq_ordem: Yup.number(),
          arq_codigo: Yup.object()
            .shape({
              arq_codigo: Yup.number()
            })
            .noUnknown()
        })
        .noUnknown()
    )
  })
  .noUnknown();

const gridStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridGap: "10px"
};

const gridItemStyles = {
  backgroundColor: "#f1f0ef",
  border: "8px solid #f1f0ef"
};
const DragHandle = sortableHandle(() => (
  <Button variant="outline-secondary" size="sm" title={"Pressione para ordenar"}>
    <FontAwesomeIcon icon="arrows-alt" />
  </Button>
));
const SortableItem = sortableElement(props => {
  let style = gridItemStyles;
  if (props.isCapa) {
    style = { ...style, border: "8px solid #17a2b8" };
  }
  return (
    <div style={style}>
      <div style={{ overflow: "hidden", width: "100%", height: 200, textAlign: 'center', backgroundColor: 'white' }}>
        <img
          src={_.get(props.value, "arq_codigo.psv_url")}
          className={"imge"}
          alt={"Foto"}
        />
      </div>
      <div className={"buttons-album"}>
        {props.isCapa ? <Badge variant={"info"}>FOTO CAPA</Badge> : null}
        <DragHandle />
        <Button
          variant="outline-primary"
          disabled={props.isCapa}
          onClick={props.onSetarCapa}
          title={"Setar como capa"}  size="sm"
        >
          <FontAwesomeIcon icon="images" />
        </Button>
        <Button variant="outline-danger" size="sm" title={"Excluir"} onClick={props.onDelete}>
          <FontAwesomeIcon icon="trash" />
        </Button>
      </div>
    </div>
  );
});
const SortableContainer = sortableContainer(({ children }) => {
  return <div style={gridStyles}>{children}</div>;
});
function ManutencaoAlbum({ match: { params }, history, location }) {
  const handleSubmit = async values => {
    try {
      if (!values.arq_capa) {
        showAlertMsg({ message: "Selecione uma imagem para a capa" });
        return;
      }

      const mutationData = await cast(AlbumSchema, values);

      await client.mutate({
        mutation: ALBUM_SAVE,
        variables: { data: { ...mutationData, psv_codigo: { psv_codigo: getConfig().idPortal } } }
      });
      
      showInfo(defaultSuccessMessage("save"));
      redirectBackForm(history, location);

    } catch (err) {
      showError({ message: defaultErrorMessage("save", err) });
    }
  };

  const notEditting = !params.albumId;
  const id = params.albumId;
  
  return (
    <Query
      query={ALBUM_QUERY_BY_ID}
      skip={notEditting}
      onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
      }}
      onCompleted={data => {
        if (!notEditting && !_.get(data, "psv_albuns.list[0]")) {
          showError({ message: defaultErrorMessage("not_found") });
          redirectBackForm(history, location);
        }
      }}
      variables={{ id }}
    >
      {({ data, loading }) => (
        <div className="colPageAdm">
          <FormDraft
            useDraft={notEditting}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true
            }}
            validate={validate(AlbumSchema)}
            initialValues={
              _.get(data, "psv_albuns.list[0]") || {
                psv_albumarquivos: [],
                alb_data: new Date().toISOString()
              }
            }
            decorators={defaultDecorators}
            render={({ handleSubmit, form, submitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <div className="colRow p-relative">
                    <h3>Manutenção de Álbum</h3>
                    <div className="detalhe"></div>
                  </div>
                  <Col sm={6} xs={12}>
                    <Field name={"alb_nome"}>
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Nome"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"alb_data"}>
                      {({ input, meta }) => (
                        <AbaseDateTimePickerField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Data"}
                        />
                      )}
                    </Field>
                  </Col>
                </Form.Row>
                <Field name={"arq_capa"}>
                  {({ input: inputCapa }) => (
                    <Field name={"psv_albumarquivos"}>
                      {({ input, meta }) => (
                        <React.Fragment>
                          <FilePond
                            name="image"
                            className="insertArqMultiMntAlbum"
                            acceptedFileTypes={["image/*"]}
                            allowMultiple={true}
                            allowRevert={false}
                            server={{
                              url: getConfig().urlUploadImagem,
                              process: {
                                headers: {
                                  'imageSize': '1024',
                                  'psv_codigo': getConfig().idPortal
                                }
                              }
                            }}
                            onprocessfile={(erro, arquivo) => {
                              if (erro) {
                                return;
                              }
                              arquivo.abortLoad();
                              let dadosUpload = JSON.parse(arquivo.serverId);
                              input.onChange([
                                ...input.value,
                                {
                                  alq_ordem: input.value.length,
                                  arq_codigo: {
                                    arq_codigo: dadosUpload.data.arq_codigo,
                                    psv_url: dadosUpload.data.link
                                  }
                                }
                              ]);
                              if (!inputCapa.value) {
                                inputCapa.onChange({
                                  arq_codigo: dadosUpload.data.arq_codigo,
                                  psv_url: dadosUpload.data.link
                                });
                              }
                            }}
                            labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> Clique para selecionar o arquivo </span>"
                            labelInvalidField="Você adicionou arquivos inválidos"
                            labelFileWaitingForSize="Lendo tamanho do arquivo..."
                            labelFileLoading="Carregando..."
                            labelFileLoadError="Erro ao carregar o arquivo"
                            labelFileProcessing="realizando upload de arquivo"
                            labelFileProcessingComplete="Upload concluído"
                            labelFileProcessingAborted="Upload cancelado!"
                            labelFileProcessingError="Erro ao realizar o upload"
                            labelFileRemoveError="Erro ao remover o arquivo"
                            labelTapToCancel="Cancelar"
                            labelTapToRetry="Toque para tentar novamente"
                            labelTapToUndo="Toque para desfazer"
                            labelButtonRemoveItem="Remover"
                            labelButtonAbortItemLoad="Abortar"
                            labelButtonRetryItemLoad="Tentar novamente"
                            labelButtonAbortItemProcessing="Cancelar"
                            labelButtonUndoItemProcessing="Desfazer"
                            labelButtonRetryItemProcessing="Tentar novamente"
                          />
                          {input.value.length === 0 ? (
                            <Alert variant={"info"} className="text-center m-1">
                              Ainda não há nenhuma imagem para este álbum
                            </Alert>
                          ) : null}
                          <div
                            style={{
                              position: "relative",
                              display: "block"
                            }}
                          >
                            <SortableContainer
                              onSortEnd={({ oldIndex, newIndex }) => {
                                let newValue = arrayMove(
                                  input.value,
                                  oldIndex,
                                  newIndex
                                );
                                newValue = newValue.map((x, i) => ({
                                  ...x,
                                  alq_ordem: i
                                }));
                                input.onChange(newValue);
                              }}
                              axis="xy"
                              useDragHandle
                            >
                              {input.value.map((x, i) => (
                                <React.Fragment>
                                  <SortableItem
                                    value={x}
                                    index={i}
                                    key={_.get(x, "arq_codigo.arq_codigo")}
                                    isCapa={
                                      inputCapa.value &&
                                      inputCapa.value.arq_codigo ===
                                      x.arq_codigo.arq_codigo
                                    }
                                    onSetarCapa={() => {
                                      inputCapa.onChange(x.arq_codigo);
                                    }}
                                    onDelete={() => {
                                      if (
                                        inputCapa.value.arq_codigo ===
                                        x.arq_codigo.arq_codigo
                                      ) {
                                        const possibleValues = input.value.filter(
                                          y =>
                                            x.arq_codigo.arq_codigo !==
                                            y.arq_codigo.arq_codigo
                                        );
                                        inputCapa.onChange(
                                          possibleValues.length > 0
                                            ? possibleValues[0].arq_codigo
                                            : null
                                        );
                                      }
                                      input.onChange(
                                        input.value.filter(
                                          y =>
                                            x.arq_codigo.arq_codigo !==
                                            y.arq_codigo.arq_codigo
                                        )
                                      );
                                    }}
                                  />
                                </React.Fragment>
                              ))}
                            </SortableContainer>
                          </div>
                        </React.Fragment>
                      )}
                    </Field>
                  )}
                </Field>
                <div className="text-right m-t-30">
                  <Button type={"button"} variant="secondary"size="sm" onClick={() => redirectBackForm(history, location)}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                  &nbsp;&nbsp;
                  <Button type={"submit"} variant="success" size="sm" disabled={submitting} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar</Button>
                </div>
              </Form>
            )}
          />
        </div>
      )}
    </Query>
  );
}

export default ManutencaoAlbum;
