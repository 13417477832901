import React, { Component } from 'react';
import { ButtonToolbar, Button, Alert, Spinner, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GraphQLClient from './GraphQLClient';
import { getConfig } from '../pages/config';

class AcessoRapido extends Component {

  classePosicaoIcone = (posicao) => {
    switch (posicao) {
      case 0:
        return "right"
        break;
      case 1:
        return "left"
        break;
      case 2:
        return "top"
        break;
      default:
        return "left"
        break;
    }
  }

  render() {

    let dadosMenu = !!this.props.conteudo ? (typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo) : null;
    if (!!dadosMenu === false) return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;O menu a ser utilizado para este acesso rápido não foi definido!</Alert>;
    else return (
      <div>
        <div className="colRow">
          <div className="colRow text-center p-relative">
              <h2>
                <FontAwesomeIcon icon="list"/> {!!dadosMenu.titulo ? dadosMenu.titulo : "Acesso Rápido"} <FontAwesomeIcon icon="list"/>
              </h2>
              <div className="detalhe"></div>
          </div>
          <div className="colRow m-t-15">     
              <GraphQLClient query={`
                {
                  psv_menuportal{
                    list(msv_codigo:${dadosMenu.msv_codigo}, itm_ativo:true){
                      msv_codigo
                      msv_nome
                      msv_tipo
                      msv_itens
                    }
                  }
                }
              `}>
              {({ data, isLoading, error }) => {
                if (isLoading) {
                  return <div className="text-center m-5"><Spinner animation="border" /></div>;
                }
                if (!data) {
                    return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;O menu a ser utilizado para este acesso rápido não foi definido!</Alert>;
                }
                if (data) {
                  let itens = data.psv_menuportal.list[0].msv_itens;
                  if (!!itens){
                    let itensMenu = JSON.parse(itens);
                    let classeMenu = "list-Acesso";
                    if (data.psv_menuportal.list[0].msv_tipo > 0) classeMenu += " vertical";
                    return <div className="colRow text-center">
                      <ul className={classeMenu}>
                      {itensMenu.map((item, i) => (
                        <li key={i} className={this.classePosicaoIcone(item.itm_posicaoicone)}>
                          <a href={(!!item.pgt_redirecionar ? getConfig().rotabase + "#" + item.pgt_redirecionar.pgt_rotaacesso : !!item.itm_link ? item.itm_link : '')} target={(!!item.itm_target ? item.itm_target : "_self")}>
                            <Image src={!!item.itm_icone ? item.itm_icone : "./assets/img/pagenotfound.png"} />
                            <p className="t-overflow">{item.itm_nome}</p>
                          </a>
                        </li>
                      ))}  
                      </ul>
                    </div>
                  }
                  else{
                    return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Os itens do menu ainda não foram definidos!</Alert>;
                  }
                }
                if (error) {
                  return <p>{error.message}</p>;
                }

              }}
              </GraphQLClient>
          </div>
        </div>
      </div>
    )
  }
}

export default AcessoRapido