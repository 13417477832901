import { TipoComponente } from '../../enums';
export const menuComponentes = [
    {tipo: TipoComponente.html, icone: 'code', label: 'HTML'},
    {tipo: TipoComponente.IFrame, icone: 'file', label: 'IFrame'},
    {tipo: TipoComponente.menu, icone: 'bars', label: 'Menu'},
    {tipo: TipoComponente.acessoRapido, icone: 'grip-horizontal', label: 'Acesso Rápido'},
    {tipo: TipoComponente.assuntosRelacionados, icone: 'hashtag', label: 'Assuntos Relacionados'},
    {tipo: TipoComponente.banner, icone: 'square', label: 'Banner'},
    {tipo: TipoComponente.slideBanner, icone: 'clone', label: 'Slide Banner'},
    {tipo: TipoComponente.ultimaNoticiaAssunto, icone: 'newspaper', label: 'Última Notícia'},
    {tipo: TipoComponente.midia, icone: 'play-circle', label: 'Mídia'},
    {tipo: TipoComponente.imagem, icone: 'image', label: 'Imagem'},
    {tipo: TipoComponente.album, icone: 'camera', label: 'Album'},
    {tipo: TipoComponente.cardUltimasNoticias, icone: 'newspaper', label: 'Lista Últimas Notícias'},
    {tipo: TipoComponente.cardUltimosVideos, icone: 'video', label: 'Lista Últimos Vídeos'},
    {tipo: TipoComponente.cardUltimosAlbuns, icone: 'images', label: 'Lista Últimos Álbuns'},
    {tipo: TipoComponente.arquivosAnexos, icone: 'paperclip', label: 'Arquivos Anexos à página'},
    {tipo: TipoComponente.newsletter, icone: 'mail-bulk', label: 'Formulário Inscrição Newsletter'},
    {tipo: TipoComponente.titulo, icone: 'text-height', label: 'Titulo'},
    {tipo: TipoComponente.pdf, icone: 'file-pdf', label: 'PDF'},
];