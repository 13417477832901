import React from 'react';
import { Form, Button, Table, Alert } from 'react-bootstrap';
import { FilePond} from 'react-filepond';
import { getConfig } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from "sweetalert2";
import { AbaseAutocompleteField, AbaseTextField } from 'abase-components';
import { Query } from 'react-apollo';
import { PAGINA_ALL } from '../PaginaGql';
import _ from 'lodash';

const targets = [
    {
      id: "_self",
      name: "Mesma página"
    },
    {
      id: "_blank",
      name: "Outra página"
    }
  ]

export default function BannerPagina({onChange, value}) {

    const [banner, setBanner] = React.useState({})
    const [editarRegistro, setEditarRegistro] = React.useState(null)
    const [configBanner, setConfigBanner] = React.useState({slideBanner:[]})

    React.useEffect(()=>{
        let dadosConfig = {slideBanner:[]}
        if (!!value) dadosConfig = JSON.parse(value);
        if(configBanner !== dadosConfig){
            setConfigBanner(dadosConfig)
        }
    },[value])

    return (
        <div>
            <Form.Group>
                <Form.Label as="legend" className="f-12">Exibir banner no cabeçalho</Form.Label>
                <br />
                <Form.Check
                    type="radio"
                    inline
                    label="Sem Banner"
                    name="exibicaoBanner"
                    id="semBanner"
                    checked={configBanner.exibirBannerPagina === null}
                    onClick={() => {
                        onChange(JSON.stringify({ ...configBanner, exibirBannerPagina: null }));
                        setConfigBanner({ ...configBanner, exibirBannerPagina: null });
                    }}
                />
                <Form.Check
                    type="radio"
                    inline
                    label="Da página inicial"
                    name="exibicaoBanner"
                    id="inicial"
                    checked={(!!configBanner === true && configBanner.exibirBannerPagina === "inicial")}
                    onClick={() => {
                        onChange(JSON.stringify({ ...configBanner, exibirBannerPagina: "inicial" }));
                        setConfigBanner({ ...configBanner, exibirBannerPagina: "inicial" });
                    }}
                />
                <Form.Check
                    type="radio"
                    inline
                    label="Todas as páginas"
                    name="exibicaoBanner"
                    id="todas"
                    checked={(!!configBanner === true && configBanner.exibirBannerPagina === "todas")}
                    onClick={() => {
                        onChange(JSON.stringify({ ...configBanner, exibirBannerPagina: "todas" }));
                        setConfigBanner({ ...configBanner, exibirBannerPagina: "todas" });
                    }}
                />
            </Form.Group>
            <Form.Group className="p-relative">
                <label className="title-label">Altura do Banner (altura padrão: 400)</label>
                <Form.Control type="number" placeholder="Informe aqui a altura do banner em pixels, se não informado será aplicada a aultura padrão de 400px ..." value={(!!configBanner ? configBanner.altura : null)} onChange={e => {
                    onChange(JSON.stringify({ ...configBanner, altura: e.target.value }));
                    setConfigBanner({ ...configBanner, altura: e.target.value });
                }} />
            
            </Form.Group>
            <hr />
            <Form.Group className="p-relative">
                <label className="title-label">Título do Banner</label>
                <Form.Control type="text" placeholder="Informe aqui o título do banner..." value={banner.titulo} onChange={e => setBanner({ ...banner, titulo: e.target.value })} />
            </Form.Group>
            <Form.Group className="p-relative">
                <label className="title-label">Texto do Banner</label>
                <Form.Control as="textarea" rows="3" placeholder="Informe aqui o texto/conteúdo do banner..." value={banner.texto} onChange={e => setBanner({ ...banner, texto: e.target.value })} />
            </Form.Group>

            <Form.Group>
                <Query
                    query={PAGINA_ALL}
                    >{({ data, loading }) => (
                        <React.Fragment>
                            <AbaseAutocompleteField isLoading={loading} label={"Redirecionar para"}
                            data={(_.get(data, "psv_paginaportal.list", []) || []).map(x => {
                                if (banner.paginaRedirecionar && banner.paginaRedirecionar.pgt_codigo === x.pgt_codigo) {
                                    return banner.paginaRedirecionar;
                                }
                                return x;
                            })}
                            value={banner.paginaRedirecionar}
                            onChange={e => {
                                setBanner({...banner, paginaRedirecionar: e, link: ''});
                            }}
                            getOptionValue={x => x}
                            textField={"pgt_titulo"}
                            />
                            <AbaseTextField fullWidth={true} label={"URL direta"} placeholder={banner.paginaRedirecionar ? "Remova a página para informar o link direto" : ""} disabled={banner.paginaRedirecionar} value={banner.link} onChange={e => {setBanner({...banner, paginaRedirecionar: null, link: e.target.value})}} />
                        </React.Fragment>
                )}</Query>                                
            </Form.Group>
            <Form.Group>
                <AbaseAutocompleteField fullWidth={true} valueField={"id"} textField={"name"} label={"Modo"} data={targets} value={banner.target} onChange={e => { setBanner({...banner, target: e}) }} />
            </Form.Group>

            <div className="row">
                <div className="col-sm-12 text-center">
                    <FilePond
                        name="image"
                        acceptedFileTypes={['image/*']}
                        allowMultiple={false}
                        server={{
                            url: getConfig().urlUploadImagem,
                            process: {
                              headers: {
                                'imageSize': '1024',
                                'psv_codigo': getConfig().idPortal
                              }
                            }
                          }}
                        onprocessfile={(erro, arquivo) => {
                            if (erro) {
                              return
                            }
                            let dadosUpload = JSON.parse(arquivo.serverId);
                            setBanner({...banner, fundo: getConfig().urlDownloadImagem + dadosUpload.data.id})
                            setTimeout(() => {
                                arquivo.abortLoad();
                            }, 3500);
                          }}
                        labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> Clique para selecionar o arquivo </span><br /> tamanho de imagem recomendado para o banner: <b>1920x720</b>."
                        labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                        labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                        labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                        labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                        labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                        labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                        <div className="col-sm-12 text-right">
                            {!!banner.fundo ? (<div >
                            <label className="f-12 m-t-23 m-r-5">Pré visualização:</label>
                            <img alt="preview" className="m-t-15" src={banner.fundo} width={120} />
                            </div>) : null}
                        </div>
                </div>
                <div className="col-sm-12 m-t-10 m-b-10 text-center" style={{marginTop: 40}}>
                    <Button variant="secondary" size="sm" onClick={() => {
                        let dadosBanner = {...configBanner};
                        if (editarRegistro === null){
                            dadosBanner.slideBanner.push({...banner});
                        }
                        else {
                            dadosBanner.slideBanner[editarRegistro] = {...banner};
                        }
                        onChange(JSON.stringify(configBanner));
                        setConfigBanner(dadosBanner);
                        setEditarRegistro(null);
                        setBanner({titulo: '', texto: '', fundo: '', link:'', target: null, paginaRedirecionar: null });
                    }}><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;{editarRegistro === null ? 'Incluir Banner' : 'Alterar Banner'}</Button>
                </div>
            </div>
            <div className="col-sm-12 m-t-15 p-0">
                <Table striped bordered hover size="sm" className="f-12">
                    <thead>
                        <tr>
                            <th> ID </th>
                            <th> TÍTULO </th>
                            <th> IMAGEM </th>
                            <th className="text-right"> OPERAÇÕES </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!configBanner && (!!configBanner.slideBanner === true && configBanner.slideBanner.length > 0) && configBanner.slideBanner.map((conteudo, indice) => (
                            <tr key={indice}>
                                <td>{indice + 1}</td>
                                <td>{conteudo.titulo}</td>
                                <td><img alt="imagem fundo" src={conteudo.fundo} width={54} /></td>
                                <td className="text-right">
                                    <Button size="sm" variant="primary" onClick={() => {
                                        setEditarRegistro(indice);
                                        setBanner({...configBanner.slideBanner[indice]});
                                    }}><FontAwesomeIcon icon="edit" /></Button>
                                    &nbsp;&nbsp;
                                    <Button size="sm" variant="secondary" onClick={() => {
                                        Swal.fire({
                                            title: "Confirma Exclusão?",
                                            type: "warning",
                                            text: "Você deseja realemnte excluir o banner " + (parseInt(indice) + 1) + "?",
                                            footer: "",
                                            showCancelButton: true,
                                            confirmButtonColor: '#d33',
                                            cancelButtonColor: '#c0c0c0',
                                            confirmButtonText: 'Sim, Excluir!',
                                            cancelButtonText: 'Cancelar'
                                        }).then((result) => {
                                            if (result.value) {
                                                let slide = [...configBanner.slideBanner];
                                                slide.splice(indice, 1);
                                                setConfigBanner({...configBanner, slideBanner: slide});
                                                onChange(JSON.stringify({...configBanner, slideBanner: slide}));
                                            }
                                        });
                                    }}><FontAwesomeIcon icon="trash" /></Button>
                                </td>
                            </tr>
                        ))}
                        {(configBanner.slideBanner.length === 0) &&
                            <tr>
                                <td colSpan={3} className="text-center mt-2">
                                    <Alert variant="warning" className="text-center">
                                        <FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Nenhum registro encontrado
                                    </Alert>;
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}