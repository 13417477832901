import React, { Component } from 'react';
import { CardNoticia } from './';
import { Container, Row, Col } from 'react-bootstrap';

class ListaNoticias extends Component {
  render() {
    return (
      <div>
        <Container>
            <Row>
                <Col sm={3}>
                    {this.props.noticias.map((noticia, i) => (
                        <CardNoticia key={i} ocultarLinkTodas noticia={noticia} />
                    ))}
                </Col>
            </Row>
        </Container>
      </div>
    )
  }
}

export default ListaNoticias
