import React, { useState } from 'react';
import { AbaseTextField, FormDraft, AbaseCheckbox, AbaseAutocompleteField } from 'abase-components';
import 'filepond/dist/filepond.min.css';
import _ from 'lodash';
import { Query } from 'react-apollo';
import { Button, Col, Form, Alert, Row } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import { defaultDecorators, redirectBackForm } from '../../services';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
import { cast, validate } from '../../services/validate';
import { client } from '../apollo';
import { PORTALSERVICOS_QUERY_BY_ID, PORTALSERVICOS_SAVE } from './PortalServicoGql';
import { MENU_ALL } from './MenuGql';
import { FilePond } from 'react-filepond';
import { getConfig } from '../config';
import FroalaEditor from 'react-froala-wysiwyg';
import BannerPagina from './components/BannerPagina';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';

const PortalServicosSchema = Yup.object().shape({
  psv_codigo: Yup.number().nullable(),
  psv_imagemcabecalho: Yup.string().nullable(),
  psv_rodapeportal: Yup.string().nullable(),
  psv_rotaacesso: Yup.string().nullable(),
  psv_banner: Yup.string().nullable(),
  psv_titulo: Yup.string().required(),
  psv_exibirpesquisa: Yup.boolean().required(),
  psv_analytics: Yup.string().nullable(),
  psv_headtitle: Yup.string().nullable(),
  psv_fbappid: Yup.string().nullable(),
  psv_fbimagem: Yup.string().nullable(),
  psv_diasvalidadenoticia: Yup.number().required(),
  msv_menu: Yup.object().shape({
    msv_codigo: Yup.number(),
  }).noUnknown(),
}).noUnknown()

function Configuracoes({ match: { params }, history, location }) {

  const [smtpInicial, setSmtpInicial] = useState({});
  const [configSmtp, setConfigSmtp] = useState({});
  const [conteudoRodape, setConteudoRodape] = useState('');

  const handleSubmit = async (values) => {
    try {
      let midiasSociais = {
        facebook: (!!values.link_facebook ? values.link_facebook : null),
        instagram: (!!values.link_instagram ? values.link_instagram : null),
        twitter: (!!values.link_twitter ? values.link_twitter : null),
      }
      let mutationData = await cast(PortalServicosSchema, values);
      mutationData.psv_linkssociais = JSON.stringify(midiasSociais);
      mutationData.psv_rodapeportal = conteudoRodape;
      if (smtpInicial != configSmtp) mutationData.psv_configsmtp = JSON.stringify(configSmtp);
      await client.mutate({
        mutation: PORTALSERVICOS_SAVE,
        variables: { data: mutationData }
      })
      showInfo(defaultSuccessMessage("save"))
    } catch (err) {
      showError({ message: defaultErrorMessage("save", err) })
    }
  }

  const notEditting = false
  const id = getConfig().idPortal;

  return (

    <Query
      query={PORTALSERVICOS_QUERY_BY_ID}
      skip={notEditting}
      onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) })
        redirectBackForm(history, location)
      }}
      onCompleted={(data) => {
        if (!notEditting && !_.get(data, "psv_portalservicos.get")) {
          showError({ message: defaultErrorMessage("not_found") })
          redirectBackForm(history, location)
        }
        else {
          setConteudoRodape(data.psv_portalservicos.get.psv_rodapeportal);
          if (!!data.psv_portalservicos.get.psv_linkssociais) {
            let midiasSociais = JSON.parse(data.psv_portalservicos.get.psv_linkssociais);
            data.psv_portalservicos.get.link_facebook = midiasSociais.facebook;
            data.psv_portalservicos.get.link_instagram = midiasSociais.instagram;
            data.psv_portalservicos.get.link_twitter = midiasSociais.twitter;
          }
          if (!!data.psv_portalservicos.get.psv_configsmtp) {
            
            let config = typeof data.psv_portalservicos.get.psv_configsmtp === 'string' ? JSON.parse(data.psv_portalservicos.get.psv_configsmtp) : data.psv_portalservicos.get.psv_configsmtp;

            setSmtpInicial({
              ServidorSMTP: config.ServidorSMTP,
              PortaSMTP: config.PortaSMTP,
              UsuarioSMTP: config.UsuarioSMTP,
              SenhaSMTP: config.SenhaSMTP,
              UsaSSL: config.UsaSSL,
              EmailRemetente: config.EmailRemetente
            });

            setConfigSmtp({
              ServidorSMTP: config.ServidorSMTP,
              PortaSMTP: config.PortaSMTP,
              UsuarioSMTP: config.UsuarioSMTP,
              SenhaSMTP: config.SenhaSMTP,
              UsaSSL: config.UsaSSL,
              EmailRemetente: config.EmailRemetente
            });
          }
        }
      }}
      variables={{ id }}>
      {({ data, loading }) => (
        <div className="colPageAdm">
          <FormDraft
            useDraft={notEditting}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true
            }}
            validate={validate(PortalServicosSchema)}
            initialValues={_.get(data, "psv_portalservicos.get") || {
              msv_menu: "{}"
            }}
            decorators={defaultDecorators}
            render={({ handleSubmit, form, submitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col xs={12}>
                    <h3>Configurações do Portal da Educação</h3>
                    <div className="detalhe-admin"></div>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"psv_titulo"}>{({ input, meta }) => (
                      <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Descrição do Portal"} />
                    )}</Field>
                  </Col>
                  <Col sm={3} xs={12} className="text-center">
                    <Field name={"psv_exibirpesquisa"}>{({ input, meta }) => (
                      <AbaseCheckbox meta={meta} {...input} label={"Exibir pesquisa no cabeçalho do site"} />
                    )}</Field>
                  </Col>
                  <Col sm={3} xs={12} className="text-center">
                  <Field name={"psv_habilitarnewsletter"}>{({ input, meta }) => (
                    <AbaseCheckbox meta={meta} {...input} label={"Habilitar newsletter no portal"} />
                  )}</Field>
                  </Col>
                  <Col sm={4} xs={12}>
                    <Query
                      query={MENU_ALL}
                    >{({ data, loading }) => (
                      <Field name="msv_menu" >{({ input, meta }) => (
                        <React.Fragment>
                          <AbaseAutocompleteField isLoading={loading} label={"Menu principal do site"}
                            data={_.get(data, "psv_menuportal.list", []).map((x) => {
                              if (input.value && input.value.msv_codigo === x.msv_codigo) {
                                return input.value
                              }
                              return x
                            })}
                            value={input.value}
                            textField={'msv_nome'}
                            onChange={value => {
                              input.onChange(value)
                            }}
                            getOptionValue={x => x}
                          />
                        </React.Fragment>
                      )}</Field>
                    )}</Query>
                  </Col>
                  <Col sm={4} xs={12}>
                    <Field name={"psv_diasvalidadenoticia"}>{({ input, meta }) => (
                      <AbaseTextField meta={meta} fullWidth={true} {...input} inputProps={{ type: "number" }} label={"Dias para exibição notícia"} />
                    )}</Field>
                  </Col>
                  <Col sm={4} xs={12}>
                    <Field name={"psv_analytics"}>{({ input, meta }) => (
                      <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Traking Google Analytics"} />
                    )}</Field>
                  </Col>
                  <Col xs={12}>
                    <Field name={"psv_headtitle"}>{({ input, meta }) => (
                      <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Título do site para exibição no navegador"} />
                    )}</Field>
                  </Col>
                  <Col xs={12}>
                    <Field name="psv_imagemcabecalho">{({ input, meta }) => (
                      <React.Fragment>
                        <Row>
                          <Col sm={9}>
                            <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Logo para o cabeçalho do site"} />
                          </Col>
                          <Col sm={3}>
                            {input.value ? (<div >
                              <label className="f-12 m-t-23 m-r-5 floatLeft">Pré visualização:</label>
                              <img alt="logo site" className="m-t-15" src={input.value} width={40} />
                            </div>) : null}
                          </Col>
                        </Row>
                        <FilePond
                          name="image"
                          acceptedFileTypes={['image/*']}
                          allowMultiple={false}
                          server={{
                            url: getConfig().urlUploadImagem,
                            process: {
                              headers: {
                                'imageSize': '1920',
                                'psv_codigo': getConfig().idPortal
                              }
                            }
                          }}
                          onprocessfile={(erro, arquivo) => {
                            if (erro) {
                              return
                            }
                            let dadosUpload = JSON.parse(arquivo.serverId);
                            input.onChange(getConfig().urlDownloadImagem + dadosUpload.data.id)
                          }}
                          labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> Clique para selecionar </span>"
                          labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                          labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                          labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                          labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                          labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                          labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />

                      </React.Fragment>
                    )}</Field>
                  </Col>
                  <Col xs={12}>
                    <Alert className="p-r-l-0">
                      <div className="text-center colRow p-relative">
                        <h3>Banner de Cabeçalho do site</h3>
                        <div className="detalhe-admin"></div>
                      </div>
                      <Field name={"psv_banner"}>{({ input }) => (
                        <BannerPagina {...input} />
                      )}</Field>
                    </Alert>
                  </Col>

                  <Col sm={12}>
                    <Alert className="p-0">
                      <div className="text-center colRow p-relative">
                        <h3>Facebook e outras redes sociais</h3>
                        <div className="detalhe-admin"></div>
                      </div>
                      <Col sm={12} className="p-0">
                        <Col sm={12}>
                          <Field name={"link_facebook"}>{({ input, meta }) => (
                            <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Link da Página do Facebook"} />
                          )}</Field>
                        </Col>
                        <Col sm={12}>
                          <Field name={"psv_fbappid"}>{({ input, meta }) => (
                            <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Facebook AppId"} />
                          )}</Field>
                        </Col>
                        
                        <Col xs={12}>
                        <Field name="psv_fbimagem">{({ input, meta }) => (
                          <React.Fragment>
                            <Row>
                              <Col sm={9}>
                                <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Imagem padrão para compartilhamento de páginas no Facebook"} />
                              </Col>
                              <Col sm={3}>
                                {input.value ? (<div >
                                  <label className="f-12 m-t-23 m-r-5 floatLeft">Pré visualização:</label>
                                  <img alt="imagem padrao facebook" className="m-t-15" src={input.value} width={40} />
                                </div>) : null}
                              </Col>
                            </Row>
                            <FilePond
                              name="image"
                              acceptedFileTypes={['image/*']}
                              allowMultiple={false}
                              server={{
                                url: getConfig().urlUploadImagem,
                                process: {
                                  headers: {
                                    'imageSize': '1200',
                                    'psv_codigo': getConfig().idPortal
                                  }
                                }
                              }}
                              onprocessfile={(erro, arquivo) => {
                                if (erro) {
                                  return
                                }
                                let dadosUpload = JSON.parse(arquivo.serverId);
                                input.onChange(getConfig().urlDownloadImagem + dadosUpload.data.id)
                              }}
                              labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> Clique para selecionar </span>"
                              labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                              labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                              labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                              labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                              labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                              labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />

                          </React.Fragment>
                        )}</Field>
                      </Col>

                        <Col sm={12}>
                          <Field name={"link_instagram"}>{({ input, meta }) => (
                            <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Link do Instagram"} />
                          )}</Field>
                        </Col>
                        <Col sm={12}>
                          <Field name={"link_twitter"}>{({ input, meta }) => (
                            <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Link do Twitter"} />
                          )}</Field>
                        </Col>
                      </Col>
                    </Alert>
                  </Col>

                  <Col xs={12}>
                    <Alert className="p-0">
                      <div className="text-center colRow p-relative">
                        <h3>Rodapé do site</h3>
                        <div className="detalhe-admin"></div>
                      </div>
                      <div style={{marginTop:'50px'}}>
                          <Field name={"psv_rodapeportal"}>{({ input }) => (
                            <FroalaEditor model={conteudoRodape}
                            config={{
                                attribution: false,
                                language: 'pt_br',
                                fileUploadURL: getConfig().urlUploadImagem+'V2',
                                fileUploadParams: {
                                    'imageSize': '1024',
                                    'psv_codigo': getConfig().idPortal
                                },
                                imageUploadURL: getConfig().urlUploadImagem+'V2',
                                imageUploadParams: {
                                    'imageSize': '1024',
                                    'psv_codigo': getConfig().idPortal
                                }
                            }}
                            onModelChange={(e)=>{setConteudoRodape(e)}}/>
                        )}</Field>
                      </div>
                    </Alert>
                  </Col>
                  <Col xs={12}>
                    <Alert className="p-r-l-0">
                      <div className="text-center colRow p-relative">
                        <h3>Configurações de SMTP para envio de e-mails do portal</h3>
                        <div className="detalhe-admin"></div>
                      </div>
                      <div style={{marginTop:'50px'}}>
                        <Row>
                          <Col sm={12} md={3}>
                            <Form.Group className="p-relative">
                              <div className="colRow">
                                  <label className="title-label">Nome ou IP do servidor SMTP</label>
                              </div>
                                <Form.Control type="text" value={configSmtp.ServidorSMTP} onChange={e => setConfigSmtp({ ...configSmtp, ServidorSMTP: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={1}>
                            <Form.Group className="p-relative">
                              <div className="colRow">
                                  <label className="title-label">Porta SMTP</label>
                              </div>
                                <Form.Control type="number" value={configSmtp.PortaSMTP} onChange={e => setConfigSmtp({ ...configSmtp, PortaSMTP: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={2}>
                            <Form.Group className="p-relative">
                              <div className="colRow">
                                  <label className="title-label">Usuário SMTP</label>
                              </div>
                                <Form.Control type="text" value={configSmtp.UsuarioSMTP} onChange={e => setConfigSmtp({ ...configSmtp, UsuarioSMTP: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={2}>
                            <Form.Group className="p-relative">
                              <div className="colRow">
                                  <label className="title-label">Senha SMTP</label>
                              </div>
                                <Form.Control type="password" value={configSmtp.SenhaSMTP} onChange={e => setConfigSmtp({ ...configSmtp, SenhaSMTP: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={1}>
                            <Form.Group className="p-relative">
                              <Form.Check
                                type="checkbox"
                                inline
                                label="Utilizar SSL"
                                name="utilizarSSL"
                                id="usaSSL"
                                checked={!!configSmtp.UsaSSL === true}
                                onChange={e => setConfigSmtp({ ...configSmtp, UsaSSL: e.target.checked })}
                            />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="p-relative">
                              <div className="colRow">
                                  <label className="title-label">Email Remetente</label>
                              </div>
                                <Form.Control type="text" value={configSmtp.EmailRemetente} onChange={e => setConfigSmtp({ ...configSmtp, EmailRemetente: e.target.value })} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </Alert>
                  </Col>
                </Form.Row>
                <div className="text-right colRow m-t-15">
                  <Button type={"button"} variant="secondary" size="sm" onClick={() => redirectBackForm(history, location)}>
                    <FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar
                  </Button>
                  &nbsp;&nbsp;
                  <Button type={"submit"} variant="success" size="sm" disabled={submitting} >
                    <FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar
                  </Button>
                </div>
              </Form>
            )}></FormDraft>
        </div>
      )}
    </Query>

  )
}

export default Configuracoes