import { Component } from 'react';
import { graphqlClient } from '../services';
import _ from 'lodash'

class GraphQLClient extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            isLoading: false,
            error: null
        };
    }
    query(props) {
        if (!!props.query) {
            this.setState({ isLoading: true });
            graphqlClient.request(props.query, props.variables)
                .then(result => this.setState({
                    data: result,
                    isLoading: false,
                    error: null
                }))
                .catch(error => this.setState({
                    error,
                    data: null,
                    isLoading: false
                }));
        }

    }
    componentWillMount() {
        this.query(this.props)
    }
    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(_.pick(nextProps, ["query", "variables"]), _.pick(this.props, ["query", "variables"]))) {
            this.query(nextProps)
        }
    }
    render() {
        return this.props.children(this.state);
    }
}

export default GraphQLClient
