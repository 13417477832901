import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbaseAutocompleteMultipleField, AbaseCheckbox, AbaseDateTimePickerField, AbaseTextField, FormDraft } from 'abase-components';
import 'filepond/dist/filepond.min.css';
import _ from 'lodash';
import { default as React } from 'react';
import { Query } from 'react-apollo';
import { Button, Col, Form } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import { defaultDecorators, redirectBackForm } from '../../services';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
import { cast, validate } from '../../services/validate';
import { client } from '../apollo';
import { ASSUNTO_ALL } from './AssuntoGql';
import EditorPagina from './components/EditorPagina';
import { NOTICIA_QUERY_BY_ID, NOTICIA_SAVE } from './NoticiaGql';
import { FilePond } from 'react-filepond';
import { getConfig } from './../config';

const NoticiaSchema = Yup.object()
  .shape({
    ntc_codigo: Yup.number().nullable(),
    ntc_titulo: Yup.string()
      .required()
      .label("Título"),
    ntc_resumo: Yup.string()
      .required()
      .label("Resumo"),
    ntc_datapublicacao: Yup.date()
      .required()
      .label("Data Publicação"),
    ntc_validade: Yup.date()
      .notRequired()
      .nullable(),
    ntc_destaque: Yup.boolean().nullable(),
    ntc_geranewsletter: Yup.boolean().nullable(),
    psv_assuntosnoticia: Yup.array(
      Yup.object()
        .shape({
          asn_codigo: Yup.number()
        })
        .noUnknown()
    ),
    arq_capa: Yup.object()
      .shape({
        arq_codigo: Yup.number().label("Capa da notícia").required()
      })
      .noUnknown().required().label("Capa da notícia"),

    pgt_codigo: Yup.object()
      .shape({
        pgt_codigo: Yup.number().notRequired(),
        pgt_titulo: Yup.string().notRequired(),
        pgt_rotaacesso: Yup.string().notRequired().nullable(),
        pgt_ishome: Yup.boolean().notRequired(),
        pgt_publicada: Yup.boolean().notRequired(),

        psv_estruturaspagina: Yup.array(
          Yup.object()
            .shape({
              epg_codigo: Yup.number()
                .notRequired()
                .nullable(),
              epg_linha: Yup.number()
                .notRequired()
                .nullable(),
              epg_coluna: Yup.number()
                .notRequired()
                .nullable(),
              epg_tamanho: Yup.number()
                .notRequired()
                .nullable(),

              psv_componentespagina: Yup.array(
                Yup.object()
                  .shape({
                    alb_codigo: Yup.object()
                      .notRequired()
                      .nullable(),
                    epg_codigo: Yup.object()
                      .notRequired()
                      .nullable(),
                    msv_codigo: Yup.object()
                      .notRequired()
                      .nullable(),
                    pgc_codigo: Yup.number()
                      .notRequired()
                      .nullable(),
                    pgc_ordem: Yup.number()
                      .notRequired()
                      .nullable(),
                    pgc_conteudo: Yup.string()
                      .notRequired()
                      .nullable(),
                    pgc_tipocomponente: Yup.string()
                      .notRequired()
                      .nullable()
                  })
                  .noUnknown()
              )
            })
            .noUnknown()
        )
      })
      .noUnknown()
  })
  .noUnknown();

function ManutencaoNoticia({ match: { params }, history, location }) {
  const handleSubmit = async values => {
    try {
      const mutationData = await cast(NoticiaSchema, values);

      await client.mutate({
        mutation: NOTICIA_SAVE,
        variables: {
          data: {
            ...mutationData,
            pgt_codigo: {
              ...mutationData.pgt_codigo,
              pgt_titulo: mutationData.ntc_titulo,
              psv_codigo: { psv_codigo: getConfig().idPortal }
            }
          }
        }
      });
      showInfo(defaultSuccessMessage("save"));
      redirectBackForm(history, location);
    } catch (err) {
      showError({ message: defaultErrorMessage("save", err) });
    }
  };

  const notEditting = !params.noticiaId;
  const id = params.noticiaId;

  return (
    <Query
      query={NOTICIA_QUERY_BY_ID}
      skip={notEditting}
      onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
      }}
      onCompleted={data => {
        if (!notEditting && !_.get(data, "psv_noticias.list[0]")) {
          showError({ message: defaultErrorMessage("not_found") });
          redirectBackForm(history, location);
        }
      }}
      variables={{ id }}
    >
      {({ data, loading }) => (

        <div className="colPageAdm">

          <FormDraft
            useDraft={notEditting}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true
            }}
            validate={validate(NoticiaSchema)}
            initialValues={
              _.get(data, "psv_noticias.list[0]") || {
                ntc_datapublicacao: new Date().toISOString()
              }
            }
            decorators={defaultDecorators}
            render={({ handleSubmit, form, submitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col xs={12}>
                    <h3>Manutenção de Notícia</h3>
                    <div className="detalhe-admin"></div>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"ntc_titulo"}>
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Título"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Query query={ASSUNTO_ALL}>
                      {({ data, loading }) => (
                        <Field name="psv_assuntosnoticia">
                          {({ input, meta }) => (
                            <React.Fragment>
                              <AbaseAutocompleteMultipleField
                                isLoading={loading}
                                label={"Assunto"}
                                data={_.get(
                                  data,
                                  "psv_assuntonoticia.list",
                                  []
                                ).map(x => {
                                  if (input.value) {
                                    const filtered = input.value.filter(
                                      y => x.asn_codigo === y.asn_codigo
                                    );
                                    return filtered.length === 0
                                      ? x
                                      : filtered[0];
                                  }
                                  return x;
                                })}
                                value={input.value}
                                textField={'asn_titulo'}
                                onChange={value => {
                                  input.onChange(value);
                                }}
                                getOptionValue={x => x}
                              />
                            </React.Fragment>
                          )}
                        </Field>
                      )}
                    </Query>
                  </Col>
                  <Col item xs={12}>
                    <Field name="ntc_resumo">
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          rows={4}
                          multiline
                          fullWidth={true}
                          {...input}
                          label={"Resumo"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col item xs={6}>
                    <Field name="ntc_datapublicacao">
                      {({ input, meta }) => (
                        <AbaseDateTimePickerField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Data de publicação"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col item xs={6}>
                    <Field name="ntc_validade">
                      {({ input, meta }) => (
                        <AbaseDateTimePickerField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Data de validade"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col item xs={12}>
                    <Field name="ntc_destaque">
                      {({ input, meta }) => (
                        <AbaseCheckbox
                          meta={meta}
                          {...input}
                          label={"Destaque"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col item xs={12}>
                    <Field name="ntc_geranewsletter">
                      {({ input, meta }) => (
                        <AbaseCheckbox
                          meta={meta}
                          {...input}
                          label={"Gerar Automaticamente uma Newsletter para esta notícia?"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col item xs={12}>
                    <Field name="arq_capa">{({ input, meta }) => (
                      <React.Fragment>
                        <div className="colRow">
                          <small><b>CAPA</b></small>
                        </div>
                        <div className="colRow">
                          <FilePond
                            name="image"
                            acceptedFileTypes={['image/*']}
                            allowMultiple={false}
                            server={{
                              url: getConfig().urlUploadImagem,
                              process: {
                                headers: {
                                  'imageSize': '400',
                                  'psv_codigo': getConfig().idPortal
                                }
                              }
                            }}
                            onprocessfile={(erro, arquivo) => {
                              if (erro) {
                                return
                              }
                              let dadosUpload = JSON.parse(arquivo.serverId);
                              input.onChange({ psv_url: dadosUpload.data.link, arq_codigo: dadosUpload.data.arq_codigo })
                            }}
                            onremovefile={(erro, arquivo) => {
                              if (erro){
                                return
                              }
                              input.onChange(null);
                            }}
                            labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> Clique para selecionar o arquivo </span>"
                            labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                            labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                            labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                            labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                            labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                            labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                        </div>
                        {input.value ? (<div className="text-center colRow m-t-15">
                          <b>Pré visualização</b> <br />
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img src={input.value.psv_url} alt="preview imagem" className="imge b-1 m-t-15" />
                          </div>
                        </div>) : null}

                        {meta && meta.touched && !input.value ? <p className="colRow f-12 p-l-12 m-0 c-red">Capa da notícia é obrigatória</p> : null}
                      </React.Fragment>
                    )}</Field>
                  </Col>
                </Form.Row>
                <div className="colRow m-t-15">
                  <Field name={"pgt_codigo.psv_estruturaspagina"}>{({ input }) => (
                    <EditorPagina {...input} />
                  )}</Field>
                </div>
                <div className="text-right colRow m-t-15">
                  <Button type={"button"} variant="secondary" size="sm" onClick={() => redirectBackForm(history, location)}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                  &nbsp;&nbsp;
                  <Button type={"submit"} variant="success" size="sm" disabled={submitting} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar</Button>
                </div>
              </Form>
            )}></FormDraft>
        </div>
      )}
    </Query>
  );
}

export default ManutencaoNoticia;