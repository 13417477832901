import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbaseAutocompleteField, AbaseDateTimePickerField, AbaseTextField, FormDraft } from 'abase-components';
import 'filepond/dist/filepond.min.css';
import _ from 'lodash';
import { default as React } from 'react';
import { Query } from 'react-apollo';
import { Button, Col, Form, FormCheck } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import { defaultDecorators, redirectBackForm } from '../../services';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
import { cast, validate } from '../../services/validate';
import { client } from '../apollo';
import { ASSUNTO_ALL } from './AssuntoGql';
import EditorPagina from './components/EditorPagina';
import { EditorHtmlV2 } from './components';
import { FilePond } from 'react-filepond';
import { PAGINA_QUERY_BY_ID, PAGINA_SAVE } from './PaginaGql';
import { getConfig } from '../config';
import { TipoComponente } from '../../enums';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import FroalaEditor from 'react-froala-wysiwyg';

const PaginaSchema = Yup.object()
  .shape({
    pgt_codigo: Yup.number().notRequired(),
    pgt_titulo: Yup.string()
      .required()
      .label("Título"),
    pgt_rotaacesso: Yup.string()
      .required()
      .label("Rota de acesso"),
    pgt_ishome: Yup.boolean().notRequired(),
    pgt_publicada: Yup.boolean().notRequired(),
    pgt_metadescription: Yup.string().nullable(),
    pgt_metakeywords: Yup.string().nullable(),
    asn_codigo: Yup.object()
      .shape({
        asn_codigo: Yup.number()
      })
      .noUnknown()
      .nullable()
      .notRequired()
      .transform(current => (current && current.asn_codigo ? current : null)),
    psv_estruturaspagina: Yup.array(
      Yup.object()
        .shape({
          epg_codigo: Yup.number()
            .notRequired()
            .nullable(),
          epg_linha: Yup.number()
            .notRequired()
            .nullable(),
          epg_coluna: Yup.number()
            .notRequired()
            .nullable(),
          epg_tamanho: Yup.number()
            .notRequired()
            .nullable(),
          pgt_codigo: Yup.object()
            .shape({
              pgt_codigo: Yup.number().notRequired()
            })
            .notRequired()
            .nullable()
            .noUnknown(),
          psv_componentespagina: Yup.array(
            Yup.object()
              .shape({
                alb_codigo: Yup.object()
                  .notRequired()
                  .nullable(),
                epg_codigo: Yup.object()
                  .notRequired()
                  .nullable(),
                msv_codigo: Yup.object()
                  .notRequired()
                  .nullable(),
                pgc_codigo: Yup.number()
                  .notRequired()
                  .nullable(),
                pgc_ordem: Yup.number()
                  .notRequired()
                  .nullable(),
                pgc_conteudo: Yup.string()
                  .notRequired()
                  .nullable(),
                pgc_tipocomponente: Yup.string()
                  .notRequired()
                  .nullable()
              })
              .noUnknown()
          )
        })
        .noUnknown()
    )
  })
  .noUnknown();

function ManutencaoPagina({ match: { params }, history, location }) {

  const [arquivos, setArquivos] = React.useState([]);
  const [arquivosExistentes, setArquivosExistentes] = React.useState([]);
  const [modalAviso, setModalAviso] = React.useState({});
  const [conteudoModalAviso, setConteudoModalAviso] = React.useState('');

  const handleSubmit = async values => {
    try {
      values.psv_estruturaspagina.map((estrutura, indiceEstrutura) => {
        if (!!estrutura){
          estrutura.psv_componentespagina.map((componente, indiceComponente) => {
            switch (componente.pgc_tipocomponente) {
              case TipoComponente.slideBanner:
              case TipoComponente.banner:
              case TipoComponente.assuntosRelacionados:
              case TipoComponente.ultimaNoticiaAssunto:
              case TipoComponente.cardUltimasNoticias:
              case TipoComponente.cardUltimosVideos:
              case TipoComponente.cardUltimosAlbuns:
              case TipoComponente.menu:
              case TipoComponente.acessoRapido:
                if (typeof componente.pgc_conteudo !== 'string') componente.pgc_conteudo = JSON.stringify(componente.pgc_conteudo);
                break;
            }
          });
        }
      });
      const mutationData = await cast(PaginaSchema, values);
      if (mutationData.asn_codigo && !mutationData.asn_codigo.asn_codigo) {
        mutationData.asn_codigo = null;
      }

      let uuid = getConfig().getUuid();
      let dadosModal = modalAviso;
      dadosModal.id = uuid;

      if (!!dadosModal && !!dadosModal.conteudo) mutationData.pgt_modalaviso = JSON.stringify(dadosModal);

      if (arquivos.length > 0) {
        const dados = arquivos.map(({ aqp_ordem, arq_codigo, arq_fullname }) => ({
          aqp_ordem,
          arq_codigo: { ...arq_codigo }, // Copia o objeto arq_codigo
          arq_fullname,
        }));
        mutationData.psv_arquivospagina = dados;
      } else {
         mutationData.psv_arquivospagina = [];
      }

      await client.mutate({
        mutation: PAGINA_SAVE,
        variables: { data: { ...mutationData, psv_codigo: { psv_codigo: getConfig().idPortal } } }
      });
      showInfo(defaultSuccessMessage("save"));
      redirectBackForm(history, location);
    } catch (err) {
      showError({ message: defaultErrorMessage("save", err) });
    }
  };

  const notEditting = !params.paginaId;
  const id = params.paginaId;

  return (
    <Query
      query={PAGINA_QUERY_BY_ID}
      skip={notEditting}
      onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
      }}
      onCompleted={data => {
        if (!notEditting && !_.get(data, "psv_paginaportal.list[0]")) {
          showError({ message: defaultErrorMessage("not_found") });
          redirectBackForm(history, location);
        }

        let listaArquivosExistentes = data.psv_paginaportal.list[0].psv_arquivospagina.map((arquivo, indice) => {
          return { 
            source: arquivo.arq_codigo.arq_fullname,
            options: {
              type: 'local', 
              file: { 
                name: arquivo.arq_codigo.arq_nome,
                type: arquivo.arq_codigo.arq_mimetype 
              } 
            } 
          }
        });
        setArquivosExistentes([...listaArquivosExistentes]);

        let listaArquivos = data.psv_paginaportal.list[0].psv_arquivospagina.map((arquivo, indice) => {
          return {
            aqp_ordem: arquivo.aqp_ordem,
            arq_codigo: {
              arq_codigo: arquivo.arq_codigo.arq_codigo, 
              arq_fullname: arquivo.arq_codigo.arq_fullname
            } 
          }
        });
        setArquivos(listaArquivos);

        let dadosModalAviso = {};
        if (!!data.psv_paginaportal.list[0].pgt_modalaviso){
          dadosModalAviso = (typeof data.psv_paginaportal.list[0].pgt_modalaviso === 'string' ? JSON.parse(data.psv_paginaportal.list[0].pgt_modalaviso) : data.psv_paginaportal.list[0].pgt_modalaviso);
        }
        setModalAviso(dadosModalAviso);
        setConteudoModalAviso(!!dadosModalAviso.conteudo ? dadosModalAviso.conteudo : '');
      }}
      variables={{ id }}
    >
      {({ data, loading }) => (

        <div className="colPageAdm">

          <FormDraft
            useDraft={notEditting}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true
            }}
            validate={validate(PaginaSchema)}
            initialValues={_.get(data, "psv_paginaportal.list[0]")}
            decorators={defaultDecorators}
            render={({ handleSubmit, form, submitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col xs={12}>
                    <h3>Manutenção de Página</h3>
                    <div className="detalhe-admin"></div>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"pgt_titulo"}>
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Nome da página"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Query query={ASSUNTO_ALL}>
                      {({ data, loading }) => (
                        <Field name="asn_codigo">
                          {({ input, meta }) => (
                            <React.Fragment>
                              <AbaseAutocompleteField
                                isLoading={loading}
                                label={"Assunto"}
                                data={_.get(
                                  data,
                                  "psv_assuntonoticia.list",
                                  []
                                ).map(x => {
                                  if (
                                    input.value &&
                                    input.value.asn_codigo === x.asn_codigo
                                  ) {
                                    return input.value;
                                  }
                                  return x;
                                })}
                                value={input.value}
                                textField={'asn_titulo'}
                                onChange={value => {
                                  input.onChange(value);
                                }}
                                getOptionValue={x => x}
                              />
                            </React.Fragment>
                          )}
                        </Field>
                      )}
                    </Query>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"pgt_metadescription"}>
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"SEO > Descrição da página"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"pgt_metakeywords"}>
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"SEO > Palavras-Chave"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Field name={"pgt_rotaacesso"}>
                    {({ input, meta }) => (
                      <Field name={"pgt_ishome"}>
                        {({ input: inputIsHome }) => (
                          <React.Fragment>
                            <Col sm={6} xs={12}>
                              <AbaseTextField
                                meta={meta}
                                disabled={inputIsHome.value}
                                fullWidth={true}
                                {...input}
                                label={"Rota da página"}
                                placeholder="Informe a rota da página"
                              />
                            </Col>
                            <Col sm={6} xs={12}>
                              <FormCheck className="chck"
                                checked={inputIsHome.value}
                                name="pgt_ishome"
                                label="Homepage do Site?"
                                onChange={() => {
                                  if (!inputIsHome.value)
                                    input.onChange("home");
                                  inputIsHome.onChange(!inputIsHome.value);
                                }}
                              />
                              <Field name={"pgt_publicada"}>
                                {({ input }) => (
                                  <FormCheck className="chck"
                                    {...input}
                                    checked={input.value}
                                    label="Página Publicada?"
                                  />
                                )}
                              </Field>
                            </Col>
                          </React.Fragment>
                        )}
                      </Field>
                    )}
                  </Field>
                </Form.Row>
                <br />
                <Field name={"psv_estruturaspagina"}>{({ input }) => (
                  <EditorPagina {...input} />
                )}</Field>

                <div className="colRow mt-3">
                  <div className="text-center colRow p-relative">
                    <h6>Arquivos anexos à esta página</h6>
                    <div className="detalhe-admin"></div>
                  </div>
                  <div className="colRow">
                    <FilePond name="image"  
                    className="filePondMntPagina"
                    allowMultiple={true}
                    files={arquivosExistentes} 
                    server={{
                      url: getConfig().urlUploadImagem,
                      process: {
                        headers: {
                          'imageSize': '1024',
                          'psv_codigo': getConfig().idPortal
                        }
                      }
                    }}
                    onprocessfile={(erro, arquivo) => {
                      if (erro) {
                        return
                      }
                      let dadosUpload = JSON.parse(arquivo.serverId);
                      setArquivos(
                        [ ...arquivos, 
                        {
                          arquivo: arquivo,
                          aqp_ordem: (arquivos.length+1), 
                          arq_codigo: {
                            arq_codigo: dadosUpload.data.arq_codigo, 
                            arq_fullname: dadosUpload.data.id
                          } 
                        }
                      ])
                    }}
                    onremovefile={(erro, arquivo) => {
                      let dadosDelete;
  
                      try {
                        //executa quando o arquivo foi feito upload
                        let dados = JSON.parse(arquivo.serverId);
                        dadosDelete = dados.data.id;
                      } catch (e) {
                        //executa quando o arquivo é o que veio do banco
                        dadosDelete = arquivo.serverId;
                      }
                      
                      let anexos = [...arquivos]
                      let indiceAnexo= anexos.findIndex(anexo => anexo.arq_codigo.arq_fullname === dadosDelete);
                      if (indiceAnexo >= 0){
                        anexos.splice(indiceAnexo,1);
                        setTimeout(() => {
                          setArquivos(anexos);
                        }, 100);
                      }
                    }}
                    onupdatefiles={fileItems => {
                      if (fileItems.length > 0) setArquivosExistentes(fileItems.map(fileItem => fileItem.file));
                      else setArquivosExistentes([]);
                    }}
                    labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> Clique para selecionar </span>"
                    labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                    labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                    labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                    labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                    labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                    labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                  </div>
                </div>

                <div className="colRow mt-3">
                  <div className="text-center colRow p-relative">
                    <h6>Exibir modal de aviso nesta página</h6>
                    <div className="detalhe-admin"></div>
                  </div>
                  <div className="colRow mt-3">
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <div className="colRow">
                              <label className="title-label">Título do Aviso</label>
                          </div>
                          <Form.Control type="text" placeholder="" value={modalAviso.titulo} onChange={e => setModalAviso({ ...modalAviso, titulo: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={6}>
                        <FormCheck className="text-center" style={{marginTop: 14}}
                          checked={modalAviso.exibir}
                          name="exibirAviso"
                          label="Exibir aviso na página"
                          onChange={() => {
                            setModalAviso({...modalAviso, exibir: !modalAviso.exibir});
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <AbaseDateTimePickerField
                          fullWidth={true}
                          value={modalAviso.exibirAte}
                          onChange={e => setModalAviso({ ...modalAviso, exibirAte: e})}
                          label={"Exibir este aviso até"}
                        />
                      </Col>
                      <Col sm={12} style={{marginTop: 10}}>
                        <FroalaEditor
                          model={conteudoModalAviso}
                          config={{
                            attribution: false,
                            language: 'pt_br',
                            fileUploadURL: getConfig().urlUploadImagem+'V2',
                            fileUploadParams: {
                                'imageSize': '1024',
                                'psv_codigo': getConfig().idPortal
                            },
                            imageUploadURL: getConfig().urlUploadImagem+'V2',
                            imageUploadParams: {
                                'imageSize': '1024',
                                'psv_codigo': getConfig().idPortal
                            }
                          }}
                          
                          onModelChange={(e) => {setConteudoModalAviso(e); setModalAviso({ ...modalAviso, conteudo: e});}}
                        />
                        {/*<EditorHtmlV2 id="conteudoModalAviso" value={conteudoModalAviso} onChange={e => setModalAviso({ ...modalAviso, conteudo: e})} />*/}
                      </Col>
                    </Form.Row>
                  </div>
                </div>

                <div className="text-right colRow m-t-15">
                  <Button type={"button"} variant="secondary" size="sm" onClick={() => redirectBackForm(history, location)}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                  &nbsp;&nbsp;
                  <Button type={"submit"} variant="success" size="sm" disabled={submitting} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar</Button>
                </div>
              </Form>
            )}></FormDraft>

        </div>
      )}
    </Query>
  );
}

export default ManutencaoPagina;