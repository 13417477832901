import React, { Component, Fragment } from 'react';
import { Card } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Moment from 'react-moment';

import { getConfig } from '../pages/config';

import './Cards.css';

class CardVideo extends Component {

  render() {
    return (
      <Fragment>
        <div className={"m-t-15 minWidth" + (!!this.props.widthCard ? " width" : "")}>
            <a href={getConfig().rotabase + `#/video/${this.props.video.vid_codigo}`}>
              <Card.Img as="div" variant="top">
                <ReactPlayer className="imgFeeds" ref={this.ref} url={this.props.video.vid_link} light={true} width={'100%'} height={215} />
              </Card.Img>
            </a>
            <div>
                <a href={getConfig().rotabase + `#/video/${this.props.video.vid_codigo}`}>
                  <h5 className="text-uppercase m-b-0 m-t-15 t-overflow titulo">{this.props.video.vid_titulo}</h5>
                  <small className="text-muted small m-b-5">
                    Vídeo incluído em: <b><Moment format="DD/MM/YYYY">{this.props.video.vid_data}</Moment></b>
                  </small> 
                  {!!this.props.video.resumo && 
                  <div className="t-overflow colDivision" style={{minHeight: '28px'}}>
                    {this.props.video.resumo}
                  </div>}
                </a>
                <div className="colRow" style={{minHeight: '25px'}}>
                  {!!this.props.video.asn_codigo &&
                    <a className="tagCards" style={{backgroundColor: this.props.video.asn_codigo.asn_cor}} href={getConfig().rotabase + "#/videos/1/" + this.props.video.asn_codigo.asn_codigo}>
                      {this.props.video.asn_codigo.asn_titulo}
                    </a>
                  }
                </div>
            </div>
        </div>
      </Fragment>
    )
  }
}

export default CardVideo
