import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { CardAlbum } from './';
import { graphqlClient } from '../services';
import { getConfig } from '../pages/config';

import './CardUltimosAlbuns.css';

export const Menu = (listaAlbuns) =>
  listaAlbuns.map((album, i) => {
      return <div className="cardAlbum" key={i}><CardAlbum ocultarLinkTodos album={album} widthCard /></div>;
  });

const Arrow = ({ icon }) => {
    if (icon === "<") return <div className="arrow-left"><FontAwesomeIcon icon="arrow-left" /></div>;
    if (icon === ">") return <div className="arrow-right"><FontAwesomeIcon icon="arrow-right" /></div>;
};
 
export const ArrowLeft = Arrow({ icon: "<" });
export const ArrowRight = Arrow({ icon: ">" });

class CardUltimosAlbuns extends Component {

  constructor(props) {
      super(props);
      this.menu = null;
      this.state = { menuAlbuns: [] };
      let configNoticia = typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo;
      let filtro = "start: 0, limit: " + (!!configNoticia.qtdeNoticiasExibir ? configNoticia.qtdeNoticiasExibir : "20") + ", sortBy:\"alb_data\", sortDirection:\"desc\"";
      let query=`
      {       
        psv_albuns {
          list(${filtro}){
            alb_codigo,
            alb_data,
            alb_nome, 
            arq_capa {
              arq_descricao
              arq_mimetype
              arq_fullname
              psv_url
            },
            psv_albumarquivos{
              alq_codigo, 
              alq_ordem,
              arq_codigo {
                arq_fullname, 
                psv_url
              }
            }
          }
        }
      }
      `;
      graphqlClient.request(query).then((result) => {
        this.setState({menuAlbuns: result.psv_albuns.list});
      });
  }  

  render() {
    let configNoticia = typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo;
    const menuCard = !!this.state.menuAlbuns ? Menu(this.state.menuAlbuns) : [];
    return (
      <div>
        <div className="colRow text-center p-relative">
            <h2>
              <FontAwesomeIcon icon="images"/> Últimos Álbuns <FontAwesomeIcon icon="images"/>
            </h2>
            <div className="detalhe"></div>
        </div>
        {configNoticia.tipoExibicaoNoticias === 'carrossel' &&
          <div className="colRow p-relative">
            <ScrollMenu
              ref={el => (this.menu = el)}
              data={menuCard}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              wheel={false}
              hideArrows={true}
              hideSingleArrow={true}
              translate={0}
              transition={0.5}
              alignCenter={false}
              scrollBy={1}
              clickWhenDrag={false}
              dragging={true}
            />
          </div>
        }
        {(configNoticia.tipoExibicaoNoticias === 'card' || !!configNoticia.tipoExibicaoNoticias === false) &&
          <div className="colRow p-relative">
            <Row>
              {this.state.menuAlbuns.map((album, i) => {
                let largura = parseInt(configNoticia.larguraCard) || 3;
                return (
                <Col xs={12} sm={6} md={(largura+1)} lg={largura} key={i}>
                  <div className="cardAlbum"><CardAlbum ocultarLinkTodos album={album} /></div>
                </Col> )
              })}
            </Row>
          </div>
        }
        {configNoticia.tipoExibicaoNoticias === 'lista' &&
          <div className="colRow p-relative">
            {this.state.menuAlbuns.map(x => (
              <div key={x.alb_codigo} className="coList p-15">
                <Row>
                  <Col xs={3} md={2} sm={3} className="wdMobile p-0 hide">
                    <Link to={"/album/" + _.get(x, "alb_codigo")}><img alt="capa album" src={getConfig().urlDownloadImagem + _.get(x, "arq_capa.arq_fullname")} style={{width: "100%", marginTop: 15, paddingLeft: 5}} /></Link>
                  </Col>
                  <Col xs={9} md={10} sm={9} className="wdMobile pl-2 centralizar-vertical">
                    <Link to={"/album/" + _.get(x, "alb_codigo")}>
                      <p style={{fontSize: "14px", fontWeight: "bold", marginTop: "-2px"}}>{x.alb_nome}</p>
                      <p style={{fontSize: "12px", marginTop: "-4px"}}>Cadastrado em: <Moment format="DD/MM/YYYY hh:mm:ss">{x.alb_data}</Moment></p>
                    </Link>
                  </Col>
                </Row>
              </div>))}
          </div>
        } 
        <div className="colRow p-t-b-30 text-center">
          <a href={getConfig().rotabase + "#/albuns"}>
            <Button variant="outline-info">
              <FontAwesomeIcon icon="plus"/> MAIS ÁLBUNS
            </Button>
          </a>
         </div>
      </div>
    )
  }
}

export default CardUltimosAlbuns
