import gql from 'graphql-tag'
export const PAGINA_FRAGMENT = gql`

fragment PaginaInfoComponente on Psv_paginaportalType {
  
  pgt_codigo
  pgt_ishome
  pgt_publicada
  pgt_rotaacesso
  pgt_metadescription
  pgt_metakeywords
  pgt_titulo
  pgt_modalaviso
  asn_codigo{
      asn_codigo
      asn_cor
      asn_titulo
  }
  psv_arquivospagina{
    aqp_codigo
    aqp_ordem
    arq_codigo{
      arq_codigo
      arq_descricao
      arq_fullname
      arq_mimetype
      arq_nome
    }
  }
  psv_estruturaspagina{
    epg_codigo
    epg_coluna
    epg_linha
    epg_tamanho
    pgt_codigo{
      pgt_codigo
    }

    psv_componentespagina{
      alb_codigo{
        alb_codigo
      }

      msv_codigo{
        msv_codigo
      }
      pgc_codigo
      pgc_conteudo
      pgc_ordem
      pgc_tipocomponente
    }
  }
}`