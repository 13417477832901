import { AbaseAutocompleteField, AbaseTextField } from 'abase-components';
import React, { Fragment } from 'react';
import reactCSS from 'reactcss';
import { menuComponentes } from '../tiposComponentes';
import { Form, Button, Col, Modal, ButtonToolbar, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfiguraColunasLinha, CardComponentePagina, EditorHtmlV2 } from './';
import Swal from "sweetalert2";
import { TipoComponente } from '../../../enums';
import { EditorState, ContentState } from 'draft-js';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import _ from 'lodash';

import htmlToDraft from 'html-to-draftjs';
import { SketchPicker } from 'react-color';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { ASSUNTO_ALL } from '../AssuntoGql';
import { MENU_ALL } from '../MenuGql';
import { ALBUM_ALL } from '../AlbumGql';
import { PAGINA_ALL } from '../PaginaGql';

import FroalaEditor from 'react-froala-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'filepond/dist/filepond.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';

import { getConfig } from '../../config';

registerPlugin(FilePondPluginFileValidateType);

const targets = [
    {
      id: "_self",
      name: "Mesma página"
    },
    {
      id: "_blank",
      name: "Outra página"
    }
  ]

export default function EditorPagina(props) {
    const [modalShow, setModalShow] = React.useState(false)
    let linhas = (props.value || []).reduce((prev, next) => {
        prev[next.epg_linha] = prev[next.epg_linha] || { colunas: [] }
        if (next.epg_coluna !== -1)
            prev[next.epg_linha].colunas.push(next)
        
        prev[next.epg_linha].colunas = _.orderBy(prev[next.epg_linha].colunas,"epg_coluna", "asc");
        
        return prev
    }, []);

    const setLinhas = novasLinhas => {
        const resultado = []
        for (let linhaIndex in novasLinhas) {
            const linha = novasLinhas[linhaIndex].colunas
            for (let colunaIndex in linha) {
                resultado.push({ ...linha[colunaIndex], epg_linha: +linhaIndex, epg_coluna: +colunaIndex })
            }
            if (linha.length === 0) {
                resultado.push({ epg_linha: +linhaIndex, epg_coluna: -1 })
            }
        }
        props.onChange(resultado)
    }

    const [componenteAdicionar, setComponenteAdicionar] = React.useState({})
    const [linhaAdicionar, setLinhaAdicionar] = React.useState(0)
    const [colunaAdicionar, setColunaAdicionar] = React.useState(0)
    const [editorState, setEditorState] = React.useState(0)
    const [editarRegistro, setEditarRegistro] = React.useState(null)
    const [banner, setBanner] = React.useState({})
    const [slideBanner, setSlideBanner] = React.useState([])
    const [exibirColorPicker, setExibirColorPicker] = React.useState(false)
    const [exibirColorPickerFonte, setExibirColorPickerFonte] = React.useState(false)
    const [assuntoRelacionado, setAssuntoRelacionado] = React.useState({})
    const [menuUtilizar, setMenuUtilizar] = React.useState({})
    const [albumUtilizar, setAlbumUtilizar] = React.useState({})
    const [tipoFiltroAssunto, setTipoFiltroAssunto] = React.useState('herdar')
    const [tipoExibicaoNoticias, setTipoExibicaoNoticias] = React.useState('card')
    const [qtdeNoticiasExibir, setQtdeNoticiasExibir] = React.useState(10)
    const [larguraCard, setLarguraCard] = React.useState(3)
    const [formularioNewsletter, setFormularioNewsletter] = React.useState({})
    const [conteudoHtml, setConteudoHtml] = React.useState('')
    const [alturaBanner, setAlturaBanner] = React.useState(null);

    const styles = reactCSS({
        'default': {
            color: {
                width: '80px',
                height: '24px',
                borderRadius: '2px',
                background: typeof banner.fundo === 'object' ? `rgba(${banner.fundo.r}, ${banner.fundo.g}, ${banner.fundo.b}, ${banner.fundo.a})` : 'rgba(0,0,0,1)',
            },
            textColor: {
                width: '80px',
                height: '24px',
                borderRadius: '2px',
                background: typeof banner.corFonte === 'object' ? `rgba(${banner.corFonte.r}, ${banner.corFonte.g}, ${banner.corFonte.b}, ${banner.corFonte.a})` : 'rgba(0,0,0,1)',
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const adicionarLinhaConteudo = () => {
        setLinhas([...linhas, { colunas: [] }])
    }

    const modalClose = () => {
        setModalShow(false)
        setEditorState(null)
        setComponenteAdicionar({})
    }

    const definirColunasLinha = (indiceLinha, colunas) => {
        const newArray = Array.from({ length: colunas })
        let linha = linhas.map((x, i) => {
            if (i === indiceLinha) {
                const r = {
                    ...x, colunas: newArray.map((x, index) => ({
                        epg_codigo: null,
                        pgt_codigo: null,
                        epg_linha: indiceLinha,
                        epg_coluna: index,
                        epg_tamanho: (12/parseInt(colunas)),
                        psv_componentespagina: []
                    }))
                }
                return r
            }
            return x
        });
        setLinhas(linha);
    }

    const excluirComponenteLinhaColuna = (linha, coluna, ordem) => {
        const newLinhas = [...linhas]
        newLinhas[linha] = { ...newLinhas[linha], colunas: [...newLinhas[linha].colunas] }
        newLinhas[linha].colunas[coluna] = { ...newLinhas[linha].colunas[coluna], psv_componentespagina: _.orderBy(newLinhas[linha].colunas[coluna].psv_componentespagina,"pgc_ordem", "asc").filter((x, i) => i !== ordem) }
        newLinhas[linha].colunas[coluna].psv_componentespagina.map((componente, indice) => {
            componente.pgc_ordem = indice;
        });
        setLinhas(newLinhas)
    }

    const editarComponenteLinhaColuna = (linha, coluna, ordem) => {
        setLinhaAdicionar(linha)
        setColunaAdicionar(coluna)
        let componente = _.orderBy(linhas[linha].colunas[coluna].psv_componentespagina,"pgc_ordem","asc")[ordem];
        if (componente.pgc_tipocomponente === TipoComponente.html) {
            const blocksFromHtml = htmlToDraft(componente.pgc_conteudo);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
        else if (componente.pgc_tipocomponente === TipoComponente.slideBanner) {
            if (typeof componente.pgc_conteudo === 'string') componente.pgc_conteudo = JSON.parse(componente.pgc_conteudo);
            setAlturaBanner(componente.pgc_conteudo[0].altura || null);
            setSlideBanner(componente.pgc_conteudo);
        }
        else if (componente.pgc_tipocomponente === TipoComponente.htmlV2) {
            setConteudoHtml(componente.pgc_conteudo);
        }
        else if (componente.pgc_tipocomponente === TipoComponente.banner) {
            if (typeof componente.pgc_conteudo === 'string') componente.pgc_conteudo = JSON.parse(componente.pgc_conteudo);
            setBanner(componente.pgc_conteudo);
        }
        else if (componente.pgc_tipocomponente === TipoComponente.newsletter) {
            if (typeof componente.pgc_conteudo === 'string') componente.pgc_conteudo = JSON.parse(componente.pgc_conteudo);
            setFormularioNewsletter(componente.pgc_conteudo);
        }
        else if (componente.pgc_tipocomponente === TipoComponente.assuntosRelacionados || componente.pgc_tipocomponente === TipoComponente.ultimaNoticiaAssunto || componente.pgc_tipocomponente === TipoComponente.cardUltimasNoticias || componente.pgc_tipocomponente === TipoComponente.cardUltimosVideos || componente.pgc_tipocomponente === TipoComponente.cardUltimosAlbuns) {
            if (typeof componente.pgc_conteudo === 'string') componente.pgc_conteudo = JSON.parse(componente.pgc_conteudo);
            setTipoFiltroAssunto(componente.pgc_conteudo.tipoFiltroAssunto);
            setAssuntoRelacionado(componente.pgc_conteudo.assuntoRelacionado);
            if (componente.pgc_tipocomponente === TipoComponente.cardUltimasNoticias){
                setTipoExibicaoNoticias(componente.pgc_conteudo.tipoExibicaoNoticias);
                setQtdeNoticiasExibir(componente.pgc_conteudo.qtdeNoticiasExibir);
            }
        }
        else if (componente.pgc_tipocomponente === TipoComponente.acessoRapido || componente.pgc_tipocomponente === TipoComponente.menu) {
            if (typeof componente.pgc_conteudo === 'string') componente.pgc_conteudo = JSON.parse(componente.pgc_conteudo);
            setMenuUtilizar(componente.pgc_conteudo);
        }
        setComponenteAdicionar({...componente})
        setModalShow(true)
    }
    const excluirLinhaConteudo = (indiceLinha) => {
        Swal.fire({
            title: "Confirma Exclusão?",
            type: "warning",
            text: "Você deseja realmente excluir a linha " + (parseInt(indiceLinha) + 1) + "?",
            footer: "",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#c0c0c0',
            confirmButtonText: 'Sim, Excluir!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                let linhasConteudo = [...linhas];
                linhasConteudo.splice(indiceLinha, 1);
                setLinhas(linhasConteudo);
            }
        });
    }

    const adicionarComponente = () => {
        let linhasConteudo = linhas;
        let componente = componenteAdicionar;
        switch (componente.pgc_tipocomponente) {
            case TipoComponente.html:
            case TipoComponente.htmlV2:
                componente.pgc_conteudo = conteudoHtml;
                //setComponenteAdicionar({ ...componenteAdicionar, pgc_conteudo: conteudoHtml })
                break;
            case TipoComponente.IFrame:
                componente.pgc_conteudo = (typeof componente.pgc_conteudo !== 'string' ? JSON.stringify(componente.pgc_conteudo) : componente.pgc_conteudo);
                break;
            case TipoComponente.midia:
                if (!!componente.pgc_conteudo === false) {
                    Swal.fire({
                        title: 'Atenção!',
                        type: 'warning',
                        text: 'Você precisa definir a mídia para adicionar o conteúdo.'
                    })
                    return false;
                }
                break;
            case TipoComponente.pdf:
            if (!!componente.pgc_conteudo === false) {
                Swal.fire({
                    title: 'Atenção!',
                    type: 'warning',
                    text: 'Você precisa definir o PDF para adicionar o conteúdo.'
                })
                return false;
            }
            break;
            case TipoComponente.banner:
                componente.pgc_conteudo = banner;//(typeof banner !== 'string' ? JSON.stringify(banner) : banner);
                break;
            case TipoComponente.slideBanner:
                if (slideBanner.length === 0) {
                    Swal.fire({
                        title: 'Atenção!',
                        type: 'warning',
                        text: 'Você precisa definir os banners para apresentação deste componente.'
                    })
                    return false;
                }
                componente.pgc_conteudo = (typeof slideBanner === 'string' ? JSON.parse(slideBanner) : slideBanner);
                componente.pgc_conteudo[0].altura = alturaBanner;
                componente.pgc_conteudo = (typeof slideBanner !== 'string' ? JSON.stringify(slideBanner) : slideBanner);
                setAlturaBanner(null);
                break;
            // || componenteAdicionar.pgc_tipocomponente === 
            case TipoComponente.assuntosRelacionados:
            case TipoComponente.ultimaNoticiaAssunto:
            case TipoComponente.cardUltimasNoticias:
            case TipoComponente.cardUltimosVideos:
            case TipoComponente.cardUltimosAlbuns:
                let dados = {}
                dados.tipoFiltroAssunto = tipoFiltroAssunto;
                dados.assuntoRelacionado = assuntoRelacionado;
                if (componente.pgc_tipocomponente === TipoComponente.cardUltimasNoticias || componente.pgc_tipocomponente === TipoComponente.cardUltimosAlbuns || componente.pgc_tipocomponente === TipoComponente.cardUltimosVideos){
                    dados.tipoExibicaoNoticias = tipoExibicaoNoticias;
                    dados.qtdeNoticiasExibir = qtdeNoticiasExibir;
                    dados.larguraCard = larguraCard;
                }
                componente.pgc_conteudo = JSON.stringify(dados);
                break;
            case TipoComponente.menu:
            case TipoComponente.acessoRapido:
                componente.pgc_conteudo = JSON.stringify(menuUtilizar);
                break;
            case TipoComponente.album:
                componente.pgc_conteudo = JSON.stringify(albumUtilizar);
                break;
            case TipoComponente.newsletter:
                componente.pgc_conteudo = JSON.stringify(formularioNewsletter);
                break;
            default:
                break;
        }

        if (componente.pgc_ordem === null) {
            componente.pgc_ordem = linhasConteudo[linhaAdicionar].colunas[colunaAdicionar].psv_componentespagina.length;
            linhasConteudo[linhaAdicionar].colunas[colunaAdicionar].psv_componentespagina.push(componente);
        }
        else {
            linhasConteudo[linhaAdicionar].colunas[colunaAdicionar].psv_componentespagina = _.orderBy(linhasConteudo[linhaAdicionar].colunas[colunaAdicionar].psv_componentespagina,"pgc_ordem","asc");
            linhasConteudo[linhaAdicionar].colunas[colunaAdicionar].psv_componentespagina[parseInt(componente.pgc_ordem)] = componente;
        }
        setLinhas(linhasConteudo)
        setComponenteAdicionar({ pgc_codigo: null, epg_codigo: null, alb_codigo: null, msv_codigo: null, pgc_ordem: null, pgc_conteudo: null, pgc_tipocomponente: null })
        setBanner({ titulo: '', texto: '', fundo: '', link:'', target: null, paginaRedirecionar: null });
        modalClose();
    }

    const incluirBannerSlide = () => {
        if (editarRegistro === null) setSlideBanner([...slideBanner, banner]);
        else {
            let alterar = slideBanner;
            alterar[editarRegistro] = {...banner};
            setSlideBanner([...alterar]);
        }
        setTimeout(() => {
            setEditarRegistro(null);
            setBanner({ titulo: '', texto: '', fundo: '', link:'', target: null, paginaRedirecionar: null });
        }, 500);
    }

    const uploadArquivoConcluido = (erro, arquivo) => {
        let dadosUpload = JSON.parse(arquivo.serverId);
        componenteAdicionar.pgc_conteudo = dadosUpload.data.id;
    }

    const uploadFundoBannerConcluido = (erro, arquivo) => {
        let dadosUpload = JSON.parse(arquivo.serverId);
        let dadosBanner = banner;
        dadosBanner.fundo = getConfig().urlDownloadImagem + dadosUpload.data.id;
        setBanner(dadosBanner);
    }

    const abrirModalAdicionar = (linhaAdicionar, colunaAdicionar) => {
        setLinhaAdicionar(linhaAdicionar)
        setColunaAdicionar(colunaAdicionar)
        setModalShow(true)
    }

    const preparaComponenteLinhaColunaAdicionar = (tipoComponente) => {
        let componente = { pgc_codigo: null, epg_codigo: null, alb_codigo: null, msv_codigo: null, pgc_ordem: null, pgc_conteudo: '', pgc_tipocomponente: null };
        componente.pgc_tipocomponente = tipoComponente;
        setComponenteAdicionar(componente)
        setEditorState(EditorState.createEmpty())
    }

    return (
        <Fragment>
            <Form.Row>
                <Col xs={12} className="text-center">
                    <h6>Editor de Conteúdo da página</h6>
                    <div className="detalhe-admin"></div>
                </Col>
                <Col xs={12} className="text-center m-t-5">
                    <Button size="sm" variant="outline-secondary" onClick={adicionarLinhaConteudo}><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Adicionar Linha</Button>
                </Col>
            </Form.Row>

            <div className="colRow m-t-15">
                {
                    linhas.map((linha, i) => (
                        <Form.Row key={i} className="m-b-t-10">
                            {linha.colunas.length === 0 &&
                                <ConfiguraColunasLinha indiceLinha={i} defineColunas={definirColunasLinha} />
                            }
                            {linha.colunas.length > 0 &&
                                <Fragment>
                                    {_.orderBy(linha.colunas,"epg_coluna","asc").map((coluna, x) => (
                                        <Col className="border p-15 boxColuna" sm={coluna.epg_tamanho} key={x}>
                                            <div className="m-b-10 text-center">
                                                <b> Coluna {x + 1} </b> -&nbsp;
                                                <small>
                                                    <select value={coluna.epg_tamanho} onChange={(e) => {
                                                        coluna.epg_tamanho = e.target.value;
                                                        setLinhas(linhas);
                                                    }}>
                                                        <option value={null}>Tamanho não definido</option>
                                                        <option value={1}>8,3% (col-1)</option>
                                                        <option value={2}>16,6% (col-2)</option>
                                                        <option value={3}>25% (col-3)</option>
                                                        <option value={4}>33,3% (col-4)</option>
                                                        <option value={5}>41,6% (col-5)</option>
                                                        <option value={6}>50% (col-6)</option>
                                                        <option value={7}>58,3% (col-7)</option>
                                                        <option value={8}>66,6% (col-8)</option>
                                                        <option value={9}>75% (col-9)</option>
                                                        <option value={10}>83,3% (col-10)</option>
                                                        <option value={11}>91,6% (col-11)</option>
                                                        <option value={12}>100% (col-12)</option>
                                                    </select>
                                                </small>
                                            </div>
                                            {_.orderBy(coluna.psv_componentespagina,"pgc_ordem", "asc").map((componente, z) => (
                                                <CardComponentePagina key={z} linha={coluna.epg_linha} coluna={coluna.epg_coluna} ordem={componente.pgc_ordem} excluirConteudoLinhaColuna={excluirComponenteLinhaColuna} editarComponenteLinhaColuna={editarComponenteLinhaColuna} cpt_tipocomponente={componente.pgc_tipocomponente} pgc_conteudo={componente.pgc_conteudo} alb_codigo={componente.alb_codigo} />
                                            ))}
                                            <div className="text-center m-t-15">
                                                <Button variant="outline-secondary" size="sm" onClick={() => abrirModalAdicionar(coluna.epg_linha, coluna.epg_coluna)}><FontAwesomeIcon icon="plus" />&nbsp;Adicionar Conteúdo</Button>
                                            </div>
                                        </Col>
                                    ))}
                                    <Button variant="secondary" size="sm" onClick={() => excluirLinhaConteudo(i)}><FontAwesomeIcon icon="trash" />&nbsp;Excluir linha</Button>
                                </Fragment>
                            }
                        </Form.Row>
                    ))
                }
            </div>

            <Modal
                show={modalShow}
                onHide={modalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Adicionar Conteúdo {!!componenteAdicionar.pgc_tipocomponente ? `(${menuComponentes.filter(m => m.tipo === componenteAdicionar.pgc_tipocomponente)[0].label})` : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!componenteAdicionar.pgc_tipocomponente === false &&
                        <div>
                            <p>Selecione o tipo de componente que deseja adicionar à página.</p>
                            <ButtonToolbar className="mt-3">
                                {
                                    menuComponentes.map((item, i) => {
                                        return <Button key={i} variant="light" className="m-1" size="sm" onClick={() => preparaComponenteLinhaColunaAdicionar(item.tipo)}><FontAwesomeIcon icon={item.icone} /><br />{item.label}</Button>;
                                    })
                                }
                            </ButtonToolbar>
                        </div>
                    }
                    {/*componenteAdicionar.pgc_tipocomponente === TipoComponente.html &&
                        <div style={{ height: '400px', zIndex: 1 }}>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                localization={{
                                    locale: 'pt',
                                }}
                                toolbar={{
                                    image: { previewImage: true, uploadCallback: getConfig().uploadImageCallBack, alt: { present: true, mandatory: false } }
                                }}
                            />
                        </div>
                            */}

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.arquivosAnexos &&
                        <div>
                        <Alert variant="success">
                            <Alert.Heading><FontAwesomeIcon icon="paperclip"/>&nbsp;&nbsp;Arquivos anexos à página</Alert.Heading>
                                <p>
                                Este componente irá apresentar a lista de arquivos que estiverem anexados a página disponibilizando aos usuários o download dos mesmos. Utilize este componenete para posicionar a lista de anexos onde desejar dentro do conteúdo dá página.
                                </p>
                            <hr />
                            <p className="mb-0">
                            * Se este componente não for incluído no conteúdo da página, e existirem anexos a ela, o portal irá adicionar automaticamente o componente ao final do conteúdo da página.
                            </p>
                        </Alert>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.newsletter &&
                        <div>
                            <Alert variant="success">
                                <Alert.Heading><FontAwesomeIcon icon="mail-bulk"/>&nbsp;&nbsp;Formulário de Inscrição - Newsletter</Alert.Heading>
                                    <p>
                                    Este comeponente irá apresentar na página o formulário para inscrição no sistema de Newsletter do site. Você pode configurar abaixo o titulo e subtitulo apresentados no formulário:
                                    </p>
                            </Alert>
                            <Form>
                                <Form.Group className="p-relative">
                                    <div className="colRow">
                                        <label className="title-label">Título do formulário</label>
                                    </div>
                                    <Form.Control type="text" placeholder="" value={componenteAdicionar.pgc_conteudo.titulo} onChange={e => setFormularioNewsletter({ ...formularioNewsletter, titulo: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="p-relative">
                                    <div className="colRow">
                                        <label className="title-label">Subtítulo fo formulário</label>
                                    </div>
                                    <Form.Control type="text" placeholder="" value={componenteAdicionar.pgc_conteudo.subtitulo} onChange={e => setFormularioNewsletter({ ...formularioNewsletter, subtitulo: e.target.value })} />
                                </Form.Group>
                            </Form>
                        </div>
                    }

                    {(componenteAdicionar.pgc_tipocomponente === TipoComponente.htmlV2 || componenteAdicionar.pgc_tipocomponente === TipoComponente.html) &&
                        <div>
                            
                            <FroalaEditor
                            model={!!componenteAdicionar ? componenteAdicionar.pgc_conteudo : ''}
                            config={{
                                attribution: false,
                                language: 'pt_br',
                                fileUploadURL: getConfig().urlUploadImagem+'V2',
                                fileUploadParams: {
                                    'imageSize': '1024',
                                    'psv_codigo': getConfig().idPortal
                                },
                                imageUploadURL: getConfig().urlUploadImagem+'V2',
                                imageUploadParams: {
                                    'imageSize': '1024',
                                    'psv_codigo': getConfig().idPortal
                                }
                            }}
                            
                            onModelChange={(e) => {
                                setComponenteAdicionar({ ...componenteAdicionar, pgc_conteudo: e});
                                setConteudoHtml(e);
                            }}
                            />
                            
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.IFrame &&
                        <div>
                            <Form>
                                <Form.Group className="p-relative">
                                    <div className="colRow">
                                        <label className="title-label">Link para exibição no iframe</label>
                                    </div>
                                    <Form.Control type="text" placeholder="http://..." value={componenteAdicionar.pgc_conteudo.link} onChange={e => setComponenteAdicionar({ ...componenteAdicionar, pgc_conteudo: {...componenteAdicionar.pgc_conteudo, link: e.target.value } })} />
                                </Form.Group>
                                <Form.Group className="p-relative">
                                    <div className="colRow">
                                        <label className="title-label">Altura do iframe</label>
                                    </div>
                                    <Form.Control type="number" placeholder="defina a altura do iframe em pixels (ex: 500)" min={100} value={componenteAdicionar.pgc_conteudo.altura} onChange={e => setComponenteAdicionar({ ...componenteAdicionar, pgc_conteudo: { ...componenteAdicionar.pgc_conteudo, altura: e.target.value} })} onBlur={e => {
                                        if (e.target.value < 100){
                                            setComponenteAdicionar({ ...componenteAdicionar, pgc_conteudo: { ...componenteAdicionar.pgc_conteudo, altura: 100} });
                                            Swal.fire({
                                                title: 'Atenção!',
                                                type: 'warning',
                                                text: 'A altura do IFrame deve ser de no mínimo 100px.'
                                            })
                                        }
                                    }} />
                                </Form.Group>
                            </Form>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.titulo &&
                        <div>
                            <Form>
                                <Form.Group className="p-relative">
                                    <div className="colRow">
                                        <label className="title-label">Texto</label>
                                    </div>
                                    <Form.Control type="text" placeholder="Informe aqui o texto desejado para o titulo" value={componenteAdicionar.pgc_conteudo} onChange={e => setComponenteAdicionar({ ...componenteAdicionar, pgc_conteudo: e.target.value })} />
                                </Form.Group>
                            </Form>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.pdf &&
                        <div style={{ zIndex: 1 }}>
                            <Form>
                                <div className="col-xs-12">
                                    <p style={{fontSize: 17, fontWeight: 600}}>Faça o upload de um arquivo PDF:</p>
                                </div>
                                <div className="mt-4 col-xs-12 text-center">
                                    <FilePond
                                        name="image"
                                        acceptedFileTypes={['application/pdf']}
                                        allowMultiple={false}
                                        server={{
                                            url: getConfig().urlUploadImagem,
                                            process: {
                                                headers: {
                                                'psv_codigo': getConfig().idPortal
                                                }
                                            }
                                            }}
                                        onprocessfile={uploadArquivoConcluido}
                                        labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> <b>Clique para selecionar o arquivo</b> </span>"
                                        labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                                        labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                                        labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                                        labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                                        labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                                        labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" 
                                    />
                                </div>
                            </Form>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.midia &&
                        <div style={{ height: '350px', zIndex: 1 }}>
                            <Form>
                                <Form.Group className="p-relative">
                                    <div className="colRow">
                                        <label className="title-label">Link da mídia on-line</label>
                                    </div>
                                    <Form.Control type="text" placeholder="http://..." value={componenteAdicionar.pgc_conteudo} onChange={e => setComponenteAdicionar({ ...componenteAdicionar, pgc_conteudo: e.target.value })} />
                                    <Form.Text className="text-muted">
                                        Informe nesse campo o link da mídia online, podendo ser um vídeo do youtube, vimeo, ou um audio no soundcloud etc.
                                    </Form.Text>
                                </Form.Group>
                                <div className="col-xs-12 text-center">
                                    <strong>OU</strong>
                                    <p>Faça o upload de um arquivo de audio ou vídeo:</p>
                                </div>
                                <div className="mt-4 col-xs-12 text-center">
                                    <FilePond
                                        name="image"
                                        acceptedFileTypes={['video/*', 'audio/*']}
                                        allowMultiple={false}
                                        server={{
                                            url: getConfig().urlUploadImagem,
                                            process: {
                                              headers: {
                                                'psv_codigo': getConfig().idPortal
                                              }
                                            }
                                          }}
                                        onprocessfile={uploadArquivoConcluido}
                                        labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> <b>Clique para selecionar o arquivo</b> </span>"
                                        labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                                        labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                                        labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                                        labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                                        labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                                        labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                                </div>
                            </Form>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.imagem &&
                        <div>
                            <Form>
                                <div className="mt-4 col-xs-12 text-center">
                                    <FilePond
                                        name="image"
                                        acceptedFileTypes={['image/*']}
                                        allowMultiple={false}
                                        server={{
                                            url: getConfig().urlUploadImagem,
                                            process: {
                                                headers: {
                                                    'psv_codigo': getConfig().idPortal,
                                                    'imageSize': '1024'
                                                }
                                            }
                                        }}
                                        onprocessfile={(erro, arquivo) => {
                                            let dadosUpload = JSON.parse(arquivo.serverId);
                                            componenteAdicionar.pgc_conteudo = dadosUpload.data.id;
                                        }}
                                        labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> <b>Clique para selecionar</b> </span>"
                                        labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                                        labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                                        labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                                        labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                                        labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                                        labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                                </div>
                            </Form>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.album &&
                        <div>
                            <Form.Group>
                                <Query pollInterval={4000} query={ALBUM_ALL}>{({ data, loading }) => (
                                    <Field name="albumUtilizar" >{({ input, meta }) => (
                                        <React.Fragment>
                                            <AbaseAutocompleteField isLoading={loading} label={"Selecione o Album"}
                                                data={_.get(data, "psv_albuns.list", []).map((x) => {
                                                    if (input.value && input.value.alb_codigo === x.alb_codigo) {
                                                        return input.value
                                                    }
                                                    return x
                                                })}
                                                value={input.value}
                                                textField={'alb_nome'}
                                                onChange={value => {
                                                    input.onChange(value)
                                                    setAlbumUtilizar(value);
                                                }}
                                                getOptionValue={x => x}
                                            />
                                        </React.Fragment>
                                    )}</Field>
                                )}</Query>
                            </Form.Group>
                            <Form.Group>
                                <a href={window.location.origin + getConfig().rotabase +"admin#/albuns/manutencao-album"} target="_blank" className="btn btn-sm btn-secondary">
                                    <FontAwesomeIcon icon="plus" />&nbsp;Novo Album
                                </a>
                            </Form.Group>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.banner &&
                        <div style={{ zIndex: 1 }}>
                            <Form>
                                <Form.Group className="p-relative m-t-15">
                                    <label className="title-label"> Título do Banner </label>
                                    <Form.Control type="text" placeholder="Informe aqui o título do banner..." value={banner.titulo} onChange={e => setBanner({ ...banner, titulo: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="p-relative">
                                    <label className="title-label"> Texto do Banner </label>
                                    <Form.Control as="textarea" rows="4" placeholder="Informe aqui o texto/conteúdo do banner..." value={banner.texto} onChange={e => setBanner({ ...banner, texto: e.target.value })} />
                                </Form.Group>
                                <Form.Group>
                                    <div className="colRow">
                                        <label className="f-12"> Tipo de fundo do banner </label>
                                    </div>
                                    <Form.Check
                                        type="radio"
                                        inline
                                        label="Cor de fundo"
                                        name="tipoFundoBanner"
                                        id="cor"
                                        checked={banner.tipoFundo === "cor"}
                                        onChange={e => setBanner({ ...banner, tipoFundo: "cor" })}
                                    />
                                    <Form.Check className="m-t-5"
                                        type="radio"
                                        inline
                                        label="Imagem de fundo"
                                        name="tipoFundoBanner"
                                        id="imagem"
                                        checked={banner.tipoFundo === "imagem"}
                                        onChange={e => setBanner({ ...banner, tipoFundo: "imagem" })}
                                    />
                                </Form.Group>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label className="f-12"> Selecione a cor para o texto do banner </label>
                                    </div>
                                    <div className="col-sm-12">
                                        {exibirColorPickerFonte ? <div style={styles.popover}>
                                            <div style={styles.cover} onClick={() => setExibirColorPickerFonte(false)} />
                                            <SketchPicker name="corFonte" color={banner.corFonte} onChange={e => setBanner({ ...banner, corFonte: e.rgb })} />
                                        </div> : null}
                                        <div style={styles.swatch} onClick={() => setExibirColorPickerFonte(true)}>
                                            <div style={styles.textColor} />
                                        </div>
                                    </div>
                                </div>
                                {banner.tipoFundo === "cor" &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label className="f-12"> Selecione a cor de fundo </label>
                                        </div>
                                        <div className="col-sm-12">
                                            {exibirColorPicker ? <div style={styles.popover}>
                                                <div style={styles.cover} onClick={() => setExibirColorPicker(false)} />
                                                <SketchPicker color={banner.fundo} onChange={e => setBanner({ ...banner, fundo: e.rgb })} />
                                            </div> : null}
                                            <div style={styles.swatch} onClick={() => setExibirColorPicker(true)}>
                                                <div style={styles.color} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {banner.tipoFundo === "imagem" &&
                                    <div>
                                        <Form.Group className="p-relative m-t-15">
                                            <label className="title-label"> Link da Imagem de fundo </label>
                                            <Form.Control type="text" placeholder="http://..." value={banner.fundo} onChange={e => setBanner({ ...banner, fundo: e.target.value })} />
                                        </Form.Group>
                                        <div className="mt-4 col-xs-12 text-center">
                                            Ou faça o upload de um arquivo de imagem:
                                        </div>
                                        <div className="mt-4 col-xs-12 text-center">
                                            <FilePond
                                                name="image"
                                                acceptedFileTypes={['image/*']}
                                                allowMultiple={false}
                                                server={{
                                                    url: getConfig().urlUploadImagem,
                                                    process: {
                                                      headers: {
                                                        'imageSize': '1920',
                                                        'psv_codigo': getConfig().idPortal
                                                      }
                                                    }
                                                  }}
                                                onprocessfile={uploadFundoBannerConcluido}
                                                labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> Clique para selecionar o arquivo </span>"
                                                labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                                                labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                                                labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                                                labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                                                labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                                                labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                                                <div className="col-sm-12 text-right">
                                                    {!!banner.fundo ? (<div >
                                                    <label className="f-12 m-t-23 m-r-5">Pré visualização:</label>
                                                    <img className="m-t-15" src={banner.fundo} width={120} />
                                                    </div>) : null}
                                                </div>
                                        </div>
                                    </div>
                                }
                                <Form.Group>
                                    <Query
                                        query={PAGINA_ALL}
                                        >{({ data, loading }) => (
                                            <React.Fragment>
                                                <AbaseAutocompleteField isLoading={loading} label={"Redirecionar para"}
                                                data={(_.get(data, "psv_paginaportal.list", []) || []).map(x => {
                                                    if (banner.paginaRedirecionar && banner.paginaRedirecionar.pgt_codigo === x.pgt_codigo) {
                                                        return banner.paginaRedirecionar;
                                                    }
                                                    return x;
                                                })}
                                                value={banner.paginaRedirecionar}
                                                onChange={e => {
                                                    setBanner({...banner, paginaRedirecionar: e, link: ''});
                                                }}
                                                getOptionValue={x => x}
                                                textField={"pgt_titulo"}
                                                />
                                                <AbaseTextField fullWidth={true} label={"URL direta"} placeholder={banner.paginaRedirecionar ? "Remova a página para informar o link direto" : ""} disabled={banner.paginaRedirecionar} value={banner.link} onChange={e => {setBanner({...banner, paginaRedirecionar: null, link: e.target.value})}} />
                                            </React.Fragment>
                                    )}</Query>                                
                                </Form.Group>
                                <Form.Group>
                                    <AbaseAutocompleteField fullWidth={true} valueField={"id"} textField={"name"} label={"Modo"} data={targets} value={banner.target} onChange={e => { setBanner({...banner, target: e}) }} />
                                </Form.Group>
                            </Form>
                        </div>
                    }

                    {componenteAdicionar.pgc_tipocomponente === TipoComponente.slideBanner &&
                        <div style={{ zIndex: 1 }}>
                            <Form>
                                <Form.Group className="p-relative m-t-15">
                                    <label className="title-label"> Título do Banner </label>
                                    <Form.Control type="text" placeholder="Informe aqui o título do banner..." value={banner.titulo} onChange={e => setBanner({ ...banner, titulo: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="p-relative">
                                    <label className="title-label"> Texto do Banner  </label>
                                    <Form.Control as="textarea" rows="3" placeholder="Informe aqui o texto/conteúdo do banner..." value={banner.texto} onChange={e => setBanner({ ...banner, texto: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="p-relative">
                                    <label className="title-label">Altura do Banner (opcional)</label>
                                    <Form.Control type="number" placeholder="Informe aqui a altura do banner em pixels, se não informada será utilizada a altura padrão de 400px" value={alturaBanner} onChange={e => setAlturaBanner(e.target.value )} />
                                </Form.Group>
                                <Form.Group>
                                    <Query
                                        query={PAGINA_ALL}
                                        >{({ data, loading }) => (
                                            <React.Fragment>
                                                <AbaseAutocompleteField isLoading={loading} label={"Redirecionar para"}
                                                data={(_.get(data, "psv_paginaportal.list", []) || []).map(x => {
                                                    if (banner.paginaRedirecionar && banner.paginaRedirecionar.pgt_codigo === x.pgt_codigo) {
                                                        return banner.paginaRedirecionar;
                                                    }
                                                    return x;
                                                })}
                                                value={banner.paginaRedirecionar}
                                                onChange={e => {
                                                    setBanner({...banner, paginaRedirecionar: e, link: ''});
                                                }}
                                                getOptionValue={x => x}
                                                textField={"pgt_titulo"}
                                                />
                                                <AbaseTextField fullWidth={true} label={"URL direta"} placeholder={banner.paginaRedirecionar ? "Remova a página para informar o link direto" : ""} disabled={banner.paginaRedirecionar} value={banner.link} onChange={e => {setBanner({...banner, paginaRedirecionar: null, link: e.target.value})}} />
                                            </React.Fragment>
                                    )}</Query>                                
                                </Form.Group>
                                <Form.Group>
                                    <AbaseAutocompleteField fullWidth={true} valueField={"id"} textField={"name"} label={"Modo"} data={targets} value={banner.target} onChange={e => { setBanner({...banner, target: e}) }} />
                                </Form.Group>

                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <FilePond
                                            name="image"
                                            acceptedFileTypes={['image/*']}
                                            allowMultiple={false}
                                            server={{
                                                url: getConfig().urlUploadImagem,
                                                process: {
                                                  headers: {
                                                    'imageSize': '1920',
                                                    'psv_codigo': getConfig().idPortal
                                                  }
                                                }
                                              }}
                                            onprocessfile={(erro, arquivo) => {
                                                let dadosUpload = JSON.parse(arquivo.serverId);
                                                setBanner({ ...banner, fundo: dadosUpload.data.link});
                                                setTimeout(() => {
                                                    arquivo.abortLoad();
                                                }, 3500);
                                            }}
                                            labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> <b>Clique para selecionar o arquivo</b> </span>"
                                            labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                                            labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                                            labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                                            labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                                            labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                                            labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                                            <div className="col-sm-12 text-right">
                                                {!!banner.fundo ? (<div >
                                                <label className="f-12 m-t-23 m-r-5">Pré visualização:</label>
                                                <img className="m-t-15" src={banner.fundo} width={120} />
                                                </div>) : null}
                                            </div>
                                    </div>
                                    <div className="col-sm-12 m-t-10 m-b-10 text-center" style={{marginTop: 40}}>
                                        <Button variant="success" size="sm" onClick={incluirBannerSlide} disabled={(!!banner.fundo === false)} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;{editarRegistro === null ? 'Incluir Banner' : 'Alterar Banner'}</Button>
                                    </div>
                                </div>
                                <div className="col-sm-12 m-t-15 p-0">
                                    <Table striped bordered hover size="sm" className="f-12">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>TÍTULO</th>
                                                <th>IMAGEM</th>
                                                <th>OPERAÇÕES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {slideBanner.length > 0 && slideBanner.map((conteudo, indice) => (
                                                <tr key={indice}>
                                                    <td>{indice + 1}</td>
                                                    <td>{conteudo.titulo}</td>
                                                    <td><img src={conteudo.fundo} width={54} /></td>
                                                    <td className="text-right">
                                                        <Button size="sm" variant="primary" onClick={() => {
                                                            setEditarRegistro(indice);
                                                            setBanner(slideBanner[indice]);
                                                        }}><FontAwesomeIcon icon="edit" /></Button>
                                                        &nbsp;&nbsp;
                                                        <Button size="sm" variant="secondary" onClick={() => {
                                                            Swal.fire({
                                                                title: "Confirma Exclusão?",
                                                                type: "warning",
                                                                text: "Você deseja realemnte excluir o banner " + (parseInt(indice) + 1) + "?",
                                                                footer: "",
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#d33',
                                                                cancelButtonColor: '#c0c0c0',
                                                                confirmButtonText: 'Sim, Excluir!',
                                                                cancelButtonText: 'Cancelar'
                                                            }).then((result) => {
                                                                if (result.value) {
                                                                    let slide = [...slideBanner];
                                                                    slide.splice(indice, 1);
                                                                    setSlideBanner(slide);
                                                                }
                                                            });
                                                        }}><FontAwesomeIcon icon="trash" /></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {(slideBanner.length === 0) &&
                                                <tr>
                                                    <td colSpan={4} className="text-center mt-2">
                                                        <Alert variant="warning" className="text-center">
                                                            <FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Nenhum registro encontrado
                                                        </Alert>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Form>
                        </div>
                    }

                    {(componenteAdicionar.pgc_tipocomponente === TipoComponente.menu || componenteAdicionar.pgc_tipocomponente === TipoComponente.acessoRapido) &&    
                    <div>
                        <Form.Group>
                            <Query pollInterval={4000} query={MENU_ALL}>{({ data, loading }) => (
                                <Field name="menuUtilizar" >{({ input, meta }) => (
                                    <React.Fragment>
                                        <AbaseAutocompleteField isLoading={loading} label={"Selecione o Menu"}
                                            data={_.get(data, "psv_menuportal.list", []).map((x) => {
                                                if (input.value && input.value.msv_codigo === x.msv_codigo) {
                                                    return input.value
                                                }
                                                return x
                                            })}
                                            value={input.value}
                                            textField={'msv_nome'}
                                            onChange={value => {
                                                input.onChange(value)
                                                setMenuUtilizar(value);
                                            }}
                                            getOptionValue={x => x}
                                        />
                                    </React.Fragment>
                                )}</Field>
                            )}</Query>
                        </Form.Group>
                        <Form.Group>
                            <a href={window.location.origin + getConfig().rotabase +"admin#/menus/manutencao-menu"} target="_blank" className="btn btn-sm btn-secondary">
                                <FontAwesomeIcon icon="plus" />&nbsp;Novo Menu
                            </a>
                        </Form.Group>
                        {componenteAdicionar.pgc_tipocomponente === TipoComponente.acessoRapido &&
                            <Form.Group className="p-relative m-t-15">
                                <label className="title-label"> Título </label>
                                <Form.Control type="text" placeholder="Informe aqui o título do acesso rápido..." value={menuUtilizar.titulo || ''} onChange={e => setMenuUtilizar({ ...menuUtilizar, titulo: e.target.value })} />
                            </Form.Group>
                        }
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                inline
                                label="Exibir na Horizontal"
                                name="tipoExibicaoMenu"
                                id="horizontal"
                                checked={menuUtilizar.msv_tipo === 0}
                                onChange={e => setMenuUtilizar({ ...menuUtilizar, msv_tipo: 0 })}
                            />
                            <Form.Check
                                type="radio"
                                inline
                                label="Exibir na Vertical"
                                name="tipoExibicaoMenu"
                                id="vertical"
                                checked={menuUtilizar.msv_tipo === 1}
                                onChange={e => setMenuUtilizar({ ...menuUtilizar, msv_tipo: 1 })}
                            />
                        </Form.Group>
                    </div>}

                    {(componenteAdicionar.pgc_tipocomponente === TipoComponente.assuntosRelacionados || componenteAdicionar.pgc_tipocomponente === TipoComponente.ultimaNoticiaAssunto || componenteAdicionar.pgc_tipocomponente === TipoComponente.cardUltimasNoticias || componenteAdicionar.pgc_tipocomponente === TipoComponente.cardUltimosVideos || componenteAdicionar.pgc_tipocomponente === TipoComponente.cardUltimosAlbuns) &&
                        <div style={{ zIndex: 1 }}>
                            <Form>
                                <div className="mb-3">
                                    <Form.Group>
                                        <Form.Check
                                            isValid
                                            checked={tipoFiltroAssunto === 'assunto'}
                                            type="radio"
                                            label="Definir Assunto"
                                            name="radioTipoFiltroAssunto"
                                            id="assunto"
                                            feedback="Você seleciona um assunto fixo e predefinido para exibição do conteúdo."
                                            onChange={e => setTipoFiltroAssunto('assunto')}
                                        />
                                        <Form.Check className="m-t-5"
                                            isValid
                                            checked={tipoFiltroAssunto === 'herdar'}
                                            type="radio"
                                            label="Herdar Assunto da Página"
                                            name="radioTipoFiltroAssunto"
                                            id="herdar"
                                            feedback={(componenteAdicionar.pgc_tipocomponente !== TipoComponente.assuntosRelacionados ? "O portal irá apresentar os dados ref. ao asunto relacionado a página, se a página não possuir assunto relacionado o portal irá buscar os dados por ordem decrescente de cadastro." : "O portal irá apresentar os dados ref. ao asunto relacionado a página, se a página não possuir assunto relacionado o portal não irá exibir este componente.")}
                                            onChange={e => setTipoFiltroAssunto('herdar')}
                                        />
                                        {componenteAdicionar.pgc_tipocomponente !== TipoComponente.assuntosRelacionados &&
                                            <Form.Check className="m-t-5"
                                                isValid
                                                checked={tipoFiltroAssunto === ''}
                                                type="radio"
                                                label="Sem assunto"
                                                name="radioTipoFiltroAssunto"
                                                id="nenhum"
                                                feedback="Este componente não terá nenhum vinculo com assunto. Os dados serão apresentados por ordem decrescente de cadastro."
                                                onChange={e => setTipoFiltroAssunto('')}
                                            />}
                                    </Form.Group>
                                </div>
                                {tipoFiltroAssunto === 'assunto' &&
                                    <Form.Group>
                                        <Query query={ASSUNTO_ALL}>{({ data, loading }) => (
                                            <Field name="assuntoRelacionado" >{({ input, meta }) => (
                                                <React.Fragment>
                                                    <AbaseAutocompleteField isLoading={loading} label={"Assunto"}
                                                        data={_.get(data, "psv_assuntonoticia.list", []).map((x) => {
                                                            if (input.value && input.value.asn_codigo === x.asn_codigo) {
                                                                return input.value
                                                            }
                                                            return x
                                                        })}
                                                        value={assuntoRelacionado}
                                                        textField={'asn_titulo'}
                                                        onChange={value => {
                                                            input.onChange(value)
                                                            setAssuntoRelacionado(value);
                                                        }}
                                                        getOptionValue={x => x}
                                                    />
                                                </React.Fragment>
                                            )}</Field>
                                        )}</Query>
                                    </Form.Group>}
                                    {(componenteAdicionar.pgc_tipocomponente === TipoComponente.cardUltimasNoticias || componenteAdicionar.pgc_tipocomponente === TipoComponente.cardUltimosVideos || componenteAdicionar.pgc_tipocomponente === TipoComponente.cardUltimosAlbuns) &&
                                        <Fragment>
                                            <hr />
                                            <Form.Group>
                                                <Form.Check
                                                    isValid
                                                    checked={tipoExibicaoNoticias === 'carrossel' || !!tipoExibicaoNoticias === false}
                                                    type="radio"
                                                    label="Apresentar conteúdo em forma de carrossel"
                                                    name="radioTipoExibicaoNoticias"
                                                    id="noticiasCarrossel"
                                                    feedback="Os dados serão exibidas em formato de lista de cartões em uma única linha com rolagem horizontal."
                                                    onChange={e => setTipoExibicaoNoticias('carrossel')}
                                                />
                                                <Form.Check
                                                    isValid
                                                    checked={tipoExibicaoNoticias === 'card' || !!tipoExibicaoNoticias === false}
                                                    type="radio"
                                                    label="Apresentar conteúdo em Cards"
                                                    name="radioTipoExibicaoNoticias"
                                                    id="noticiasCards"
                                                    feedback="Os conteúdos serão exibidas em formato de lista de cartões, quebra a linha conforme tamanho definido de largura."
                                                    onChange={e => setTipoExibicaoNoticias('card')}
                                                />
                                                <Form.Check className="m-t-5"
                                                    isValid
                                                    checked={tipoExibicaoNoticias === 'lista'}
                                                    type="radio"
                                                    label="Apresentar conteúdos em Lista"
                                                    name="radioTipoExibicaoNoticias"
                                                    id="noticiasLista"
                                                    feedback="Os conteúdos serão exibidas em formato de listagem/tabela de dados."
                                                    onChange={e => setTipoExibicaoNoticias('lista')}
                                                />

                                            </Form.Group>
                                            {tipoExibicaoNoticias === 'card' &&
                                                <Form.Group>
                                                    <label className="title-Label"> Largura do Card</label>
                                                    <select value={larguraCard} onChange={(e) => {
                                                        setLarguraCard(e.target.value);
                                                    }}>
                                                        <option value={2}>16,6% (col-2)</option>
                                                        <option value={3}>25% (col-3)</option>
                                                        <option value={4}>33,3% (col-4)</option>
                                                        <option value={5}>41,6% (col-5)</option>
                                                        <option value={6}>50% (col-6)</option>
                                                        <option value={7}>58,3% (col-7)</option>
                                                        <option value={8}>66,6% (col-8)</option>
                                                        <option value={9}>75% (col-9)</option>
                                                        <option value={10}>83,3% (col-10)</option>
                                                        <option value={11}>91,6% (col-11)</option>
                                                        <option value={12}>100% (col-12)</option>
                                                    </select>
                                                </Form.Group>
                                            }
                                            <Form.Group>
                                                <label className="title-label"> Quantidade de registros à exibir </label>
                                                <Form.Control type="number" placeholder="Informe aqui o quantidade..." value={qtdeNoticiasExibir} onChange={e => setQtdeNoticiasExibir(e.target.value)} />
                                            </Form.Group>
                                        </Fragment>
                                    }
                            </Form>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {!!componenteAdicionar.pgc_tipocomponente === true && <Button size="sm" variant="success" hidden={editarRegistro !== null} onClick={adicionarComponente}><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar Componente</Button>}
                    <Button variant="secondary" size="sm" onClick={modalClose}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                </Modal.Footer>
            </Modal>
            {/* <pre>
                {JSON.stringify(props.value, null, 4)}
            </pre> */}
        </Fragment>
    )
}