import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Spinner, Alert, Modal, FormCheck } from 'react-bootstrap';
import { FacebookShareCount, FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon } from 'react-share';
import _ from 'lodash';
import renderHTML from 'react-render-html';
import {Helmet} from "react-helmet";
import { CabecalhoNoticia, GraphQLClient, ArquivosAnexos, Album, IFrame, Titulo, UltimaNoticiaAssunto, CardUltimasNoticias, CardUltimosAlbuns, CardUltimosVideos, Midia, Imagem, Banner, SlideBanner, Menu, AssuntosRelacionados, AcessoRapido, FormularioNewsletter, VisualizadorPdf } from '../components';
import { TipoComponente } from '../enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';

import "../froala.css";
import "./Pagina.css";

class EducarWebCmsPagina extends Component {

  constructor(props){
    super(props);

    this.exibirAlerta = this.exibirAlerta.bind(this);
    this.fecharAlerta = this.fecharAlerta.bind(this);
    this.naoExibirAlertaNovamente = this.naoExibirAlertaNovamente.bind(this);

    this.state = { exibeModalAviso: null, naoExibirNovamente: null };
  }

  fecharAlerta() {
    this.setState({ exibeModalAviso: false });
    if (!!this.state.naoExibirNovamente){
      let listaNaoExibirNovamente = JSON.parse(localStorage.getItem('AlertasLidos')) || [];
      listaNaoExibirNovamente.push({id: this.state.naoExibirNovamente});
      localStorage.setItem('AlertasLidos', JSON.stringify(listaNaoExibirNovamente));
    }
  }

  exibirAlerta(alerta) {
    let listaNaoExibirNovamente = JSON.parse(localStorage.getItem('AlertasLidos')) || [];
    if (_.filter(listaNaoExibirNovamente, (obj) => {return obj.id === alerta.id}).length === 0 && Date.now() <= new Date(alerta.exibirAte).getTime()) this.setState({ exibeModalAviso: true });
  }

  naoExibirAlertaNovamente(id) {
    this.setState({naoExibirNovamente: id});
  }

  componentDidMount(){
    ReactGA.pageview(window.location.href);
  }

  renderHeaderBanner = () => {
    if (!!!!this.props.portalservicos && !!this.props.portalservicos.psv_banner){
      let banner = JSON.parse(this.props.portalservicos.psv_banner);
      if ((banner.exibirBannerPagina === "inicial" && (this.props.pagina === '' || this.props.pagina === '/' || this.props.pagina === 'home')) || banner.exibirBannerPagina === "todas"){
        let conteudoBanner = JSON.stringify(banner.slideBanner);
        return (
          <div className="colRow">
            <SlideBanner conteudo={conteudoBanner} altura={banner.altura} />
          </div>
        )
      }
    }
    else return null;
  }

  render() {
    const params = ((!!this.props.match && !!this.props.match.params) ? this.props.match.params : null );
    let filtro = null;
    if (!!this.props.pagina) {
      filtro = this.props.pagina === '' || this.props.pagina === '/' || this.props.pagina === 'home' ? 'pgt_ishome:true' : `pgt_rota:"${this.props.pagina}"`;
    }
    else {
      filtro = `ntc_codigo: ${params.noticia}`;
    }
    const query = `
      {
        psv_paginaportal{ 
          list(${filtro}) { 
            pgt_codigo, 
            pgt_titulo, 
            pgt_ishome, 
            pgt_rotaacesso,
            pgt_metadescription,
      			pgt_metakeywords, 
            pgt_publicada,
            pgt_modalaviso,
            asn_codigo {
              asn_codigo,
              asn_titulo,
            },
            psv_noticias {
      			  ntc_datapublicacao
              ntc_titulo
              ntc_resumo
              arq_capa{
                arq_fullname
              }
              psv_assuntosnoticia{
                asn_codigo
                asn_cor
                asn_titulo
              }
      			}, 
            psv_estruturaspagina { 
              epg_codigo, 
              epg_linha, 
              epg_coluna, 
              epg_tamanho, 
              psv_componentespagina { 
                pgc_codigo, 
                pgc_ordem, 
                pgc_tipocomponente, 
                pgc_conteudo,
                alb_codigo {
                  alb_codigo
                }
              } 
            },
            psv_arquivospagina{
              arq_codigo{
                arq_codigo,
                arq_descricao,
                arq_mimetype,
                arq_nome,
                arq_fullname
              }
            } 
          } 
        }
      }
    `;

    let ComponenteAnexos = false;
    let exibeModalAviso = false;

    return (
      <Fragment>
        <GraphQLClient query={query}>
          {({ data, isLoading, error }) => {
            if (isLoading) {
              return <div className="text-center m-5"><Spinner animation="border" /></div>;
            }
            if (!data) {
              return <Alert variant="warning" className="text-center m-t-15"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Ooops! Esta página ainda não possui conteúdo.</Alert>;
            }
            if (data) {
              if (data.psv_paginaportal.list[0].psv_estruturaspagina.length === 0) {
                return <Alert variant="warning" className="text-center m-t-15"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Ooops! Esta página ({data.psv_paginaportal.list[0].pgt_titulo}) ainda não possui conteúdo.</Alert>;
              }
              else {

                let dadosCompartilharPagina = { idPagina: data.psv_paginaportal.list[0].pgt_codigo, urlPortal: window.location.href };
                let shareUrl = window.location.origin + `/Psv_portalservicos/LinkShare?url=${btoa(JSON.stringify(dadosCompartilharPagina))}`;

                let dadosAviso = JSON.parse(data.psv_paginaportal.list[0].pgt_modalaviso);
                if (!!dadosAviso && !!dadosAviso.exibir && this.state.exibeModalAviso === null) this.exibirAlerta(dadosAviso);

                return (
                  <Fragment>
                    <Helmet>
                      <title>{data.psv_paginaportal.list[0].pgt_titulo + ' | ' + ((!!this.props.portalservicos && !!this.props.portalservicos.psv_headtitle) ? this.props.portalservicos.psv_headtitle : '')}</title>
                      <meta name="description" content={!!data.psv_paginaportal.list[0].pgt_metadescription ? data.psv_paginaportal.list[0].pgt_metadescription : ''} />
                      <meta name="keywords" cpntent={!!data.psv_paginaportal.list[0].pgt_metakeywords ? data.psv_paginaportal.list[0].pgt_metakeywords : ''} />
                    </Helmet>

                    {!!dadosAviso && 
                    <Modal size={"lg"} centered backdrop="static" show={this.state.exibeModalAviso} onHide={this.fecharAlerta}>
                      <Modal.Header closeButton closeLabel="Fechar alerta" style={{height: '50px', backgroundColor: '#167ac6', color: '#fff', borderRadius: 0}}>
                        <Modal.Title style={{fontSize: 18}}>{dadosAviso.titulo}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="fr-view">
                          {renderHTML(dadosAviso.conteudo)}
                        </div>
                      </Modal.Body>
                      <Modal.Footer style={{textAlign:'left', backgroundColor: '#eeeeee', height: '20px', flexDirection: 'row-reverse', fontSize: 12}}>
                        <FormCheck 
                          checked={!!this.state.naoExibirNovamente}
                          name="naoExibirNovamente"
                          label="Marcar como lido e não exibir novamente."
                          onChange={() => {
                            this.naoExibirAlertaNovamente((!!this.state.naoExibirNovamente ? null : dadosAviso.id));
                          }} />
                      </Modal.Footer>
                    </Modal>}

                    {this.renderHeaderBanner()}
                    <div className="colRow">
                      <Container className="p-t-b-30 m-t-30">
                        {(!!this.props.pagina === false && !!params.noticia === true) && 
                            <Fragment>
                              <CabecalhoNoticia noticia={data.psv_paginaportal.list[0].psv_noticias[0]} />
                            </Fragment>
                        }
                        {_.values(_.groupBy(data.psv_paginaportal.list[0].psv_estruturaspagina, (value) => { return value.epg_linha })).map((item, i) => (
                        
                          <Row key={i}>
                            {_.orderBy(item,"epg_coluna", "asc").map((coluna, idxColuna) => (
                              <Col key={idxColuna} xs={12} sm={coluna.epg_tamanho}>
                                {_.orderBy(coluna.psv_componentespagina,"pgc_ordem", "asc").map((componente, idxComponente) => {
                                  if (componente.pgc_tipocomponente === TipoComponente.arquivosAnexos) ComponenteAnexos = true;
                                  return (
                                  <div key={idxComponente} className="colRow mb-1">
                                    {componente.pgc_tipocomponente === TipoComponente.html && <div className="fr-view">{renderHTML(componente.pgc_conteudo)}</div>}
                                    {componente.pgc_tipocomponente === TipoComponente.htmlV2 && <div className="fr-view">{renderHTML(componente.pgc_conteudo)}</div>}
                                    {componente.pgc_tipocomponente === TipoComponente.IFrame && <IFrame conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.titulo && <Titulo conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.menu && <Menu conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.acessoRapido && <AcessoRapido conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.assuntosRelacionados && <AssuntosRelacionados assuntoPagina={!!data.psv_paginaportal.list[0].asn_codigo ? data.psv_paginaportal.list[0].asn_codigo.asn_codigo : null} conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.banner && <Banner conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.slideBanner && <SlideBanner conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.ultimaNoticiaAssunto && <UltimaNoticiaAssunto assuntoPagina={!!data.psv_paginaportal.list[0].asn_codigo ? data.psv_paginaportal.list[0].asn_codigo.asn_codigo : null} conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.midia && <Midia url={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.imagem && <Imagem url={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.album && <Album conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.newsletter && <FormularioNewsletter conteudo={componente.pgc_conteudo} />}
                                    {componente.pgc_tipocomponente === TipoComponente.cardUltimasNoticias && <CardUltimasNoticias assuntoPagina={!!data.psv_paginaportal.list[0].asn_codigo ? data.psv_paginaportal.list[0].asn_codigo.asn_codigo : null} conteudo={componente.pgc_conteudo} /> }
                                    {componente.pgc_tipocomponente === TipoComponente.cardUltimosVideos && <CardUltimosVideos assuntoPagina={!!data.psv_paginaportal.list[0].asn_codigo ? data.psv_paginaportal.list[0].asn_codigo.asn_codigo : null} conteudo={componente.pgc_conteudo} /> }
                                    {componente.pgc_tipocomponente === TipoComponente.cardUltimosAlbuns && <CardUltimosAlbuns assuntoPagina={!!data.psv_paginaportal.list[0].asn_codigo ? data.psv_paginaportal.list[0].asn_codigo.asn_codigo : null} conteudo={componente.pgc_conteudo} /> }
                                    {componente.pgc_tipocomponente === TipoComponente.arquivosAnexos && <ArquivosAnexos arquivos={data.psv_paginaportal.list[0].psv_arquivospagina} /> }
                                    {componente.pgc_tipocomponente === TipoComponente.pdf && <VisualizadorPdf conteudo={componente.pgc_conteudo} /> }
                                  </div>
                                )})}
                              </Col>  
                            ))}
                          </Row>
                        ))}

                        {(data.psv_paginaportal.list[0].psv_arquivospagina.length > 0 && ComponenteAnexos === false) &&
                          <Row>
                            <ArquivosAnexos arquivos={data.psv_paginaportal.list[0].psv_arquivospagina} />
                          </Row>
                        }

                        {!data.psv_paginaportal.list[0].pgt_ishome &&
                          <div className="mt-3 text-center midias b-1">
                            <span className="colRow m-b-5 social-share">Compartilhar esta {!!this.props.pagina ? 'página' : 'notícia'}:</span>
                            <div className="divCompartilhar">
                              <FacebookShareButton
                                url={shareUrl}
                                quote={data.psv_paginaportal.list[0].pgt_titulo + ' | ' + ((!!this.props.portalservicos && !!this.props.portalservicos.psv_headtitle) ? this.props.portalservicos.psv_headtitle : '')}
                                className="compartilhar-botao">
                                <FacebookIcon
                                  size={32}
                                  round />
                              </FacebookShareButton>
                              <FacebookShareCount
                                className="compartilhar-contador"
                                url={shareUrl}>
                                {count => count}
                              </FacebookShareCount>
                            </div>
                            <div className="divCompartilhar">
                              <WhatsappShareButton
                                url={shareUrl}
                                title={data.psv_paginaportal.list[0].pgt_titulo + ' | ' + ((!!this.props.portalservicos && !!this.props.portalservicos.psv_headtitle) ? this.props.portalservicos.psv_headtitle : '')}
                                className="compartilhar-botao">
                                <WhatsappIcon
                                  size={32}
                                  round />
                              </WhatsappShareButton>
                              <div className="compartilhar-contador">
                                &nbsp;
                              </div>
                            </div>
                            <div className="divCompartilhar">
                              <TwitterShareButton
                                url={shareUrl}
                                title={data.psv_paginaportal.list[0].pgt_titulo + ' | ' + ((!!this.props.portalservicos && !!this.props.portalservicos.psv_headtitle) ? this.props.portalservicos.psv_headtitle : '')}
                                className="compartilhar-botao">
                                <TwitterIcon
                                  size={32}
                                  round />
                              </TwitterShareButton>
                              <div className="compartilhar-contador">
                                &nbsp;
                              </div>
                            </div>
                            <div className="divCompartilhar">
                              <EmailShareButton
                                url={shareUrl}
                                title={data.psv_paginaportal.list[0].pgt_titulo + ' | ' + ((!!this.props.portalservicos && !!this.props.portalservicos.psv_headtitle) ? this.props.portalservicos.psv_headtitle : '')}
                                className="compartilhar-botao">
                                <EmailIcon
                                  size={32}
                                  round />
                              </EmailShareButton>
                              <div className="compartilhar-contador">
                                &nbsp;
                              </div>
                            </div>
                            <div className="divCompartilhar">
                              <a href="javascript:print();">
                                <div aria-label="imprimir" role="button" tabIndex="0" className="SocialMediaShareButton SocialMediaShareButton--printer compartilhar-botao">
                                  <div style={{width: 32, height: 32}}>
                                    <svg viewBox="0 0 64 64" width="32" height="32" className="social-icon social-icon--printer ">
                                      <g>
                                        <circle cx="32" cy="32" r="31" fill="#7f7f7f"></circle>
                                      </g>
                                      <g>
                                      <polyline id="svg_2" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round" stroke="#FFFFFF" points="   649,137.999 675,137.999 675,155.999 661,155.999  " fill="none"/>
                                      <polyline id="svg_3" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round" stroke="#FFFFFF" points="   653,155.999 649,155.999 649,141.999  " fill="none"/>
                                      <polyline id="svg_4" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round" strokeLinecap="round" stroke="#FFFFFF" points="   661,156 653,162 653,156  " fill="none"/>
                                      <path strokeWidth="3" fill="#ffffff" id="svg_5" d="m45.479962,25.56066l-6,0l0,-10c0,-0.552 -0.447,-1 -1,-1l-12,0c-0.552,0 -1,0.448 -1,1l0,10l-6,0c-0.552,0 -1,0.448 -1,1s0.448,1 1,1l6,0l0,2c0,0.553 0.448,1 1,1l12,0c0.553,0 1,-0.447 1,-1l0,-2l5,0l0,10l-5,0l0,-1c0,-0.553 -0.447,-1 -1,-1l-12,0c-0.552,0 -1,0.447 -1,1l0,1l-5,0l0,-7c0,-0.553 -0.448,-1 -1,-1s-1,0.447 -1,1l0,8c0,0.553 0.448,1 1,1l6,0l0,2c0,0.553 0.448,1 1,1l12,0c0.553,0 1,-0.447 1,-1l0,-2l6,0c0.553,0 1,-0.447 1,-1l0,-12c0,-0.552 -0.447,-1 -1,-1zm-8,3l-10,0l0,-12l10,0l0,12zm0,12l-10,0l0,-3l10,0l0,3z"/>
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="compartilhar-contador">&nbsp;</div>
                            </div>
                        </div>
                        }
                      </Container>
                    </div>
                  </Fragment>
                );
              }  
              
            }
            if (error) {
              return <p>{error.message}</p>;
            }
          }}
        </GraphQLClient>  
      </Fragment>
    )
  }
}

export default EducarWebCmsPagina