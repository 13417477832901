import React from 'react';
import { getConfig } from '../pages/config';

export default function VisualizadorPdf(props){
    return (
        <div>
            <embed src={getConfig().urlDownloadImagem + props.conteudo} type="application/pdf" className="col-12" style={{height: 900, zIndex: 10}}/>
        </div>
    )
}
