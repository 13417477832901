import React, { Component } from 'react';
import { Card, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ConfiguraColunasLinha extends Component {

    constructor(props) {
        super(props);
        this.state = { colunas: 1 };
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        );
    }

    definirColunas = () => {
        this.props.defineColunas(this.props.indiceLinha, this.state.colunas)
    }

    render() {
        return (
            <Card className="text-center" bg="light" style={{ width: '100%' }}>
                <Card.Body>
                    <div className="row m-b-15">
                        <div className="col-6 text-right mt-2">
                            Configurar número de colunas da linha {this.props.indiceLinha+1}:
                        </div>
                        <div className="col-6">
                            <FormControl as="select" style={{ width: '6rem' }} name="colunas" onChange={this.handleChange}>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>6</option>
                            </FormControl>
                        </div>
                    </div>
                    <Button variant="primary" size="sm" onClick={this.definirColunas}><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Definir Colunas</Button>
                </Card.Body>
            </Card>
        )
    }
}

export default ConfiguraColunasLinha
