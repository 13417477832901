import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbaseAutocompleteField, AbaseAutoCompleteFieldFinalForm, AbaseCheckbox, AbaseTextField, FormDraft } from 'abase-components';
import arrayMove from 'array-move';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Query } from 'react-apollo';
import { Alert, Button, ButtonToolbar, ListGroup, Modal } from 'react-bootstrap';
import { FilePond } from 'react-filepond';
import { Field } from "react-final-form";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { defaultDecorators } from '../../../services';
import { validate } from '../../../services/validate';
import { getConfig } from '../../config';
import { PAGINA_ALL } from '../PaginaGql';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './EditorMenu.scss';

const targets = [
  {
    id: "_self",
    name: "Mesma página"
  },
  {
    id: "_blank",
    name: "Outra página"
  }
]
const iconPositions = [
  {
    id: 0,
    name: "Direita"
  },
  {
    id: 1,
    name: "Esquerda"
  },
  {
    id: 2,
    name: "Topo"
  }
]
const DragHandle = sortableHandle(() => <Button size={"sm"} className={"button-sort"}><FontAwesomeIcon icon="sort" /></Button>);
const SortableContainer = sortableContainer(({ children }) => {
  return (<ListGroup className="cursorMove">
    {children}
  </ListGroup>)
});
const SortableItem = sortableElement((props) => <MenuRenderer {...props} />);

function MenuRenderer({ currentPath, menu, index, indexItem, ...otherProps }) {
  //const pathToEdit = `${currentPath || ""}[${index}]`
  const pathToEdit = `${currentPath || ""}[${menu.itm_ordem}]`
  const onSortEnd = ({ oldIndex, newIndex }) => {
    otherProps.onSort(`${pathToEdit}.itm_filhos`, oldIndex, newIndex)
  };
  return (
    <ListGroup.Item>
      <div className={classNames("menu-container", { "with-submenu": menu.itm_filhos && menu.itm_filhos.length > 0 })}>
        <div className={"menu-nome"}>
          - {menu.itm_nome}
        </div>
        <ButtonToolbar>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Adicionar</Tooltip>}>
            <Button type={"button"} onMouseDown={() => otherProps.add(pathToEdit)} variant="outline-secondary" size="sm"><FontAwesomeIcon icon="plus" /></Button>
          </OverlayTrigger>
          &nbsp;
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Editar</Tooltip>}>
            <Button type={"button"} onMouseDown={() => otherProps.edit(pathToEdit)} variant="outline-secondary" size="sm"><FontAwesomeIcon icon="edit" /></Button>
          </OverlayTrigger>
          &nbsp;
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Excluir</Tooltip>}>
            <Button type={"button"} onMouseDown={() => otherProps.remove(currentPath, indexItem)} variant="outline-secondary" size="sm"><FontAwesomeIcon icon="trash" /></Button>
            {/* <Button type={"button"} onMouseDown={() => otherProps.remove(currentPath, index)} variant="outline-secondary" size="sm"><FontAwesomeIcon icon="trash" /></Button> */}
          </OverlayTrigger>
          &nbsp;
          <OverlayTrigger placement="bottom" overlay={<Tooltip>{menu.itm_ativo ? 'Ativo' : 'Inativo'}</Tooltip>}>
            <Button type={"button"} variant="outline-secondary" size="sm" disabled><FontAwesomeIcon icon={menu.itm_ativo ? "check-circle" : "times-circle"} color={menu.itm_ativo ? "green" : "red"} /></Button>
          </OverlayTrigger>
        </ButtonToolbar>
      </div>
      <SortableContainer onSortEnd={onSortEnd}>
        {menu.itm_filhos ? <ListGroup className="f-14">{
          menu.itm_filhos.map((menu, index) => <SortableItem currentPath={`${pathToEdit}.itm_filhos`} index={index} indexItem={index} menu={menu} {...otherProps} />)
        }</ListGroup> : null}
      </SortableContainer>
    </ListGroup.Item>
  )
}


export default function EditorMenu({ value, onChange }) {
  const items = value ? JSON.parse(value) : []
  const [showModal, setShowModal] = React.useState(false)
  const [editPath, setEditPath] = React.useState(false)
  const [editItem, setEditItem] = React.useState(null)
  const add = (path) => {
    setEditPath(path)
    setEditItem(null)
    setShowModal(true)

  }
  const remove = (path, index) => {
    Swal.fire({
      title: "Confirma Exclusão?",
      type: "warning",
      text: "Você deseja realmente remover o menu? Todos os itens filhos serão removidos",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#c0c0c0',
      confirmButtonText: 'Sim, Remover!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        const filhos = (path ? _.get(items, path) : items).filter((x, i) => i !== index).map((x, index) => ({ ...x, itm_ordem: index }))
        if (path) {
          _.set(items, path, filhos)
          onChange(JSON.stringify(items))
        } else {
          onChange(JSON.stringify(filhos))
        }
      }
    });
  }
  const edit = (path) => {
    const item = _.get(items, path)
    setShowModal(true)
    setEditPath(path)
    setEditItem(item)
  }
  const onSave = (value) => {
    if (editItem) {
      _.set(items, editPath, value)
      onChange(JSON.stringify(items))

      setEditItem(null)
      setEditPath(null)

    } else {
      if (!editPath) {
        items.push(value)
        onChange(JSON.stringify(items))

      } else {
        const item = _.get(items, editPath)
        item.itm_filhos = item.itm_filhos || []
        item.itm_filhos.push(value)
        item.itm_filhos = item.itm_filhos.map((x, i) => ({ ...x, itm_ordem: i }))
        onChange(JSON.stringify(items))

      }
    }
    setShowModal(false)
  }
  const onSort = (path, oldIndex, newIndex) => {
    let filhos = (path ? _.get(items, path) : items)
    filhos = arrayMove(filhos, oldIndex, newIndex)
    filhos = filhos.map((x, i) => ({ ...x, itm_ordem: i }))
    if (path) {
      _.set(items, path, filhos)
      onChange(JSON.stringify(items))
    } else {
      onChange(JSON.stringify(filhos))
    }
  }
  return (<div className={"menu-editor"}>
    {items.length === 0 ? (<Alert variant={"warning"} className="text-center m-b-0">
    <FontAwesomeIcon icon="exclamation-triangle" />&nbsp;Ainda não há nenhum item de menu cadastrado
        </Alert>) : null}
    <SortableContainer onSortEnd={({ oldIndex, newIndex }) => onSort("", oldIndex, newIndex)}>
      {items.map((menu, index) => <SortableItem menu={menu} index={index} indexItem={index} add={add} remove={remove} edit={edit} onSort={onSort} />)}
    </SortableContainer>
    <div className="text-center m-t-30">
      <Button size="sm" variant="outline-secondary" onClick={() => add("")}><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Adicionar Item de menu</Button>
    </div>
    <MenuModal show={showModal} editItem={editItem} onClose={() => setShowModal(false)} onSave={onSave} />
  </div>)
}


function MenuModal({ show, onClose, editItem, onSave }) {
  const handleSubmit = onSave
  const MenuSchema = Yup.object().shape({
    itm_nome: Yup.string().required(),
    itm_target: Yup.string().notRequired(),
    itm_link: Yup.string().notRequired(),
    itm_icone: Yup.string().notRequired(),
    itm_posicaoicone: Yup.number(),
    itm_ativo: Yup.boolean().notRequired(),
    itn_filhos: Yup.array().notRequired(),

  })
  return (
    <Modal
      show={show}

      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header
        closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          ALTERAR MENU
                </Modal.Title>
      </Modal.Header>
      <FormDraft
        onSubmit={handleSubmit}
        validate={validate(MenuSchema)}
        initialValues={editItem || {
          itm_ativo: true
        }}
        subscription={{
          submitting: true
        }}
        decorators={defaultDecorators}
        render={({ handleSubmit, form, submitting }) => (
          <React.Fragment>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Field name="itm_nome">{({ input, meta }) => (
                  <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Nome"} />
                )}</Field>
                <Field name="itm_icone">{({ input, meta }) => (
                  <React.Fragment>
                    <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Icone"} />
                    <FilePond
                      name="image"
                      acceptedFileTypes={['image/*']}
                      allowMultiple={false}
                      server={{
                        url: getConfig().urlUploadImagem,
                        process: {

                          headers: {
                            'imageSize': '250',
                            'psv_codigo': getConfig().idPortal
                          }


                        }
                      }}
                      onprocessfile={(erro, arquivo) => {
                        if (erro) {
                          return
                        }
                        let dadosUpload = JSON.parse(arquivo.serverId);
                        input.onChange(getConfig().urlDownloadImagem + dadosUpload.data.id)
                      }}
                      labelIdle="Arraste e solte o arquivo aqui ou <span class='filepond--label-action'> <b>Clique para selecionar o arquivo</b> </span>"
                      labelInvalidField="Você adicionou arquivos inválidos" labelFileWaitingForSize="Lendo tamanho do arquivo..." labelFileLoading="Carregando..."
                      labelFileLoadError="Erro ao carregar o arquivo" labelFileProcessing="realizando upload de arquivo" labelFileProcessingComplete="Upload concluído"
                      labelFileProcessingAborted="Upload cancelado!" labelFileProcessingError="Erro ao realizar o upload" labelFileRemoveError="Erro ao remover o arquivo"
                      labelTapToCancel="Cancelar" labelTapToRetry="Toque para tentar novamente" labelTapToUndo="Toque para desfazer" labelButtonRemoveItem="Remover"
                      labelButtonAbortItemLoad="Abortar" labelButtonRetryItemLoad="Tentar novamente" labelButtonAbortItemProcessing="Cancelar"
                      labelButtonUndoItemProcessing="Desfazer" labelButtonRetryItemProcessing="Tentar novamente" />
                    {input.value ? (<div className="text-center">
                      Pré visualização: <img alt="preview" src={input.value} width={40} />
                    </div>) : null}
                  </React.Fragment>
                )}</Field>
                <Field name="itm_posicaoicone">{({ input, meta }) => (
                  <AbaseAutoCompleteFieldFinalForm meta={meta} fullWidth={true} {...input} valueField={"id"} textField={"name"} label={"Posição do icone"} data={iconPositions} />
                )}</Field>
                <Query
                  query={PAGINA_ALL}
                >{({ data, loading }) => (
                  <Field name="itm_link">{({ input: inputLink, metaLink }) => (
                    <Field name="pgt_redirecionar" >{({ input, meta }) => (
                      <React.Fragment>
                        <AbaseAutocompleteField isLoading={loading} label={"Redirecionar para"}
                          data={(_.get(data, "psv_paginaportal.list", []) || []).map(x => {
                            if (input.value && input.value.pgt_codigo === x.pgt_codigo) {
                              return input.value
                            }
                            return x
                          })}
                          value={input.value}
                          onChange={value => {
                            input.onChange(value)
                            if (value) {
                              inputLink.onChange("")
                            }
                          }}
                          getOptionValue={x => x}
                          textField={"pgt_titulo"}
                        />
                        <AbaseTextField meta={metaLink} fullWidth={true} {...inputLink} label={"URL direta"} placeholder={input.value ? "Remova a página para informar o link direto" : ""} disabled={input.value} />
                      </React.Fragment>
                    )}</Field>
                  )}</Field>
                )}</Query>
                <Field name="itm_target">{({ input, meta }) => (
                  <AbaseAutoCompleteFieldFinalForm meta={meta} fullWidth={true} {...input} valueField={"id"} textField={"name"} label={"Modo"} data={targets} />
                )}</Field>
                <Field name="itm_ativo">{({ input, meta }) => (
                  <AbaseCheckbox meta={meta} fullWidth={true} {...input} checked={input.value} label={"Ativo"} />
                )}</Field>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={onClose}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                <Button variant="success" size="sm" type={"submit"} ><FontAwesomeIcon icon="check" disabled={submitting} />&nbsp;&nbsp;Confirmar</Button>
              </Modal.Footer>
            </form>
          </React.Fragment>
        )}
      />
    </Modal>)
}