import * as Yup from 'yup'
Yup.setLocale({
    mixed: {
        // eslint-disable-next-line no-template-curly-in-string
        default: '"${label} não é válido',
        // eslint-disable-next-line no-template-curly-in-string
        required: "${label} é obrigatório"
    },
    number: {
        // eslint-disable-next-line no-template-curly-in-string
        min: '"${label} deve ser maior que ${min}',
    },
    string: {
        // eslint-disable-next-line no-template-curly-in-string
        email: "${label} deve ser um e-mail válido",
        // eslint-disable-next-line no-template-curly-in-string
        required: "${label} é obrigatório"
    }
});