import _ from 'lodash';
import Swal from "sweetalert2";

const defaultErrors = {
    insert: "Ocorreu um erro ao inserir o registro",
    query_one: "Ocorreu um erro ao consultar o registro",
    query_list: "Ocorreu um erro ao consultar os registros",
    update: "Ocorreu um erro ao alterar o registro",
    save: "Ocorreu um erro ao salvar o registro",
    delete: "Ocorreu um erro ao excluir o registro",
    not_found: "O registro que você está tentando acessar não existe",
}
const defaultSuccess = {
    save: "Registro salvo com sucesso",
    delete: "Registro excluido com sucesso",
}
const errorCodes = {
    "REPOSITORY_ENTITY_NOT_FOUND": "Registro não encontrado",
    "POSTGRES": "Ocorreu um erro ao executar a operação"
}
export function defaultErrorMessage(type, originalError) {
    let message = "Ocorreu um erro"
    if (defaultErrors[type]) {
        message = defaultErrors[type]
    }
    const pathErrorExtensions = "networkError.result.errors[0]"
    if (_.get(originalError, `${pathErrorExtensions}.extensions.code`) && errorCodes[_.get(originalError, `${pathErrorExtensions}.extensions.code`)]) {
        let messageDescrition = ""
        //Mensagem default para erros do postgre
        if (_.get(originalError, `${pathErrorExtensions}.extensions.code`) === "POSTGRES") {
            switch (_.get(originalError, "networkError.result.errors[0].extensions.data.Code")) {
                case "23503":
                    messageDescrition = "O registro está sendo utilizado em " + _.get(originalError, `${pathErrorExtensions}.extensions.data.TableName`)
                    break
                default:
                    break
            }
        } else if (_.get(originalError, `${pathErrorExtensions}.extensions.code`) === "INVALID_VALUE") {
            messageDescrition = "Campos obrigatórios não foram informados"
        }

        message += ": " + (messageDescrition ? messageDescrition : errorCodes[_.get(originalError, `${pathErrorExtensions}.extensions.code`)])

    }
    else if (_.get(originalError, `${pathErrorExtensions}.extensions.code`) === "APP") {
        message += ": " + _.get(originalError, `${pathErrorExtensions}.extensions.data.userMessage`)
    } else if (originalError && originalError.message === "Network error: Response not successful: Received status code 400") {
        message += ": A operação falhou"

    }
    else if (originalError && originalError.message) {
        message += ": " + originalError.message
    }

    return message
}
export function errorMessagePaperTable(originalError) {
    if (originalError && originalError.message) {
        return defaultErrorMessage("query_list", originalError)
    }
    return undefined;
}
export function defaultSuccessMessage(type) {
    if (typeof defaultSuccess[type] === "undefined") {
        throw new Error("Tipo não implementado")
    }
    let message = defaultSuccess[type]

    return message
}
export function defaultSnack(type) {
    return {
        message: defaultSuccessMessage(type),
        variant: "success"
    }
}


export function showError(message) {
    Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: message.message ? message.message : message
    })
}


export function showInfo(message) {
    Swal.fire({
        type: 'success',
        title: 'Sucesso',
        text: message.message ? message.message : message
    })
}

export function showAlertMsg(message) {
    Swal.fire({
        type: 'warning',
        title: 'Atenção',
        text: message.message ? message.message : message
    })
}