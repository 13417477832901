import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { FormDraft } from 'abase-components';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../services/errorMessage';
import { defaultDecorators, redirectBackForm } from '../services';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

import { INSCRITOSNEWSLETTER_SAVE } from '../pages/admin/InscritosNewsletterGql';
import { cast, validate } from '../services/validate';
import { client } from '../pages/apollo';
import { getConfig } from '../pages/config';

import './FormularioNewsletter.css';

const FormularioNewsletterSchema = Yup.object()
    .shape({
        inw_codigo: Yup.number().nullable(),
        inw_nome: Yup.string().required().label("Nome"),
        inw_email: Yup.string().required().label("E-Mail"),
    }).noUnknown();

export default function FormularioNewsletter(props) {
    let dados = (typeof props.conteudo === 'string' ? JSON.parse(props.conteudo) : props.conteudo);

    const handleSubmit = async values => {
        try {
          const mutationData = await cast(FormularioNewsletterSchema, values);

          mutationData.inw_ativo = true;
          mutationData.inw_datainscricao = new Date();
          
          await client.mutate({
            mutation: INSCRITOSNEWSLETTER_SAVE,
            variables: {
              data: {
                ...mutationData,
                psv_codigo: { psv_codigo: getConfig().idPortal }
              }
            }
          });
          showInfo("Cadastro em nosso Newsletter efetuado com sucesso!");
          
        } catch (err) {
          showError({ message: "Não foi possível realizar sua inscrição em nossa Newsletter. Verifique se você já não está cadastrado ou tente novamente mais tarde." });
        }
    };

  return (
    <FormDraft
        useDraft={false}
        onSubmit={handleSubmit}
        subscription={{
            submitting: true
        }}
        validate={validate(FormularioNewsletterSchema)}
        decorators={defaultDecorators}
        render={({ handleSubmit, form, submitting, values }) => (
            <div className="modal-newsletter">
                <div>
                    <Form onSubmit={handleSubmit}>
                        <div className="modal-header">
                            <div className="icon-box">						
                                <FontAwesomeIcon icon={faEnvelopeOpen} size="4x"/>
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            {!!dados.titulo && <h4>{dados.titulo}</h4>}
                            {!!dados.subtitulo && <p>{dados.subtitulo}</p>}
                            <div className="input-group">
                                <Field name={"inw_nome"}>
                                    {({ input, meta }) => (
                                        <input type="text" className="form-control" placeholder="Informe aqui seu nome" {...input} />
                                    )}
                                </Field>
                                
                            </div>
                            <div className="input-group">
                                <Field name={"inw_email"}>
                                {({ input, meta }) => (
                                    <input type="email" className="form-control" placeholder="Informe aqui seu melhor e-mail" {...input} />
                                )}
                                </Field>
                                <span className="input-group-btn">
                                    <Button className="btn btn-primary" type={"submit"} disabled={submitting}>Cadastrar</Button>
                                </span>
                            </div>
                        </div>
                    </Form>			
                </div>
            </div>
        )} />
  );
}
