import React, {useState} from 'react';
import Swal from "sweetalert2";

import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../services/errorMessage';
import { graphqlClient } from '../services';

import './DescadastrarNewsletter.css';
import { allSettled } from 'q';

export default function DescadastrarNewsletter() {

    const [email, setEmail] = useState('');

    function descadastrar(e){
        e.preventDefault();
        let codigo = null;
        const query = `
        {
            psv_inscritosnewsletter {
                list(inw_email: "${email}") {
                    inw_codigo
                }
            }
        }
        `;
        
        graphqlClient.request(query)
            .then((result) => {
                codigo = result.psv_inscritosnewsletter.list.length > 0 ? result.psv_inscritosnewsletter.list[0].inw_codigo : null;
                if (!!codigo){
                    Swal.fire({
                        title: "Cancelar Inscrição?",
                        type: "warning",
                        text: "Você deseja realmente cancelar sua inscrição em nosso sistema de newsletter?",
                        footer: "",
                        showCancelButton: true,
                        confirmButtonColor: '#F45',
                        cancelButtonColor: '#c0c0c0',
                        confirmButtonText: 'Sim, cancelar inscrição!',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            const deleteQuery = `
                            mutation {
                                psv_inscritosnewsletter {
                                  delete(id: ${codigo}) {
                                    success
                                  }
                                }
                              }
                        `;
                        console.log('mutation delete', deleteQuery);
                            graphqlClient.request(deleteQuery)
                                .then((result) => {
                                    Swal.fire({
                                        title: 'Inscrição Cancelada!',
                                        type: 'info',
                                        text: `O e-mail ${email} foi removido sistema de newsletter com sucesso!`
                                    });
                                    setEmail('');
                                })
                                .catch((err) => {
                                    showError({ message: defaultErrorMessage("save", err) })
                                })
                        }
                    });
                }
                else {
                    Swal.fire({
                        title: 'Atenção!',
                        type: 'warning',
                        text: 'O e-mail informado não foi localizado na base de dados do nosso sistema de newsletter.'
                    });
                }
            }
            )
            .catch((err) => {
                showError({ message: defaultErrorMessage("save", err) })
            });
    }

  return (
    <div className="body-unsubscribe">
        <div className="container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card card-signin my-5">
                        <div className="card-body">
                            <h5 className="card-title text-center">Newsletter - Cancelar inscrição</h5>
                            <div className="row col-sm-12">
                                <p>
                                    Para cancelar sua inscrição em nosso sistema de newsletter, informe seu endereço de email cadastrado no campo abaixo e clique em <b>Cancelar Inscrição</b>
                                </p>
                            </div>
                            <form className="form-signin" onSubmit={(e) => descadastrar(e)}>
                            <div style={{marginBottom: 30, marginTop: 30}}>
                                <input type="email" id="inputEmail" class="form-control" placeholder="Informe o e-mail cadastrado" value={email} required autofocus onChange={e => setEmail(e.target.value)} />
                            </div>

                            <button className="btn btn-lg btn-danger btn-block text-uppercase" type="submit">Cancelar Inscrição</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
