import React, { Component } from 'react';
import { Container, Row, Spinner, Col, Alert } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import _ from 'lodash'
import { Link } from 'react-router-dom';
import { AbaseAutocompleteMultipleField } from 'abase-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import ReactPlayer from 'react-player';

import Paginacao from '../components/Paginacao'
import GraphQLClient from './../components/GraphQLClient';
import { getConfig } from './config';

export default function ListarTodosVideos(props) {

  const query = `
    query VideoList($start:Int $limit:Int $asn_codigo:[Int]){
      psv_videos{
        pagedList(sortBy:"vid_data" sortDirection:"desc" start:$start limit:$limit asn_codigo:$asn_codigo){
          data{
            vid_codigo
            vid_data
            vid_link
            vid_resumo
            vid_titulo
            asn_codigo{
              asn_codigo
              asn_cor
              asn_titulo
            }
          }
          total
        }
      }
    }
    `;

  const assuntoQuery = `query AssuntoList {
      psv_assuntonoticia {
        list(sortBy: "asn_titulo", apenasComVideos: true) {
          asn_codigo
          asn_cor
          asn_titulo
        }
      }
  }`;

  const page = props.match.params.page ? +props.match.params.page : 1;
  const assuntos = props.match.params.assuntos ? props.match.params.assuntos.split(",").map(x => +x) : []
  
  const setAssuntos = (assuntos) => {
    props.history.push(`/videos/1/${assuntos.join(",")}`)
  }
  const setPage = page => {
    props.history.push(`/videos/${page}/${assuntos.join(",")}`)
  }
  const limit = 20;

  return (
    <Container className="p-t-b-30" key={page}>
      <Helmet>
        <title>{'Vídeos | ' + ((!!props.portalservicos && !!props.portalservicos.psv_headtitle) ? props.portalservicos.psv_headtitle : '')}</title>
      </Helmet>
      <div>
        <div className="colRow p-relative">
          <h3>Vídeos</h3>
          <div className="detalhe"></div>
        </div>
        <div className="colRow">
          <GraphQLClient query={assuntoQuery}>
            {({ data, isLoading: loading }) => (
              <div className="m-b-15">
                <React.Fragment>
                  <AbaseAutocompleteMultipleField
                    isLoading={loading}
                    label={"Filtrar por assunto"}
                    data={_.get(
                      data,
                      "psv_assuntonoticia.list",
                      []
                    )}
                    value={_.get(
                      data,
                      "psv_assuntonoticia.list",
                      []
                    ).filter(x => assuntos.indexOf(x.asn_codigo) !== -1)}
                    onChange={values => setAssuntos(values.map(x => x.asn_codigo))}
                    textField={"asn_titulo"}
                    valueField={"asn_codigo"}
                    getOptionValue={x => x.asn_codigo}
                  />
                </React.Fragment>
              </div>
            )}
          </GraphQLClient>
          <GraphQLClient query={query} variables={{
            start: (page - 1) * limit,
            limit: limit,
            asn_codigo: assuntos && assuntos.length > 0 ? assuntos : undefined
          }}>
            {({ data, isLoading, error }) => {

            if (isLoading) {
              return <div className="text-center m-5"><Spinner animation="border" /></div>;
            }
            if (_.get(data, "psv_videos.pagedList.data", []).length === 0) {
              return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Nenhum registro encontrado</Alert>;
            }
            return (<React.Fragment>
              {_.get(data, "psv_videos.pagedList.data", []).map(x => (
                <div key={x.vid_codigo} className="coList p-15">
                  <Row>
                    <Col xs={3} md={3} sm={7} className="wdMobile">
                      <Link to={"/video/" + _.get(x, "vid_codigo")}>
                      <ReactPlayer class="imgFeeds" url={x.vid_link} light={true} height="100%" width="100%" />
                      </Link>
                    </Col>
                    <Col xs={9} md={9} sm={5} className="wdMobile mt15">
                      <Link to={"/video/" + _.get(x, "vid_codigo")}>
                        <h4>{x.vid_titulo}</h4>
                        <small>Publicada em: <Moment format="DD/MM/YYYY hh:mm:ss">{x.vid_data}</Moment></small>
                        <p>{x.vid_resumo}</p>
                      </Link>
                      <div className="colRow" style={{minHeight: '25px'}}>
                        {!!x.asn_codigo &&
                          <div className="tagCards" style={{backgroundColor: x.asn_codigo.asn_cor, cursor: 'pointer'}} onClick={() => {assuntos.push(x.asn_codigo.asn_codigo);setAssuntos(assuntos)}}>
                            {x.asn_codigo.asn_titulo}
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>))}
                <div className="colRow m-t-15">
                  <Paginacao totalPages={_.get(data, "psv_videos.pagedList.total", 0) > limit ? Math.ceil(_.get(data, "psv_videos.pagedList.total", 0) / limit) : 1} currentPage={page} onChange={page => setPage(page)}></Paginacao>
                </div>
            </React.Fragment>)
          }}</GraphQLClient>
        </div>
      </div>
    </Container>
  )

}
