import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import { Col, Alert, Spinner } from "react-bootstrap";
import { EducarWebCmsFooter, EducarWebCmsHeader, GraphQLClient } from '../components';
import { EducarWebCmsPagina, EducarWebCmsPagina404 } from './';
import ListarTodasNoticias from './ListarTodasNoticias';
import ListarTodosAlbuns from './ListarTodosAlbuns';
import ListarTodosVideos from './ListarTodosVideos';
import VerAlbum from './VerAlbum';
import VerVideo from './VerVideo';
import { getConfig } from "./config";
import BuscaGeral from './BuscaGeral';
import ReactGA from 'react-ga';

library.add(fas);

class App extends Component {

  render() {

    return (
      
        <GraphQLClient query={`{
          psv_portalservicos {
            list(psv_codigo:${getConfig().idPortal}) {
              psv_titulo,
              psv_imagemcabecalho,
              psv_exibirpesquisa,
              psv_rodapeportal,
              psv_banner,
              psv_analytics,
              psv_headtitle,
              psv_fbappid,
              psv_fbimagem,
              psv_linkssociais,
              msv_menu {
                msv_codigo,
                msv_tipo,
                msv_itens
              },
              psv_paginasportal {
                pgt_titulo,
                pgt_codigo,
                pgt_rotaacesso
              }
            } 
          }
        }`}>
          {({ data, isLoading, error }) => {
            if (isLoading) {
              return <Col xs={12}><div className="text-center m-5"><Spinner animation="border" /></div></Col>;
            }
            else if (error) {
                return <Alert variant="danger">{error.message}</Alert>;
            }
            else if (!data) {
                return <p>Nenhum registro encontrado ...</p>;
            }
            else if (data) {
                let menu = [{ itm_codigo: null, itm_nome: 'Início', pgt_redirecionar: { pgt_rotaacesso: 'home' } }, ...JSON.parse(data.psv_portalservicos.list[0].msv_menu.msv_itens)];
                ReactGA.initialize(data.psv_portalservicos.list[0].psv_analytics);
                return (
                  <div>
                      <Router>
                        <HashRouter>
                    <EducarWebCmsHeader logo={data.psv_portalservicos.list[0].psv_imagemcabecalho} menu={menu} midiasSociais={data.psv_portalservicos.list[0].psv_linkssociais} titulo={data.psv_portalservicos.list[0].psv_titulo} exibirCampoPesquisa={data.psv_portalservicos.list[0].psv_exibirpesquisa} />
                          <Switch>
                            {data.psv_portalservicos.list[0].psv_paginasportal.map((pagina, i) => {
                              if (pagina.pgt_rotaacesso !== '/admin'){
                                return <Route key={pagina.pgt_codigo} exact path={'/'+pagina.pgt_rotaacesso} render={() => <EducarWebCmsPagina GATrackingId={data.psv_portalservicos.list[0].psv_analytics} portalservicos={data.psv_portalservicos.list[0]} pagina={(!!pagina.pgt_rotaacesso === true ? pagina.pgt_rotaacesso : '')} />} />;
                              }
                              else if (pagina.pgt_rotaacesso === '/' || pagina.pgt_rotaacesso === ''){
                                return <Route key={pagina.pgt_codigo} exact path="/" render={() => <EducarWebCmsPagina GATrackingId={data.psv_portalservicos.list[0].psv_analytics} portalservicos={data.psv_portalservicos.list[0]} pagina="home" />} />;
                              }
                            })}
                            <Route exact path="/" render={() => <EducarWebCmsPagina GATrackingId={data.psv_portalservicos.list[0].psv_analytics} portalservicos={data.psv_portalservicos.list[0]} pagina="home" />} />;
                            <Route render={props => <EducarWebCmsPagina {...props} portalservicos={data.psv_portalservicos.list[0]} />} path={"/noticia/:noticia"} />
                            <Route render={props => <ListarTodasNoticias {...props} portalservicos={data.psv_portalservicos.list[0]} />} path={"/noticias/:page?/:assuntos?"} />
                            <Route render={props => <ListarTodosVideos {...props} portalservicos={data.psv_portalservicos.list[0]} />} path={"/videos/:page?/:assuntos?"} />
                            <Route render={props => <ListarTodosAlbuns {...props} portalservicos={data.psv_portalservicos.list[0]} />} path={"/albuns/:page?"} />
                            <Route render={props => <VerAlbum {...props} portalservicos={data.psv_portalservicos.list[0]} />} path={"/album/:id?"} />
                            <Route render={props => <VerVideo {...props} portalservicos={data.psv_portalservicos.list[0]} />} path={"/video/:id?"} />
                            <Route render={props => <BuscaGeral {...props} portalservicos={data.psv_portalservicos.list[0]} />} path={"/search/:page?/:search?"} />
                            <Route render={props => <EducarWebCmsPagina404 {...props} portalservicos={data.psv_portalservicos.list[0]} />} />
                          </Switch>
                        </HashRouter>
                      </Router>
                    <EducarWebCmsFooter conteudo={data.psv_portalservicos.list[0].psv_rodapeportal} />
                  </div>  
                );
            }
          }}
        </GraphQLClient>
    );
  }
}

export default App;
