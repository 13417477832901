import React, { Component, Fragment } from 'react';
import renderHTML from 'react-render-html';
import { Spinner, Alert, Row, Col, Button } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Moment from 'react-moment';
import _ from 'lodash';
import { TipoComponente } from '../enums';
import { GraphQLClient } from '../components';
import { AssuntosRelacionados, Banner, SlideBanner, Midia, CabecalhoNoticia } from './';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UltimaNoticiaAssunto extends Component {

  render() {
    const configNoticia = JSON.parse(this.props.conteudo);
    let filtro = "start: 0, limit: 1, sortBy:\"ntc_datapublicacao\", sortDirection:\"desc\"" + (configNoticia.tipoFiltroAssunto === "assunto" ? `, asn_codigo:${configNoticia.assuntoRelacionado.asn_codigo}` : (!!this.props.assuntoPagina ? `, asn_codigo:${this.props.assuntoPagina}` : ""));
    return (
        <GraphQLClient query={`
        {
            psv_noticias {
              list(${filtro}){
                ntc_codigo,
                ntc_datapublicacao,
                ntc_destaque,
                ntc_resumo,
                ntc_titulo,
                ntc_validade,
                psv_assuntosnoticia{
                  asn_codigo,
                  asn_cor,
                  asn_titulo
                },
                pgt_codigo{
                    pgt_codigo,
                    pgt_rotaacesso,
                    psv_estruturaspagina {
                        epg_codigo,
                        epg_coluna,
                        epg_linha,
                        epg_tamanho
                    },
                    psv_estruturaspagina{
                        psv_componentespagina{
                        pgc_tipocomponente,
                        pgc_conteudo
                        }
                    }
                }      
              }
            }
          }
        `}>
        {({ data, isLoading, error }) => {
            if (isLoading) {
                return <div className="text-center m-5"><Spinner animation="border" /></div>;
            }
            if (!data) {
                return<Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Esta notícia não possui nenhuma informação cadastrada.</Alert>;
            }
            if (data) {
                return (<div className="colRow p-t-b-30">
                    <CabecalhoNoticia noticia={data.psv_noticias.list[0]} />
                    <div className="colRow m-t-15">
                      {_.values(_.groupBy(data.psv_noticias.list[0].pgt_codigo.psv_estruturaspagina, (value) => { return value.epg_linha })).map((item, i) => (
                      
                        <Row key={i}>
                          {item.map((coluna, idxColuna) => (
                            <Col key={idxColuna} xs={coluna.epg_tamanho}>
                              {coluna.psv_componentespagina.map((componente, idxComponente) => (
                                <div key={idxComponente}>
                                  {componente.pgc_tipocomponente === TipoComponente.html && renderHTML(componente.pgc_conteudo)}
                                  {componente.pgc_tipocomponente === TipoComponente.menu && <div>MENU</div>}
                                  {componente.pgc_tipocomponente === TipoComponente.acessoRapido && <div>ACESSO RAPIDO</div>}
                                  {componente.pgc_tipocomponente === TipoComponente.assuntosRelacionados && <AssuntosRelacionados conteudo={componente.pgc_conteudo} />}
                                  {componente.pgc_tipocomponente === TipoComponente.banner && <Banner conteudo={componente.pgc_conteudo} />}
                                  {componente.pgc_tipocomponente === TipoComponente.slideBanner && <SlideBanner conteudo={componente.pgc_conteudo} />}
                                  {componente.pgc_tipocomponente === TipoComponente.ultimaNoticiaAssunto && <UltimaNoticiaAssunto conteudo={componente.pgc_conteudo} />}
                                  {componente.pgc_tipocomponente === TipoComponente.midia && <Midia url={componente.pgc_conteudo} />}
                                  {componente.pgc_tipocomponente === TipoComponente.cardUltimasNoticias && <div>Lista ultimas noticias</div>}
                                  {componente.pgc_tipocomponente === TipoComponente.cardUltimosVideos && <div>Lista ultimos vídeos</div>}
                                  {componente.pgc_tipocomponente === TipoComponente.cardUltimosAlbuns && <div>Lista ultimos albuns</div>}
                                </div>
                              ))}
                            </Col>  
                          ))}
                        </Row>
                      ))}
                    </div>
                </div>)
            }
            if (error) {
                return <p>{error.message}</p>;
            }
        }}
        </GraphQLClient>
    )
  }
}

export default UltimaNoticiaAssunto