import React, { Component, Fragment } from 'react';
import { Card, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getConfig } from '../pages/config';

import './Cards.css';

class CardAlbum extends Component {
  render() {
    return (
      <Fragment>
        <div className={"m-t-15 minWidth" + (!!this.props.widthCard ? " width" : "")}>
          <a href={getConfig().rotabase + `#/album/${this.props.album.alb_codigo}`}>
            <Card.Img variant="top" className="imgFeeds" src={ getConfig().urlDownloadImagem + this.props.album.arq_capa.arq_fullname } height={215}/>
          </a>
          <div>
            <a href={getConfig().rotabase + `#/album/${this.props.album.alb_codigo}`}><h5 className="text-uppercase m-b-0 m-t-5 t-overflow titulo">{this.props.album.alb_nome}</h5></a>
            <small className="text-muted small m-b-5">
              Álbum incluído em:&nbsp;<b><Moment format="DD/MM/YYYY hh:mm:ss">{this.props.album.alb_data}</Moment></b>
            </small>
            <div className="m-t-10">
              {!this.props.ocultarLinkTodos && (<Button variant="outline-dark" className="pull-right" size="sm">
                <FontAwesomeIcon icon="list"/> Listar Todos
              </Button>)}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default CardAlbum