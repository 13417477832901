import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbaseAutocompleteField, AbaseCheckbox, AbaseDateTimePickerField, AbaseTextField, FormDraft } from 'abase-components';
import _ from 'lodash';
import { Query } from 'react-apollo';
import { Button, Col, Container, Form, Alert } from 'react-bootstrap';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

import { defaultDecorators, redirectBackForm } from '../../services';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
import { cast, validate } from '../../services/validate';
import { client } from '../apollo';
import { ASSUNTO_ALL } from './AssuntoGql';
import { VIDEO_QUERY_BY_ID, VIDEO_SAVE } from './VideoGql';
import { getConfig } from './../config';

const VideoSchema = Yup.object()
    .shape({
        vid_codigo: Yup.number().nullable(),
        asn_codigo: Yup.object()
            .shape({
                asn_codigo: Yup.number()
            })
            .noUnknown()
            .nullable()
            .notRequired()
            .transform(current => (current && current.asn_codigo ? current : null)),
        vid_titulo: Yup.string().required().label("Título do Vídeo"),
        vid_resumo: Yup.string().notRequired().nullable(),
        vid_link: Yup.string().required().label("Link do Vídeo"),
        vid_data: Yup.string().notRequired().nullable(),
    }).noUnknown();

export default function ManutencaoNoticia({ match: { params }, history, location }) {

    const handleSubmit = async values => {
        try {
          const mutationData = await cast(VideoSchema, values);
          if (mutationData.asn_codigo && !mutationData.asn_codigo.asn_codigo) {
            mutationData.asn_codigo = null;
          };
          await client.mutate({
            mutation: VIDEO_SAVE,
            variables: {
              data: {
                ...mutationData,
                psv_codigo: { psv_codigo: getConfig().idPortal }
              }
            }
          });
          showInfo(defaultSuccessMessage("save"));
          redirectBackForm(history, location);
        } catch (err) {
          showError({ message: defaultErrorMessage("save", err) });
        }
    };

    const notEditting = !params.videoId;
    const id = params.videoId;

    return (
        <Query
      query={VIDEO_QUERY_BY_ID}
      skip={notEditting}
      onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
      }}
      onCompleted={data => {
        if (!notEditting && !_.get(data, "psv_videos.list[0]")) {
          showError({ message: defaultErrorMessage("not_found") });
          redirectBackForm(history, location);
        }
      }}
      variables={{ id }}
    >
      {({ data, loading }) => (

        <div className="colPageAdm">

          <FormDraft
            useDraft={notEditting}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true
            }}
            validate={validate(VideoSchema)}
            initialValues={
              _.get(data, "psv_videos.list[0]") || {
                vid_data: new Date().toISOString()
              }
            }
            decorators={defaultDecorators}
            render={({ handleSubmit, form, submitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col xs={12}>
                    <h3>Manutenção de Vídeo</h3>
                    <div className="detalhe-admin"></div>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"vid_titulo"}>
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Título"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Query query={ASSUNTO_ALL}>
                      {({ data, loading }) => (
                        <Field name="asn_codigo">
                          {({ input, meta }) => (
                            <React.Fragment>
                              <AbaseAutocompleteField
                                isLoading={loading}
                                label={"Assunto"}
                                data={_.get(
                                  data,
                                  "psv_assuntonoticia.list",
                                  []
                                ).map(x => {
                                  if (
                                    input.value &&
                                    input.value.asn_codigo === x.asn_codigo
                                  ) {
                                    return input.value;
                                  }
                                  return x;
                                })}
                                value={input.value}
                                onChange={value => {
                                  input.onChange(value);
                                }}
                                getOptionValue={x => x}
                                getOptionLabel={x => (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <div className="badgeColor"
                                      style={{
                                        background: x.asn_cor
                                      }}
                                    />
                                    <div style={{ lex: 1, paddingLeft: 5 }}>
                                      {x.asn_titulo}
                                    </div>
                                  </div>
                                )}
                              />
                            </React.Fragment>
                          )}
                        </Field>
                      )}
                    </Query>
                  </Col>
                  <Col item xs={12}>
                    <Field name="vid_resumo">
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          rows={4}
                          multiline
                          fullWidth={true}
                          {...input}
                          label={"Resumo"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={9} xs={12}>
                    <Field name={"vid_link"}>
                      {({ input, meta }) => (
                        <AbaseTextField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Link do Vídeo (YouTube/Vimeo)"}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col item sm={3} xs={12}>
                    <Field name="vid_data">
                      {({ input, meta }) => (
                        <AbaseDateTimePickerField
                          meta={meta}
                          fullWidth={true}
                          {...input}
                          label={"Data de publicação"}
                        />
                      )}
                    </Field>
                  </Col>
                </Form.Row>
                <div className="text-right colRow m-t-15">
                  <Button type={"button"} variant="secondary" size="sm" onClick={() => redirectBackForm(history, location)}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                  &nbsp;&nbsp;
                  <Button type={"submit"} variant="success" size="sm" disabled={submitting} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar</Button>
                </div>
              </Form>
            )}></FormDraft>
        </div>
      )}
    </Query>
    );

}