import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbaseAutoCompleteFieldFinalForm, AbaseTextField, FormDraft } from 'abase-components';
import 'filepond/dist/filepond.min.css';
import _ from 'lodash';
import { default as React } from 'react';
import { Query } from 'react-apollo';
import { Button, Col, Form } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import { defaultDecorators, redirectBackForm } from '../../services';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
import { cast, validate } from '../../services/validate';
import { client } from '../apollo';
import EditorMenu from './components/EditorMenu';
import { MENU_QUERY_BY_ID, MENU_SAVE } from './MenuGql';
import { getConfig } from './../config';

const menuPositions = [
  {
    id: 0,
    name: "Horizontal"
  },
  {
    id: 1,
    name: "Vertical"
  }
]

const MenuSchema = Yup.object().shape({
  msv_codigo: Yup.number().nullable(),
  msv_nome: Yup.string().required(),
  msv_tipo: Yup.number().required(),
  msv_itens: Yup.string().required(),
  psv_codigo: Yup.object().shape({
    psv_codigo: Yup.number(),
  }).noUnknown(),
}).noUnknown()


function ManutencaoMenu({ match: { params }, history, location }) {

  const handleSubmit = async (values) => {
    try {
      const mutationData = await cast(MenuSchema, values)

      await client.mutate({
        mutation: MENU_SAVE,
        variables: { data: { ...mutationData, psv_codigo: { psv_codigo: getConfig().idPortal } } }
      })
      showInfo(defaultSuccessMessage("save"))
      redirectBackForm(history, location)
    } catch (err) {
      showError({ message: defaultErrorMessage("save", err) })
    }
  }

  const notEditting = !params.menuId
  const id = params.menuId

  return (
    <Query  
      query={MENU_QUERY_BY_ID}
      skip={notEditting}
      onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) })
        redirectBackForm(history, location)
      }}
      onCompleted={(data) => {
        if (!notEditting && !_.get(data, "psv_menuportal.list[0]")) {
          showError({ message: defaultErrorMessage("not_found") })
          redirectBackForm(history, location)
        }
      }}
      variables={{ id, itm_ativo: false }}>
      {({ data, loading }) => (

        <div className="colPageAdm">

          <FormDraft
            useDraft={notEditting}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true
            }}
            validate={validate(MenuSchema)}
            initialValues={_.get(data, "psv_menuportal.list[0]") || {
              msv_itens: "[]",
              msv_tipo: 0
            }}
            decorators={defaultDecorators}
            render={({ handleSubmit, form, submitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col xs={12}>
                    <h3>Manutenção do Menu</h3>
                    <div className="detalhe-admin"></div>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name={"msv_nome"}>{({ input, meta }) => (
                      <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Nome"} />
                    )}</Field>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Field name="msv_tipo">{({ input, meta }) => (
                      <AbaseAutoCompleteFieldFinalForm meta={meta} fullWidth={true} {...input} valueField={"id"} textField={"name"} label={"Tipo de menu"} data={menuPositions} />
                    )}</Field>

                  </Col>
                </Form.Row>
                <br />
                <Field name={"msv_itens"}>{({ input }) => (
                  <EditorMenu {...input} />

                )}</Field>
                <div className="text-right m-t-30">
                  <Button type={"button"} variant="secondary" size="sm" onClick={() => redirectBackForm(history, location)}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                  &nbsp;&nbsp;
                  <Button type={"submit"} variant="success" size="sm" disabled={submitting} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar</Button>
                </div>
              </Form>
            )}></FormDraft>
        </div>
      )}
    </Query>
  )
}

export default ManutencaoMenu