import React, { Component, Fragment } from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch
} from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  Assuntos,
  Paginas,
  Noticias,
  Newsletter,
  Albuns,
  Videos,
  Configuracoes,
  Menus,
  ManutencaoPagina,
  ManutencaoNoticia,
  ManutencaoNewsletter
} from "./";
import { client as apolloClient } from "../apollo";
import { ApolloProvider } from "react-apollo";
import AbaseDialogProvider from "abase-components/dist/components/AbaseComponents/AbaseDialog/AbaseDialogProvider";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import ManutencaoMenu from "./ManutencaoMenu";
import ManutencaoAssunto from "./ManutencaoAssunto";
import ManutencaoAlbum from "./ManutencaoAlbum";
import ManutencaoVideo from "./ManutencaoVideo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { getConfig } from '../config';

class AdminPortal extends Component {

  componentWillMount() {
    axios.get(window.origin+'/psv_portalservicos/isLoggedIn').then((response) => {
      if (response.status !== 200) window.location = "/Login";
    })
    .catch((erro) => {
      window.location = "/Login";
    });
  }

  logOff = () => {
    axios.get(window.origin + '/Login/SairLogin').then((response) => {
      setTimeout(() => {
        window.location = getConfig().rotabase;
      }, 2000);
    });
  }

  render() {
    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AbaseDialogProvider>
            <ApolloProvider client={apolloClient}>
              <Helmet>
                <title>EducarWeb CMS - Administração</title>
              </Helmet>
              <Navbar collapseOnSelect expand="lg" className="bgm-primary" variant="dark">
                <Navbar.Brand href="#home" className="namePortal">Administrativo - Portal da Educação</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link href="#assuntos">Assuntos</Nav.Link>
                    <Nav.Link href="#menus">Menus</Nav.Link>
                    <Nav.Link href="#paginas">Páginas</Nav.Link>
                    <Nav.Link href="#noticias">Notícias</Nav.Link>
                    <Nav.Link href="#newsletter">Newsletter</Nav.Link>
                    <Nav.Link href="#albuns">Álbuns</Nav.Link>
                    <Nav.Link href="#videos">Vídeos</Nav.Link>
                    <Nav.Link href="#configuracoes">Configurações</Nav.Link>
                  </Nav>
                  <Nav>
                    <Nav.Link onClick={this.logOff}>Sair&nbsp;&nbsp;<FontAwesomeIcon icon="sign-in-alt" /></Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <Router>
                <HashRouter>
                  <Switch>
                    <Route exact path="/assuntos" component={Assuntos} />
                    <Route exact path="/paginas" component={Paginas} />
                    <Route exact path="/noticias" component={Noticias} />
                    <Route exact path="/newsletter" component={Newsletter} />
                    <Route exact path="/albuns" component={Albuns} />
                    <Route exact path="/videos" component={Videos} />
                    <Route
                      exact
                      path="/configuracoes"
                      component={Configuracoes}
                    />
                    <Route exact path="/menus" component={Menus} />
                    <Route
                      exact
                      path="/menus/manutencao-menu/:menuId"
                      component={ManutencaoMenu}
                    />
                    <Route
                      exact
                      path="/menus/manutencao-menu"
                      component={ManutencaoMenu}
                    />
                    <Route
                      exact
                      path="/paginas/manutencao-pagina/:paginaId"
                      component={ManutencaoPagina}
                    />
                    <Route
                      exact
                      path="/paginas/manutencao-pagina"
                      component={ManutencaoPagina}
                    />
                    <Route
                      exact
                      path="/noticias/manutencao-noticia/:noticiaId"
                      component={ManutencaoNoticia}
                    />
                    <Route
                      exact
                      path="/noticias/manutencao-noticia"
                      component={ManutencaoNoticia}
                    />
                    <Route
                      exact
                      path="/assuntos/manutencao-assunto/:assuntoId"
                      component={ManutencaoAssunto}
                    />
                    <Route
                      exact
                      path="/assuntos/manutencao-assunto"
                      component={ManutencaoAssunto}
                    />
                    <Route
                      exact
                      path="/albuns/manutencao-album/:albumId"
                      component={ManutencaoAlbum}
                    />
                    <Route
                      exact
                      path="/albuns/manutencao-album"
                      component={ManutencaoAlbum}
                    />
                    <Route
                      exact
                      path="/videos/manutencao-video/:videoId"
                      component={ManutencaoVideo}
                    />
                    <Route
                      exact
                      path="/videos/manutencao-video"
                      component={ManutencaoVideo}
                    />
                    <Route
                      exact
                      path="/newsletter/manutencao-newsletter/:newsletterId"
                      component={ManutencaoNewsletter}
                    />
                    <Route
                      exact
                      path="/newsletter/manutencao-newsletter"
                      component={ManutencaoNewsletter}
                    />
                  </Switch>
                </HashRouter>
              </Router>
            </ApolloProvider>
          </AbaseDialogProvider>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}

export default AdminPortal;