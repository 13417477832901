import gql from "graphql-tag";
export const ALBUM_ALL = gql`
  query AlbumList($query: String) {
    psv_albuns {
      list(filter: $query, sortBy: "alb_data") {
        alb_codigo
        alb_data
        alb_nome
        arq_capa {
          arq_codigo
          psv_url
        }
      }
    }
  }
`;

export const ALBUM_DELETE_ONE = gql`
  mutation AlbumDelete($id: ID) {
    psv_albuns {
      delete(id: [$id]) {
        success
      }
    }
  }
`;
export const ALBUM_SAVE = gql`
  mutation AlbumSave($data: Psv_albunsInputType) {
    psv_albuns {
      save(data: [$data]) {
        alb_codigo
      }
    }
  }
`;

export const ALBUM_QUERY_BY_ID = gql`
  query AlbumById($id: Int) {
    psv_albuns {
      list(alb_codigo: $id) {
        alb_codigo
        alb_data
        alb_nome
        arq_capa {
          arq_codigo
          psv_url
        }
        psv_albumarquivos {
          alq_codigo
          alq_ordem
          arq_codigo {
            arq_codigo
            psv_url
          }
        }
      }
    }
  }
`;
