import React, { Component, Fragment } from 'react';
import { Navbar, NavDropdown, Nav, Image, Form, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FormDraft } from 'abase-components';
import { Field } from 'react-final-form';
import { withRouter } from 'react-router';

class EducarWebCmsHeader extends Component {

  constructor(props){
    super(props);
    let sociais = !!this.props.midiasSociais ? JSON.parse(this.props.midiasSociais) : {};
    this.state = {
      fonte: 0,
      contraste: false,
      midiasSociais: sociais
    }
  }

  trataRota = (rota)=>{
    if (!!rota){
      if(rota.indexOf("/") === -1){
        return "/"+rota
      }
      return rota
    }
  }

  renderizaMenuDropDown = (itemMenu, dropConfig) => {
    return (
      <NavDropdown key={itemMenu.itm_codigo} title={itemMenu.itm_nome} drop={(!!dropConfig ? dropConfig : "down")} id="basic-nav-dropdown">
        {itemMenu.itm_filhos.map((menu, i) => {
          if (!!menu.itm_filhos === false || menu.itm_filhos.length === 0) return <NavDropdown.Item key={i} href={(!!menu.pgt_redirecionar === true ? '#' + this.trataRota(menu.pgt_redirecionar.pgt_rotaacesso) : (!!menu.itm_link ? menu.itm_link : '/'))} target={(!!menu.itm_target ? menu.itm_target : '_self')}>{menu.itm_nome}</NavDropdown.Item>;
          else return this.renderizaMenuDropDown(menu, "right");
        })}
      </NavDropdown>
    )
  }

  aumentarFonte = () => {
    let fonte = this.state.fonte;
    if (fonte < 3) fonte++;
    document.body.classList.add(`aumentar_${fonte}`);
    this.setState({fonte: fonte});
  }

  diminuirFonte = () => {
    let fonte = this.state.fonte;
    document.body.classList.remove(`aumentar_${fonte}`);
    if (fonte > 0) fonte--;
    this.setState({fonte: fonte});
  }

  altoContraste = () => {
    let contraste = !this.state.contraste;
    if (contraste) document.body.classList.add('contrast');
    else document.body.classList.remove('contrast');
    this.setState( { contraste: contraste });
  }

  render() {
    
    return (
      <Fragment>
        <div>
          <div className="acessibilidade text-right bgm-primary">
            <div className="container">
              <div className="floatLeft">
                {(!!this.state.midiasSociais.facebook) &&
                <a href={this.state.midiasSociais.facebook} target="_blank">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Facebook</Tooltip>}>
                    <Image src={("/assets/img/facebook.png")} />
                  </OverlayTrigger>
                </a>}
                {(!!this.state.midiasSociais.instagram) &&
                <a href={this.state.midiasSociais.instagram} target="_blank">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Instagram</Tooltip>}>
                    <Image src={("/assets/img/insta.png")} />
                  </OverlayTrigger>
                </a>}
                {(!!this.state.midiasSociais.twitter) &&
                <a href={this.state.midiasSociais.twitter} target="_blank">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>twitter</Tooltip>}>
                    <Image src={("/assets/img/twitter.png")} />
                  </OverlayTrigger>
                </a>}
              </div>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Alto Contraste</Tooltip>}>
                <Button size="sm" onClick={this.altoContraste}> A </Button>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Reduzir Fonte</Tooltip>}>
                <Button size="sm" onClick={this.diminuirFonte}> A- </Button>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Aumentar Fonte</Tooltip>}>
                <Button size="sm" onClick={this.aumentarFonte}> A+ </Button>
              </OverlayTrigger>
              <a href={"/login"} target="_blank">
                <Button className="lag" size="sm"> educarweb </Button>
              </a>
            </div>
          </div>
          <div className="bg-header">
            <div className="container">
              <Navbar collapseOnSelect variant="dark" expand="lg" sticky="top" className="p-r-l-0">
                <Navbar.Brand href="#home">
                  <Image src={(!!this.props.logo ? this.props.logo : "")} className="floatLeft" height="60" />
                  <div className="colName">
                    {this.props.titulo}<br />
                    <div style={{paddingTop: 6}}>
                    <b style={{fontSize: 30}}>PORTAL DA EDUCAÇÃO</b>
                    </div>
                  </div>
                </Navbar.Brand>
                {this.props.exibirCampoPesquisa &&
                  <FormDraft onSubmit={(values) => {
                    if (values.search) {
                      this.props.history.push("/search/1/" + encodeURIComponent(values.search))
                    }
                  }} render={({ handleSubmit }) => (
                    <Form inline className="forBusca" onSubmit={handleSubmit}>
                      <Field name={"search"}>{({ input }) => (
                        <React.Fragment>
                          <FontAwesomeIcon icon="search" />  
                          <FormControl type="text" {...input} placeholder="Pesquisar por..."/>
                        </React.Fragment>
                      )}</Field>
                    </Form>

                  )} />
                }
              </Navbar>
            </div>
          </div>
          <div className="bgm-secondary">
            <div className="container">
              <Navbar collapseOnSelect variant="dark" expand="lg" sticky="top" className="nv-menu">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    {this.props.menu.map((item, i) => {
                      if (!!item.itm_filhos === false || item.itm_filhos.length === 0) return <Nav.Link key={i} href={(!!item.pgt_redirecionar === true ? '#' + this.trataRota(item.pgt_redirecionar.pgt_rotaacesso) : (!!item.itm_link ? item.itm_link : '/'))} target={(!!item.itm_target ? item.itm_target : '_self')}>{item.itm_nome}</Nav.Link>;
                      else if (!!item.itm_filhos === true || item.itm_filhos.length > 0) return this.renderizaMenuDropDown(item, "");
                    })}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(EducarWebCmsHeader)