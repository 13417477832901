/* eslint-disable no-undef */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import React, { Component } from 'react';
import { Query } from "react-apollo";
import { Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { InputFiltro } from '../../components';
import { client } from '../apollo';
import { ASSUNTO_ALL, ASSUNTO_DELETE_ONE } from './AssuntoGql';
import ReenterRouteListener from "./components/ReenterRouteListener";
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
class Assuntos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Id", field: "asn_codigo", width: 70
        }, {
          headerName: "Título", field: "asn_titulo"
        }, {
          headerName: "Cor", field: "asn_cor",
          width: 30,
          cellRendererFramework: ({ value }) => <div style={{ background: value, height: 20, width: 20, borderRadius: 10 }} />
        },
        {
          headerName: "Relacionados", field: "psv_assuntosrelacionados",
          cellRendererFramework: ({ value }) => ((value || []).map(x => (<React.Fragment>

            <Badge style={{ backgroundColor: x.asn_cor, color: "#fff", padding: 5 }}>
              {x.asn_titulo}
            </Badge>&nbsp;&nbsp;
          </React.Fragment>
          )))

        }
      ],
      listaMenuLinhaSelecionada: null,
      filtro: null
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    params.api.showLoadingOverlay();

  }

  onSelectionChanged() {
    this.setState({ listaMenuLinhaSelecionada: this.gridApi.getSelectedRows() })
  }

  handleChange = (e) => {
    if (e.target.name === 'filtro') this.gridApi.setQuickFilter(e.target.value);
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    );
  }

  onDelete = () => {
    Swal.fire({
      title: "Confirma Exclusão?",
      type: "warning",
      text: "Você deseja realmente excluir o registro ?",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#c0c0c0',
      confirmButtonText: 'Sim, Excluir!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.value) {
        try {
          await client.mutate({
              refetchQueries: ["AssuntoList"],
              mutation: ASSUNTO_DELETE_ONE,
              variables: {
                id: this.state.listaMenuLinhaSelecionada[0].asn_codigo
              }
          })
          showInfo(defaultSuccessMessage("delete"));
        } catch (e) {
          showError({ message: defaultErrorMessage("delete", e) })
        }
      }
    });
  }

  render() {

    const onEdit = () => {
      this.props.history.push("/assuntos/manutencao-assunto/" + this.state.listaMenuLinhaSelecionada[0].asn_codigo)
    }
    return (
      <div className="colPageAdm">
        <div className="colRow">
          <div className="colRow p-relative">
            <h3>Listagem de Assuntos</h3>
            <div className="detalhe"></div>
          </div>
          <div className="colButtons">
            <Link to="/assuntos/manutencao-assunto">
              <Button size="sm" variant="success">
                <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Adicionar
              </Button>
            </Link>
            {!!this.state.listaMenuLinhaSelecionada && <span>
              <Button variant="primary" size="sm" onClick={onEdit}>
                <FontAwesomeIcon icon="edit" />&nbsp;&nbsp;Editar
              </Button>
              <Button variant="secondary" size="sm" onClick={this.onDelete}>
                <FontAwesomeIcon icon="trash" />&nbsp;&nbsp;Excluir
              </Button>
            </span>}
          </div>
          <div className="colRow">
            <InputFiltro size="sm" name="filtro" handleChange={this.handleChange} value={this.state.filtro} />
          </div>
        </div>
        <div className="ag-theme-balham">
          <Query
            query={ASSUNTO_ALL}
            variables={{ query: this.state.filtro }}
          >{({ data, loading, refetch, error }) => (
            <React.Fragment>
              <AgGridReact
                onGridReady={this.onGridReady}
                columnDefs={this.state.columnDefs}
                rowData={_.get(data, "psv_assuntonoticia.list", [])}
                rowSelection="single"
                loadingOverlayComponentFramework={() => <div className={"loading-div"}>Carregando...</div>}

                onSelectionChanged={this.onSelectionChanged.bind(this)}>
              </AgGridReact>
              <ReenterRouteListener callback={refetch} />
            </React.Fragment>
          )}</Query>
        </div>
      </div>
    )
  }
};

export default Assuntos