import React, { Fragment } from 'react';
import { Container, Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export default function EducarWebCmsPagina404(props) {  
    return (
      <Fragment>
        <Helmet>
            <title>{`Página não encontrada | ` + ((!!props.portalservicos && !!props.portalservicos.psv_headtitle) ? props.portalservicos.psv_headtitle : '')}</title>
        </Helmet>
        <Container className="colPageNot text-center">
          <Image src={("/assets/img/pagenotfound.png")} />
          <h1>PÁGINA NÃO ENCONTRADA</h1>
        </Container>
      </Fragment>
    )
}