import gql from 'graphql-tag';

export const PORTALSERVICOS_QUERY_BY_ID = gql`
query PortalServicosById($id: Int!) {
  psv_portalservicos{
    get(psv_codigo:$id){
        psv_codigo
        msv_menu {
          msv_codigo
          msv_nome
        }
        psv_imagemcabecalho
        psv_titulo
        psv_exibirpesquisa
        psv_diasvalidadenoticia
        psv_habilitarnewsletter
        psv_rodapeportal
        psv_banner
        psv_analytics
        psv_rotaacesso
        psv_headtitle
        psv_fbappid
        psv_fbimagem
        psv_linkssociais
        psv_configsmtp
    }
  }
}`

export const PORTALSERVICOS_SAVE = gql`
mutation PortalServicosSave($data: Psv_portalservicosInputType) {
  psv_portalservicos {
    save(data:[$data]) {
      psv_codigo
    }
  }
}`