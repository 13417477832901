import React, { Component, Fragment } from 'react';
import { ListGroup, Alert, Spinner, Row } from 'react-bootstrap';
import { GraphQLClient } from './';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getConfig } from '../pages/config';

class AssuntosRelacionados extends Component {

  constructor(props) {
    super(props);
    this.state = { exibirAlerta: true };
  }

  renderizaComponente = (codigoAssunto) => {
    const query = `
      {
        psv_assuntonoticia {
          list(asn_codigo: ${codigoAssunto}) {
            asn_titulo,
            asn_cor,
            psv_paginasrelacionadas{
              pgt_codigo,
              pgt_titulo,
              pgt_rotaacesso
            },
            psv_assuntosrelacionados {
              asn_codigo,
              asn_cor,
              asn_titulo,
              psv_paginasrelacionadas{
                pgt_codigo,
                pgt_titulo,
                pgt_rotaacesso
              }
            }
          }
        }
      }
    `;

    return (
      <GraphQLClient query={query}>
        {({ data, isLoading, error }) => {
          if (isLoading) {
            return <div className="text-center m-5"><Spinner animation="border" /></div>;
          }
          if (!data) {
            return <div className="text-center m-5"><Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Assunto não encontrado!</Alert></div>;
          }
          if (data) {
            return (
              <div className="col-12">
                {!!data.psv_assuntonoticia.list[0] &&
                  <ListGroup>
                    <ListGroup.Item variant="secondary">{data.psv_assuntonoticia.list[0].asn_titulo}</ListGroup.Item>
                    {data.psv_assuntonoticia.list[0].psv_assuntosrelacionados.map((item, i) => (
                      <ListGroup.Item key={i} action href={getConfig().rotabase + '#/' + (!!item.psv_paginasrelacionadas.length ? item.psv_paginasrelacionadas[0].pgt_rotaacesso : '')}>
                        {item.asn_titulo}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                }
                {!!data.psv_assuntonoticia.list[0] === false &&
                  <Alert variant="danger">
                    Assunto não encontrado ou excluído!
                  </Alert>
                }
              </div>
            )
          }
          if (error) {
            return <p>{error.message}</p>;
          }
        }}
      </GraphQLClient>
    )
  }

  render() {

    const conteudo = (typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo);

    if (conteudo.tipoFiltroAssunto === 'herdar') {
      if (!!this.props.pagina === true && this.props.pagina.asn_codigo === true) {
        return this.renderizaComponente(this.props.pagina.asn_codigo);
      }
      else if (!!this.props.assuntoPagina) {
        return this.renderizaComponente(this.props.assuntoPagina);
      }
      else {
        return (
          <Alert dismissible show={this.state.exibirAlerta} onClose={() => this.setState({ exibirAlerta: false })} variant="danger">
            <Alert.Heading>Ops! Nenhum assunto encontrado nesta página.</Alert.Heading>
            <p>
              Ocorreu um erro ao exibir o conteudo deste componente pois não existe nenhuma assunto relacionado a esta página.
            </p>
          </Alert>
        )
      }
    }
    else if (conteudo.tipoFiltroAssunto === 'assunto') {
      return this.renderizaComponente(conteudo.assuntoRelacionado.asn_codigo);
    }
  }
}

export default AssuntosRelacionados
