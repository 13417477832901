import React, { Component } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InputFiltro extends Component {
  render() {
    return (
        <InputGroup size={this.props.size || null}>
            <InputGroup.Prepend>
                <InputGroup.Text id="aria-icon"><FontAwesomeIcon icon="search" /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl name={this.props.name} onChange={this.props.handleChange} placeholder={this.props.placeholder || "Pesquisar por..."} aria-label={this.props.placeholder || "Pesquisar por..."} aria-describedby="aria-icon" />
        </InputGroup>
    )
  }
}

export default InputFiltro
