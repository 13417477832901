import React, { Component } from 'react'
import renderHTML from 'react-render-html';

import '../froala.css';

class EducarWebCmsFooter extends Component {

  render() {
    return (
      <footer className="fr-view colRow text-center">
        {!!this.props.conteudo === true && renderHTML(this.props.conteudo)}
      </footer>
    )
  }
}

export default EducarWebCmsFooter
