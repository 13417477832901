import React, { Component } from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import { RGBAToHexA } from '../funcoes';
import { getConfig } from '../pages/config';

class Banner extends Component {
  render() {

    let conteudoBanner = this.props.conteudo;
    if (typeof conteudoBanner === 'string') conteudoBanner = JSON.parse(conteudoBanner);

    let style = {};
    if (conteudoBanner.tipoFundo === 'cor' && !!conteudoBanner.fundo) {
      style.backgroundColor = RGBAToHexA(conteudoBanner.fundo.r,conteudoBanner.fundo.g,conteudoBanner.fundo.b,conteudoBanner.fundo.a);
    }
    else if (!!conteudoBanner.fundo) style.backgroundImage = 'url("'+ conteudoBanner.fundo +'")';

    if (!!conteudoBanner.corFonte) style.color = RGBAToHexA(conteudoBanner.corFonte.r,conteudoBanner.corFonte.g,conteudoBanner.corFonte.b,conteudoBanner.corFonte.a);

    return (
      <a href={!!conteudoBanner.paginaRedirecionar ? getConfig().rotabase + '#/' + conteudoBanner.paginaRedirecionar.pgt_rotaacesso : (!!conteudoBanner.link ? conteudoBanner.link : null)} target={!!conteudoBanner.target ? conteudoBanner.target.id : '_self'}>
        <Jumbotron className="m-b-0" fluid style={style}>
            <Container>
                {!!conteudoBanner.titulo &&<h1>{conteudoBanner.titulo}</h1>}
                {!!conteudoBanner.texto &&<p>
                    {conteudoBanner.texto}
                </p>}
            </Container>
        </Jumbotron>
      </a>
    )
  }
}

export default Banner
