import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import ReactPlayer from 'react-player';

import { CardVideo } from './';
import { graphqlClient } from '../services';
import { getConfig } from '../pages/config';

import './CardUltimosVideos.css';

export const Menu = (listaVideos) =>
  listaVideos.map((video, i) => {
      return <div className="cardVideo" key={i}><CardVideo ocultarLinkTodos video={video} widthCard /></div>;
  });

const Arrow = ({ icon }) => {
    if (icon === "<") return <div className="arrow-left"><FontAwesomeIcon icon="arrow-left" /></div>;
    if (icon === ">") return <div className="arrow-right"><FontAwesomeIcon icon="arrow-right" /></div>;
};
 
export const ArrowLeft = Arrow({ icon: "<" });
export const ArrowRight = Arrow({ icon: ">" });

class CardUltimosVideos extends Component {

    constructor(props){
        super(props);
        this.menu = null;
        this.state = { menuVideos: [] };
        let configNoticia = typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo;
        let filtro = "start: 0, limit: " + (!!configNoticia.qtdeNoticiasExibir ? configNoticia.qtdeNoticiasExibir : "20") + ", sortBy:\"vid_data\", sortDirection:\"desc\"" + (configNoticia.tipoFiltroAssunto === "assunto" ? `, asn_codigo:${configNoticia.assuntoRelacionado.asn_codigo}` : (!!this.props.assuntoPagina ? `, asn_codigo:${this.props.assuntoPagina}` : (!!this.props.assuntoPagina ? `, asn_codigo:${this.props.assuntoPagina}` : "")));
        let query=`
        {       
          psv_videos {
            list(${filtro}){
              vid_codigo
              vid_data
              vid_link
              vid_resumo
              vid_titulo
              asn_codigo{
                asn_codigo
                asn_cor
                asn_titulo
              }
            }
          }
        }
        `;
        graphqlClient.request(query).then((result) => {
          this.setState({menuVideos: result.psv_videos.list});
        });
    }  

  render() {
    let configNoticia = typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo;
    const menuCard = !!this.state.menuVideos ? Menu(this.state.menuVideos) : [];
    return (
      <div>
        <div className="colRow text-center p-relative">
            <h2>
              <FontAwesomeIcon icon="film"/> Últimos Vídeos <FontAwesomeIcon icon="film"/>
            </h2>
            <div className="detalhe"></div>
        </div>
        {configNoticia.tipoExibicaoNoticias === 'carrossel' &&
          <div className="colRow p-relative">
            <ScrollMenu
              ref={el => (this.menu = el)}
              data={menuCard}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              wheel={false}
              hideArrows={true}
              hideSingleArrow={true}
              translate={0}
              transition={0.5}
              alignCenter={false}
              scrollBy={1}
              clickWhenDrag={false}
              dragging={true} />
          </div>
        }
        {(configNoticia.tipoExibicaoNoticias === 'card' || !!configNoticia.tipoExibicaoNoticias === false) &&
          <div className="colRow p-relative">
            <Row>
              {this.state.menuVideos.map((video, i) => {
                let largura = parseInt(configNoticia.larguraCard) || 3;
                return (
                <Col xs={12} sm={6} md={(largura+1)} lg={largura} key={i}>
                  <div className="cardVideo"><CardVideo ocultarLinkTodos video={video} /></div>
                </Col> )
              })}
            </Row>
          </div>
        }
        {configNoticia.tipoExibicaoNoticias === 'lista' &&
          <div className="colRow p-relative">
            {this.state.menuVideos.map(x => (
              <div key={x.vid_codigo} className="coList p-15">
                <Row>
                  <Col xs={3} md={2} sm={3} className="wdMobile p-0 hide">
                    <Link to={"/video/" + _.get(x, "vid_codigo")}><ReactPlayer className="imgFeeds" url={x.vid_link} light={true} height="100%" width="100%" /></Link>
                  </Col>
                  <Col xs={9} md={10} sm={9} className="wdMobile pl-2 centralizar-vertical">
                    <Link to={"/video/" + _.get(x, "vid_codigo")}>
                      <p style={{fontSize: "14px", fontWeight: "bold", marginTop: "-2px"}}>{x.vid_titulo} - <Moment format="DD/MM/YYYY hh:mm:ss">{x.vid_data}</Moment></p>
                      <p style={{fontSize: "12px", marginTop: "-4px"}}>{x.vid_resumo}</p>
                    </Link>
                  </Col>
                </Row>
              </div>))}
          </div>
        }
        <div className="colRow p-t-b-30 text-center">
          <a href={getConfig().rotabase + "#/videos"}>
            <Button variant="outline-info">
              <FontAwesomeIcon icon="plus"/> MAIS VÍDEOS
            </Button>
          </a>
         </div>
      </div>
    )
  }
}

export default CardUltimosVideos
