
import gql from 'graphql-tag'
import { PAGINA_FRAGMENT } from './FragmentsGql'

export const NOTICIA_ALL = gql`
query NoticiaList($query:String){
  psv_noticias{
    list(filter:$query sortBy:"ntc_datapublicacao" ){
      ntc_codigo
      ntc_datapublicacao
      ntc_destaque
      ntc_titulo
      ntc_validade
    }
  }
}

`
export const NOTICIA_DELETE_ONE = gql`
mutation NoticiaDelete($id: ID) {
  psv_noticias {
    delete(id:[$id]) {
      success
    }
  }
}
`
export const NOTICIA_SAVE = gql`
mutation NoticiaSave($data: Psv_noticiasInputType) {
  psv_noticias {
    save(data:[$data]) {
      ntc_codigo
    }
    
  }
}
`

export const NOTICIA_QUERY_BY_ID = gql`


query NoticiaById($id: Int) {
  psv_noticias{
    list(ntc_codigo:$id){
      ntc_codigo
      ntc_datapublicacao
      ntc_destaque
      ntc_resumo
      ntc_titulo
      ntc_validade
      arq_capa{
        arq_codigo
        psv_url
      }
      psv_assuntosnoticia{
        asn_codigo
        asn_cor
        asn_titulo
      }
      pgt_codigo  {
        ... PaginaInfoComponente
      }
    }
  }
}

${PAGINA_FRAGMENT}

`