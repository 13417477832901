import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbaseAutocompleteMultipleField, AbaseTextField, FormDraft } from 'abase-components';
import 'filepond/dist/filepond.min.css';
import _ from 'lodash';
import { default as React } from 'react';
import { Query } from 'react-apollo';
import { Button, Col, Form } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Field } from 'react-final-form';
import reactCSS from 'reactcss';
import * as Yup from 'yup';
import { defaultDecorators, redirectBackForm } from '../../services';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
import { cast, validate } from '../../services/validate';
import { client } from '../apollo';
import { ASSUNTO_ALL, ASSUNTO_QUERY_BY_ID, ASSUNTO_SAVE } from './AssuntoGql';

const AssuntoSchema = Yup.object().shape({
  asn_codigo: Yup.number().nullable(),
  asn_titulo: Yup.string().required().label("Título"),
  asn_cor: Yup.string().required().label("Cor"),
  psv_assuntosrelacionados: Yup.array(Yup.object().shape({
    asn_codigo: Yup.number(),
  }).noUnknown()),

}).noUnknown()

function ManutencaoAssunto({ match: { params }, history, location }) {
  const [exibirColorPickerFonte, setExibirColorPickerFonte] = React.useState(false)

  const styles = reactCSS({
    'default': {
      color: {
        width: '80px',
        height: '24px',
        borderRadius: '2px',
      },
      textColor: {
        width: '80px',
        height: '24px',
        borderRadius: '2px',
      },
      swatch: {
        padding: '5px',
        cursor: 'pointer',
        background: '#fff',
        borderRadius: '1px',
        display: 'inline-block',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      },
      popover: {
        zIndex: '2',
        position: 'absolute',
      },
      cover: {
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        position: 'fixed',
      },      
    },
  });

  const handleSubmit = async (values) => {
    try {
      const mutationData = await cast(AssuntoSchema, values)

      await client.mutate({
        mutation: ASSUNTO_SAVE,
        variables: { data: mutationData }
      })
      showInfo(defaultSuccessMessage("save"))
      redirectBackForm(history, location)
    } catch (err) {
      showError({ message: defaultErrorMessage("save", err) })
    }
  }

  const notEditting = !params.assuntoId
  const id = params.assuntoId

  return (
    <Query
      query={ASSUNTO_QUERY_BY_ID}
      skip={notEditting}
      onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) })
        redirectBackForm(history, location)
      }}
      onCompleted={(data) => {
        if (!notEditting && !_.get(data, "psv_assuntonoticia.list[0]")) {
          showError({ message: defaultErrorMessage("not_found") })
          redirectBackForm(history, location)
        }
      }}
      variables={{ id }}>
      {({ data, loading }) => (
        <div className="colPageAdm">
          <FormDraft
            useDraft={notEditting}
            onSubmit={handleSubmit}
            subscription={{
              submitting: true
            }}
            validate={validate(AssuntoSchema)}
            initialValues={_.get(data, "psv_assuntonoticia.list[0]") || {
              asn_cor: "#ffffff"
            }}
            decorators={defaultDecorators}
            render={({ handleSubmit, form, submitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col xs={12}>
                    <h3>Manutenção do Assunto</h3>
                    <div className="detalhe-admin"></div>
                  </Col>
                  <Col sm={12} xs={12}>
                    <Field name={"asn_titulo"}>{({ input, meta }) => (
                      <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Título"} />
                    )}</Field>
                  </Col>
                  <Col item xs={12}>
                    <Field name="asn_cor">{({ input, meta }) => (
                      <div className="row">
                        <div className="col-sm-12 m-t-15">
                          <small><b>COR DO ASSUNTO</b></small>
                        </div>
                        <div className="col-sm-12">
                          {exibirColorPickerFonte ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={() => setExibirColorPickerFonte(false)} />
                            <SketchPicker disableAlpha color={input.value} {...input} onChange={value => input.onChange(value.hex)} />
                          </div> : null}
                          <div style={{ ...styles.swatch, backgroundColor: input.value }} onClick={() => setExibirColorPickerFonte(true)}>
                            <div style={styles.textColor} />
                          </div>
                        </div>
                      </div>
                    )}</Field>
                  </Col>
                  <Col sm={12} xs={12}>
                    <Query
                      query={ASSUNTO_ALL}
                    >{({ data, loading }) => (
                      <Field name="psv_assuntosrelacionados" >{({ input, meta }) => (
                        <React.Fragment>
                          <AbaseAutocompleteMultipleField isLoading={loading} label={"Assunto relacionados"}
                            data={
                              _.get(data, "psv_assuntonoticia.list", [])
                                .filter(x => x.asn_codigo !== +id)
                                .map((x) => {
                                  if (input.value) {
                                    const filtered = input.value.filter(y => x.asn_codigo === y.asn_codigo)
                                    return filtered.length === 0 ? x : filtered[0]
                                  }
                                  return x
                                })
                            }
                            value={input.value}
                            textField={'asn_titulo'}
                            onChange={value => {
                              input.onChange(value)
                            }}
                            getOptionValue={x => x}
                          />
                        </React.Fragment>
                      )}</Field>

                    )}</Query>
                  </Col>
                </Form.Row>
                <div className="text-right m-t-30">
                  <Button type={"button"} variant="secondary"size="sm" onClick={() => redirectBackForm(history, location)}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                  &nbsp;&nbsp;
                  <Button type={"submit"} variant="success" size="sm" disabled={submitting} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar</Button>
                </div>
              </Form>
            )}></FormDraft>
        </div>
      )}
    </Query>
  )
}

export default ManutencaoAssunto