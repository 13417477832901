import React, { Component } from 'react';
import { Card, FormCheck, Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

class CardPagina extends Component {

    state = {}
    constructor(props) {
        super(props);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    }

    handleChangeCheckBox(e) {
        const data = { ...this.props.pagina }
        data[e.target.name] = e.target.checked
        this.props.onChangeStatus(data.id, data.pgt_ishome, data.pgt_publicada)
    }

    excluir = () => {
        Swal.fire({
            title: "Confirma Exclusão?",
            type: "warning",
            text: "Você deseja realemnte excluir a página " + this.props.pagina.pgt_titulo + "?",
            footer: "",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#c0c0c0',
            confirmButtonText: 'Sim, Excluir!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.props.onDelete(this.props.pagina.id)

            }
        });
    }

    duplicar = () => {
        Swal.fire({
            title: "Duplicar Página?",
            type: "warning",
            text: "Você deseja criar uma nova página clonando a página " + this.props.pagina.pgt_titulo + "?",
            footer: "",
            showCancelButton: true,
            confirmButtonColor: '#c3c',
            cancelButtonColor: '#c0c0c0',
            confirmButtonText: 'Sim, Duplicar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.props.onDuplicar(this.props.pagina.id)

            }
        });
    }

    render() {

        return (
            <Card style={{ width: '100%' }}>
                <Card.Header>
                    <strong>{this.props.pagina.pgt_titulo}</strong>
                </Card.Header>
                <Card.Body >
                    <span>Rota: <strong>{this.props.pagina.pgt_rotaacesso}</strong></span>
                    <span><FormCheck checked={this.props.pagina.pgt_ishome} name="pgt_ishome" label="Homepage do Site?" onChange={this.handleChangeCheckBox} /></span>
                    <span><FormCheck checked={this.props.pagina.pgt_publicada} name="pgt_publicada" label="Publicada?" onChange={this.handleChangeCheckBox} /></span>
                    <div>
                    {!!this.props.pagina.asn_codigo && 
                    <a className="tagCards" style={{backgroundColor: this.props.pagina.asn_codigo.asn_cor}} href="">
                        {this.props.pagina.asn_codigo.asn_titulo}
                    </a>
                    }
                    </div>
                </Card.Body>
                <Card.Footer className="text-right">
                    <Dropdown as={ButtonGroup}>
                        <Link to={"/paginas/manutencao-pagina/" + this.props.pagina.id}>
                            <Button variant="secondary" size="sm">
                            <FontAwesomeIcon icon="edit" />&nbsp;&nbsp;Editar
                            </Button>
                        </Link>
                        <Dropdown.Toggle split variant="secondary" size="sm" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.duplicar}>
                                <FontAwesomeIcon icon="copy" />&nbsp;&nbsp;Duplicar Página
                            </Dropdown.Item>
                            <Dropdown.Item onClick={this.excluir}>
                                <FontAwesomeIcon icon="trash" />&nbsp;&nbsp;Excluir
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Footer>
            </Card>
        )
    }
}

export default CardPagina