import _ from 'lodash'
export const validate = (schema) => async (values, ) => {
    if (typeof schema === 'function') {
        schema = schema();
    }
    values = replaceNullsForValidation(values)
    try {
        await schema.validate(values, { abortEarly: false });
    } catch (e) {
        const result = e.inner.reduce((errors, error) => {
            errors[error.path] = error.message;
            return errors
        }, {});
        return result
    }
};

export const replaceNullsForValidation = (values) => {
    return _.omitBy(values, _.isNull)

}
export async function cast(schema, values) {
    return await schema.cast(replaceNullsForValidation(values))
};