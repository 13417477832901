import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Query } from 'react-apollo';
import { Button, Col, Container, Form, Alert } from 'react-bootstrap';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import { AbaseAutocompleteField, AbaseCheckbox, AbaseDateTimePickerField, AbaseTextField, FormDraft } from 'abase-components';

import EditorHtmlV2 from './components/EditorHtmlV2';
import { NEWSLETTER_SAVE, NEWSLETTER_QUERY_BY_ID } from './NewsletterGql';
import { defaultDecorators, redirectBackForm } from '../../services';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage';
import { cast, validate } from '../../services/validate';
import { client } from '../apollo';
import { getConfig } from './../config';

const NewsletterSchema = Yup.object()
    .shape({
        nws_codigo: Yup.number().nullable(),
        nws_assunto: Yup.string().required().label("Título da Newsletter"),
        nws_data: Yup.string().notRequired().nullable(),
        nws_enviar: Yup.bool().notRequired(),
        nws_mensagem: Yup.string(),
    }).noUnknown();

export default function ManutencaoNewsletter({ match: { params }, history, location }) {

    const [conteudoNewsletter, setConteudoNewsletter] = useState('');

    const handleSubmit = async values => {
        try {
          const mutationData = await cast(NewsletterSchema, values);
          if (mutationData.nws_enviar === true) mutationData.nws_envioconcluido = false;
          mutationData.nws_mensagem = conteudoNewsletter;
          await client.mutate({
            mutation: NEWSLETTER_SAVE,
            variables: {
              data: {
                ...mutationData,
                psv_codigo: { psv_codigo: getConfig().idPortal }
              }
            }
          });
          showInfo(defaultSuccessMessage("save"));
          redirectBackForm(history, location);
        } catch (err) {
          showError({ message: defaultErrorMessage("save", err) });
        }
    };
    
    const notEditting = !params.newsletterId;
    const id = params.newsletterId;

    return (
        <Query
      query={NEWSLETTER_QUERY_BY_ID}
      skip={notEditting}
      onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
      }}
      onCompleted={data => {
        if (!notEditting && !_.get(data, "psv_newsletter.list[0]")) {
          showError({ message: defaultErrorMessage("not_found") });
          redirectBackForm(history, location);
        }
        else {
            setConteudoNewsletter(data.psv_newsletter.list[0].nws_mensagem)
        }
      }}
      variables={{ id }}>
        {({ data, loading }) => (
            <div className="colPageAdm">
            <FormDraft
                useDraft={notEditting}
                onSubmit={handleSubmit}
                subscription={{
                    submitting: true
                }}
                validate={validate(NewsletterSchema)}
                initialValues={
                _.get(data, "psv_newsletter.list[0]") || {
                    nws_data: new Date().toISOString()
                }
                }
                decorators={defaultDecorators}
                render={({ handleSubmit, form, submitting, values }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Col xs={12}>
                                <h3>Manutenção de Newsletter</h3>
                                <div className="detalhe-admin"></div>
                            </Col>
                            <Col sm={8} xs={12}>
                                <Field name={"nws_assunto"}>
                                {({ input, meta }) => (
                                    <AbaseTextField
                                    meta={meta}
                                    fullWidth={true}
                                    {...input}
                                    label={"Título da Newsletter"}
                                    />
                                )}
                                </Field>
                            </Col>
                            <Col item sm={2} xs={12}>
                                <Field name="nws_data">
                                {({ input, meta }) => (
                                    <AbaseDateTimePickerField
                                    meta={meta}
                                    fullWidth={true}
                                    {...input}
                                    label={"Data de publicação"}
                                    />
                                )}
                                </Field>
                            </Col>
                            <Col item sm={2} xs={12}>
                                <Field name="nws_enviar" type="checkbox">
                                    {({ input, meta }) => (
                                    <AbaseCheckbox
                                        meta={meta}
                                        {...input}
                                        label={"Pronto p/ Envio?"}
                                    />
                                    )}
                                </Field>
                            </Col>
                            <Col item xs={12}>
                                <Field name={"nws_mensagem"}>{({ input }) => (
                                    <EditorHtmlV2 onChange={(e)=>{setConteudoNewsletter(e)}} value={conteudoNewsletter} />
                                )}</Field>
                            </Col>
                        </Form.Row>
                        <div className="text-right colRow m-t-15">
                            <Button type={"button"} variant="secondary" size="sm" onClick={() => redirectBackForm(history, location)}><FontAwesomeIcon icon="times" />&nbsp;&nbsp;Cancelar</Button>
                            &nbsp;&nbsp;
                            <Button type="submit" variant="success" size="sm" disabled={submitting} ><FontAwesomeIcon icon="check" />&nbsp;&nbsp;Salvar</Button>
                        </div>
                    </Form>
                )}></FormDraft>
            </div>
        )}
      </Query>
    );
}