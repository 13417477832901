
import gql from 'graphql-tag'
import { PAGINA_FRAGMENT } from './FragmentsGql'

export const PAGINA_ALL = gql`
 query PaginaAll($filter:String){
    psv_paginaportal{
        list (filter:$filter sortBy:"pgt_titulo" apenasSemNoticia:true){
            pgt_codigo
            pgt_titulo
            pgt_rotaacesso
            pgt_ishome
            pgt_publicada
            asn_codigo {
              asn_titulo
              asn_cor
            }
        }
    }
}
`
export const PAGINA_SAVE = gql`
mutation PaginaSave($data: Psv_paginaportalInputType) {
  psv_paginaportal {
    save(data:[$data]) {
      pgt_codigo
    }
    
  }
}
`

export const PAGINA_QUERY_BY_ID = gql`
query PaginaById($id: Int) {
psv_paginaportal {
  list(pgt_codigo:$id) {
    ...PaginaInfoComponente
   
  }
  
}
}
${PAGINA_FRAGMENT}
`
export const PAGINA_DELETE_ONE = gql`
mutation PaginaDelete($id: Int!) {
    psv_paginaportal {
        deletePagina(pgt_codigo:$id) {
            success
        }
  }
}
`

export const PAGINA_UPDATE_STATUS = gql`
mutation PaginaUpdateStatus($id:Int! $home:Boolean! $publica:Boolean!){
  psv_paginaportal{
    updateStatusPagina(
      pgt_codigo:$id
      pgt_ishome:$home
      pgt_publicada:$publica
    ){
      success
    }
  }
}
`


export const PAGINA_DUPLICAR = gql`
mutation PaginaDuplicar($id: Int!) {
    psv_paginaportal {
        duplicar(pgt_codigo:$id) {
            pgt_codigo
        }
  }
}
`