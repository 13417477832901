import { Component } from "react";
import { withRouter } from "react-router";

class ReenterRouteListener extends Component {
    componentDidMount() {
        this.listener = this.props.history.listen((newLocation) => {
            if (newLocation.pathname === this.props.match.url) {
                setTimeout(() => this.props.callback(), 100);
            }
        })
    }
    componentWillUnmount() {
        if (this.listener) {
            this.listener()
        }
    }
    render() {
        return null
    }
}

export default withRouter(ReenterRouteListener);
