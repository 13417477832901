import React, { Component } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import Paginacao from '../components/Paginacao'
import { Col } from 'react-bootstrap';
import GraphQLClient from './../components/GraphQLClient';
import _ from 'lodash'
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getConfig } from '../pages/config';

export function ListarTodasNoticias(props) {
  const query = `
    
query SearchList($start:Int $limit:Int $search:String!){
  psv_paginaportal{
    search(text:$search start:$start limit:$limit ){
      data{
          pgt_codigo
          pgt_titulo
          pgt_rotaacesso
          psv_noticias{
            ntc_codigo
            ntc_titulo
            ntc_resumo
          }
      }
      total
      
    }
  }
}
    `
  const page = props.match.params.page ? +props.match.params.page : 1
  const search = props.match.params.search

  const setPage = page => {
    props.history.push(`/search/${page}/${encodeURI(search)}`)
  }
  const limit = 20
  return (
    <Container className="p-t-b-30" key={page}>
      <Helmet>
        <title>{`Resultados da busca por: ${search} | ` + ((!!props.portalservicos && !!props.portalservicos.psv_headtitle) ? props.portalservicos.psv_headtitle : '')}</title>
      </Helmet>
      <div>
        <div className="colRow p-relative"> 
          <h3>Resultado da Busca por <b>"{search}"</b></h3>
          <div class="detalhe"></div>
        </div>

        <div className="colRow m-t-15 p-relative"> 
          <GraphQLClient query={query} variables={{
            start: (page - 1) * limit,
            limit: limit,
            search
          }}>
            {({ data, isLoading, error }) => {

              if (isLoading) {
                return <div className="text-center m-5"><Spinner animation="border" /></div>;
              }
              if (_.get(data, "psv_paginaportal.search.data", []).length === 0) {
                return <div className="text-center m-5"><Alert variant="warning">Ooops! Nada encontrado para esta busca.</Alert></div>;
              }
              return (<React.Fragment>
                {_.get(data, "psv_paginaportal.search.data", []).map(x => (
                  <div key={x.pgt_codigo} className="coList">
                    <Row>
                      <Col xs={12}>
                        {x.psv_noticias.length > 0 ? (
                          <React.Fragment>
                            <Link to={"/noticia/" + _.get(x, "psv_noticias[0].ntc_codigo") + "/" + _.get(x, "pgt_rotaacesso")}><h5>{_.get(x, "psv_noticias[0].ntc_titulo")}</h5></Link>
                            <small className="colRow m-b-15">{_.get(x, "psv_noticias[0].ntc_resumo")}</small>
                          </React.Fragment>
                        ) : (
                            <Link to={"/" + _.get(x, "pgt_rotaacesso")}><h5>{x.pgt_titulo}</h5></Link>
                          )}

                      </Col>
                    </Row>
                  </div>))}
                <div className="colRow m-t-15">
                  <Paginacao totalPages={_.get(data, "psv_paginaportal.search.total", 0) > limit ? Math.ceil(_.get(data, "psv_paginaportal.search.total", 0) / limit) : 1} currentPage={page} onChange={page => setPage(page)}></Paginacao>
                </div>
              </React.Fragment>)

            }}</GraphQLClient>

          </div>

      </div>
    </Container>
  )
}


export default ListarTodasNoticias
