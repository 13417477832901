import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { Query } from 'react-apollo';
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InputFiltro } from '../../components';
import { client } from '../apollo';
import { CardPagina } from './components';
import { PAGINA_ALL, PAGINA_DELETE_ONE, PAGINA_UPDATE_STATUS, PAGINA_DUPLICAR } from './PaginaGql';
import { defaultErrorMessage, defaultSuccessMessage, showError, showInfo } from '../../services/errorMessage'

export default function Paginas(props) {

    const [filtro, setFiltro] = useState("")
    const onDelete = async (id) => {
        try {
            await client.mutate({
                refetchQueries: ["PaginaAll"],
                mutation: PAGINA_DELETE_ONE,
                variables: {
                    id: id
                }
            })
            showInfo(defaultSuccessMessage("delete"))

        } catch (e) {
            showError({ message: defaultErrorMessage("delete", e) })
        }
    }

    const onDuplicar = async (id) => {
        try {
            await client.mutate({
                refetchQueries: ["PaginaAll"],
                mutation: PAGINA_DUPLICAR,
                variables: {
                    id: id
                }
            })
            showInfo(defaultSuccessMessage("save"))

        } catch (e) {
            showError({ message: defaultErrorMessage("save", e) })

        }
    }

    const onChangeStatus = async (id, home, publica) => {
        try {
            await client.mutate({
                refetchQueries: ["PaginaAll"],
                mutation: PAGINA_UPDATE_STATUS,
                variables: {
                    id, home, publica
                }
            })

        } catch (e) {
            showError({ message: defaultErrorMessage("update", e) })

        }
    }

    return (
        <Fragment>
            <div className="colPageAdm">
                <div className="colRow">
                    <div className="colRow p-relative">
                        <h3>Páginas do Portal</h3>
                        <div className="detalhe"></div>
                    </div>
                    <div className="colButtons">
                        <Link to="/paginas/manutencao-pagina">
                            <Button size="sm" variant="success">
                                <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Adicionar
                            </Button>
                        </Link>
                    </div>
                    <div className="colRow">
                        <InputFiltro size="sm" name="filtro" handleChange={e => setFiltro(e.target.value)} />
                    </div>
                </div>
                <div className="colRow m-t-15">
                    <Row>
                        <Query query={PAGINA_ALL}
                            variables={{
                                filter: filtro
                            }}
                        >
                            {({ data, isLoading, error }) => {
                                if (isLoading) {
                                    return <Col xs={12}><div className="text-center m-5"><Spinner animation="border" /></div></Col>;
                                }
                                else if (error) {
                                    return <Alert variant="danger">{error.message}</Alert>;
                                }
                                else if (!data) {
                                    return <p>Nenhum registro encontrado ...</p>;
                                }
                                else if (data) {
                                    return (
                                        _.get(data, "psv_paginaportal.list", []).map((item, i) => {
                                            return (<Col className="mb-4" xs={3} key={item.pgt_codigo}><CardPagina
                                                onDelete={onDelete}
                                                onDuplicar={onDuplicar}
                                                onChangeStatus={onChangeStatus}
                                                pagina={{ id: item.pgt_codigo, pgt_titulo: item.pgt_titulo, pgt_rotaacesso: item.pgt_rotaacesso, pgt_ishome: item.pgt_ishome, pgt_publicada: item.pgt_publicada, asn_codigo: item.asn_codigo }} /></Col>)

                                        })
                                    );
                                }
                            }}
                        </Query>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}