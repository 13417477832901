import gql from "graphql-tag";
export const ASSUNTO_ALL = gql`
  query AssuntoList($query: String) {
    psv_assuntonoticia {
      list(filter: $query, sortBy: "asn_titulo") {
        asn_codigo
        asn_cor
        asn_titulo
        psv_assuntosrelacionados {
          asn_codigo
          asn_cor
          asn_titulo
        }
      }
    }
  }
`;

export const ASSUNTO_DELETE_ONE = gql`
  mutation AssuntoDelete($id: Int!) {
    psv_assuntonoticia {
      deleteAssunto(asn_codigo:$id) {
        success
      }
    }
  }
`;
export const ASSUNTO_SAVE = gql`
  mutation AssuntoSave($data: Psv_assuntonoticiaInputType) {
    psv_assuntonoticia {
      save(data: [$data]) {
        asn_codigo
      }
    }
  }
`;

export const ASSUNTO_QUERY_BY_ID = gql`
  query AssuntoById($id: Int) {
    psv_assuntonoticia {
      list(asn_codigo: $id) {
        asn_codigo
        asn_cor
        asn_titulo
        psv_assuntosrelacionados {
          asn_codigo
          asn_cor
          asn_titulo
        }
      }
    }
  }
`;
