import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { getConfig } from '../pages/config';

export default function ArquivosAnexos(props){

    let listaArquivos = props.arquivos;
    return (
        <Col xs={12} className="text-center mt-5">
            <div className="colRow p-relative">
                <h2><FontAwesomeIcon icon="paperclip"/> Anexos <FontAwesomeIcon icon="paperclip"/></h2>
                <div className="detalhe"></div>
            </div>
            <div className="colRow">
            <ul className="list-anexo">
                {_.orderBy(listaArquivos,"arq_codigo.arq_codigo", "desc").map((arquivo, i) => (
                <li key={i}>
                    <a href={getConfig().urlDownloadImagem + arquivo.arq_codigo.arq_fullname} target="_blank">
                    {(() => {
                        if (arquivo.arq_codigo.arq_mimetype.includes('pdf')) return <FontAwesomeIcon icon="file-pdf"/>;
                        else if (arquivo.arq_codigo.arq_mimetype.includes('image')) return <FontAwesomeIcon icon="file-image"/>;
                        else return <FontAwesomeIcon icon="file"/>;
                    })()}
                    <p className="t-overflow">{arquivo.arq_codigo.arq_nome}</p>
                    </a>
                </li>
                ))}
            </ul>
            </div>
        </Col>
    );
}