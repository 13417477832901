import React, { useEffect, useState, createRef } from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import { getConfig } from '../../config';

export default function EditorHtmlV2({ onChange, value, id }) {

    return(
        <FroalaEditor
                          model={value}
                          config={{
                            attribution: false,
                            language: 'pt_br',
                            fileUploadURL: getConfig().urlUploadImagem+'V2',
                            fileUploadParams: {
                                'imageSize': '1024',
                                'psv_codigo': getConfig().idPortal
                            },
                            imageUploadURL: getConfig().urlUploadImagem+'V2',
                            imageUploadParams: {
                                'imageSize': '1024',
                                'psv_codigo': getConfig().idPortal
                            }
                          }}
                          
                          onModelChange={(e) => onChange(e)}
                        />
    );
}