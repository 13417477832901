import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { getConfig } from '../pages/config';

class SlideBanner extends Component {
  render() {
    const conteudoBanner = (typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo);
    let altura = 400;
    
    if (!!conteudoBanner[0].altura) {
      altura = conteudoBanner[0].altura;
    }
    else if (!!this.props.altura) {
      altura = this.props.altura;
    }
    
    return (
      <div className="colSlide" style={{height: parseInt(altura)}}>
        <Carousel>
          {conteudoBanner.map((item, i) => (
              <Carousel.Item key={i}>
              <a href={!!item.paginaRedirecionar ? getConfig().rotabase + '#/' + item.paginaRedirecionar.pgt_rotaacesso : (!!item.link ? item.link : null)} target={!!item.target ? item.target.id : '_self'}><div className="colImageBanner" style={{backgroundImage:`url(${item.fundo})`}}></div></a>
                  {(!!item.titulo || !!item.texto) &&
                    <Carousel.Caption>
                      <div className="colRow">
                        <h3>{item.titulo}</h3>
                      </div>
                      <div className="colRow">
                        <p>{item.texto}</p>
                      </div>
                    </Carousel.Caption>
                  }
              </Carousel.Item>
          ))}
        </Carousel>
      </div>
    )
  }
}

export default SlideBanner