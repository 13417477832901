import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import { getConfig } from '../pages/config';

class CabecalhoNoticia extends Component {
  render() {
    return (
        <div className="colRow">
            <div className="colRow p-relative">
                <h3>
                    {this.props.noticia.ntc_titulo}
                </h3>
                <div className="detalhe"></div>
            </div>
            <small className="text-muted">Notícia cadastrada em: <b><Moment format="DD/MM/YYYY hh:mm:ss">{this.props.noticia.ntc_datapublicacao}</Moment></b></small>
            <div className="colRow m-t-15 p-relative">
                {this.props.noticia.psv_assuntosnoticia.map((assunto, i) => (
                    <Fragment key={i}>
                        <a className="btn b-white btn-sm m-b-15" style={{backgroundColor: assunto.asn_cor}} href={getConfig().rotabase + "#/noticias/1/" + assunto.asn_codigo}>{assunto.asn_titulo}</a>&nbsp;
                    </Fragment>
                ))}
            </div>
        </div>
    )
  }
}

export default CabecalhoNoticia
