export function getConfig() {
    let config = {
        rootUrl: ""
    }
    config.apiUrl = config.rootUrl + "/GraphQL/Post";
    config.urlUploadImagem = config.rootUrl + '/Psv_portalservicos/UploadArquivo';
    config.urlDownloadImagem = config.rootUrl + '/Psv_portalservicos/DownloadArquivo?id=';
    config.rotabase = (!!window.rotabase ? window.rotabase : '/');
    config.idPortal = !!window.idPortal ? window.idPortal : 1;

    config.uploadImageCallBack = (file) => {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', config.urlUploadImagem);
                xhr.setRequestHeader('imageSize', '1024');
                xhr.setRequestHeader('psv_codigo', config.idPortal);
                const data = new FormData();
                data.append('image', file);
                xhr.send(data);
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText);
                    reject(error);
                });
            }
        );
    }

    config.getUuid = () => {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    }

    return config
}