import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TipoComponente } from '../../../enums';
import renderHTML from 'react-render-html';
import Swal from "sweetalert2";
import { menuComponentes } from '../tiposComponentes';
import { ArquivosAnexos, AcessoRapido, AssuntosRelacionados, Banner, CardUltimasNoticias, CardUltimosAlbuns, CardUltimosVideos, Menu, Midia, Imagem, SlideBanner, UltimaNoticiaAssunto, IFrame, Titulo, Album, FormularioNewsletter, VisualizadorPdf } from '../../../components';

import '../../../froala.css'

class CardComponentePagina extends Component {

    excluirConteudo = (linha, coluna, ordemConteudo) => {
        Swal.fire({
            title: "Remover Conteúdo?",
            type: "warning",
            text: `Você deseja realemnte remover o componente ${this.props.cpt_tipocomponente} desta página da linha ${(parseInt(linha) + 1)}, coluna ${(parseInt(coluna) + 1)}, componente ${(parseInt(ordemConteudo) + 1)}?`,
            footer: "*Esta operação é irreversível",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#c0c0c0',
            confirmButtonText: 'Sim, Excluir!',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.value) this.props.excluirConteudoLinhaColuna(linha, coluna, ordemConteudo);
        });
    }

    editarConteudo = (linha, coluna, ordemConteudo) => {
        this.props.editarComponenteLinhaColuna(linha, coluna, ordemConteudo);
    }

    render() {
        return (
            <div className="m-t-10">
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <small className="floatLeft m-t-5">
                                    Componente: <b className="text-uppercase">{menuComponentes.filter(m => m.tipo === this.props.cpt_tipocomponente)[0].label}</b>
                                </small>
                            </Col>
                            <Col xs="auto">
                                <Button variant="primary" size="sm" onClick={() => this.editarConteudo(this.props.linha, this.props.coluna, this.props.ordem)}>
                                    <FontAwesomeIcon icon="edit" />
                                </Button>
                                &nbsp;
                                <Button variant="secondary" size="sm" onClick={() => this.excluirConteudo(this.props.linha, this.props.coluna, this.props.ordem)}>
                                    <FontAwesomeIcon icon="trash" />
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <div className="disable"></div>
                            {/*this.props.cpt_tipocomponente === TipoComponente.html && renderHTML(this.props.pgc_conteudo)*/}
                            {(this.props.cpt_tipocomponente === TipoComponente.htmlV2 || this.props.cpt_tipocomponente === TipoComponente.html) && <div className="fr-view">{renderHTML(this.props.pgc_conteudo)}</div>}
                            {this.props.cpt_tipocomponente === TipoComponente.IFrame && <IFrame conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.titulo && <Titulo conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.menu && <Menu conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.acessoRapido && <AcessoRapido conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.assuntosRelacionados && <AssuntosRelacionados conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.banner && <Banner conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.slideBanner && <SlideBanner conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.ultimaNoticiaAssunto && <UltimaNoticiaAssunto conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.midia && <Midia url={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.imagem && <Imagem url={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.album && <Album conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.cardUltimasNoticias && <CardUltimasNoticias conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.cardUltimosVideos && <CardUltimosVideos conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.cardUltimosAlbuns && <CardUltimosAlbuns conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.arquivosAnexos && <ArquivosAnexos arquivos={[]} />}
                            {this.props.cpt_tipocomponente === TipoComponente.newsletter && <FormularioNewsletter conteudo={this.props.pgc_conteudo} />}
                            {this.props.cpt_tipocomponente === TipoComponente.pdf && <VisualizadorPdf conteudo={this.props.pgc_conteudo} />}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default CardComponentePagina
