import './YupTraducao'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import DescadastrarNewsletter from './pages/DescadastrarNewsletter'
import AdminPortal from './pages/admin/AdminPortal';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
const Graphiql = React.lazy(() => import('./pages/admin/Graphiql'))

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
        <React.Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter basename={window.rotabase}>
                <Switch>
                    <Route path="/" exact={true} component={App} />
                </Switch>
            </BrowserRouter>
        </React.Suspense>
        , document.getElementById('root'));
}
else {
ReactDOM.render(
    <React.Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter basename={window.rotabase}>
            <Switch>
                <Route path="/" exact={true} component={App} />
                <Route path="/admin" exact={true} component={AdminPortal} />
                <Route path="/graphql" exact={true} component={Graphiql} />
                <Route path="/descadastrar" exact={true} component={DescadastrarNewsletter} />

            </Switch>
        </BrowserRouter>
    </React.Suspense>
    , document.getElementById('root'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
