
import gql from 'graphql-tag'

export const MENU_ALL = gql`
query MenuList($query:String){
  psv_menuportal{
    list(filter:$query sortBy:"msv_nome" ){
      msv_codigo
      msv_nome
      msv_tipo
    }
  }
}

`
export const MENU_DELETE_ONE = gql`
mutation MenuDelete($id: ID) {
  psv_menuportal {
    delete(id:[$id]) {
      success
    }
  }
}
`
export const MENU_SAVE = gql`
mutation MenuSave($data: Psv_menuportalInputType) {
  psv_menuportal {
    save(data:[$data]) {
      msv_codigo
    }
    
  }
}
`

export const MENU_QUERY_BY_ID = gql`


query MenuById($id: Int, $itm_ativo: Boolean) {
  psv_menuportal{
    list(msv_codigo:$id, itm_ativo:$itm_ativo){
      msv_codigo
      msv_itens
      msv_nome
      msv_tipo
    }
  }
}


`