import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Spinner,Alert } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Paginacao from '../components/Paginacao'
import GraphQLClient from './../components/GraphQLClient';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getConfig } from './config';
import Moment from 'react-moment';

export function ListarTodosAlbuns(props) {
  const query = `
    query AlbumList($start:Int $limit:Int){
      psv_albuns{
        pagedList(sortBy:"alb_data" sortDirection:"desc" start:$start limit:$limit){
          data{
            alb_codigo
            alb_data
            alb_nome
            arq_capa {
              arq_descricao
              arq_mimetype
              arq_fullname
              psv_url
            }
          }
          total
        }
      }
    }
    `;
    const page = props.match.params.page ? +props.match.params.page : 1;
    const setPage = page => {
      props.history.push(`/albuns/${page}`)
    };
    const limit = 20;
    return (
      <Container className="p-t-b-30" key={page}>
        <Helmet>
          <title>{'Álbuns | ' + ((!!props.portalservicos && !!props.portalservicos.psv_headtitle) ? props.portalservicos.psv_headtitle : '')}</title>
        </Helmet>
        <div className="colRow p-relative">
            <h3>Albuns</h3>
            <div className="detalhe"></div>
        </div>
        <div className="colRow m-t-15">
          <GraphQLClient query={query} variables={{
            start: (page - 1) * limit,
            limit: limit
          }}>
            {({ data, isLoading, error }) => {
  
              if (isLoading) {
                return <div className="text-center m-5"><Spinner animation="border" /></div>;
              }
              if (_.get(data, "psv_albuns.pagedList.data", []).length === 0) {
                return <Alert variant="warning" className="text-center"><FontAwesomeIcon icon="exclamation-triangle"/> &nbsp;&nbsp;Nenhum registro encontrado</Alert>;
              }
              return (<div>
                {_.get(data, "psv_albuns.pagedList.data", []).map(x => (
                  <div key={x.alb_codigo} className="coList p-15">
                    <Row>
                      <Col xs={3} md={3} sm={5} className="wdMobile">
                        <Link to={"/album/" + _.get(x, "alb_codigo")}><img src={getConfig().urlDownloadImagem + _.get(x, "arq_capa.arq_fullname")} className={"iw100"} /></Link>
                      </Col>
                      <Col xs={9} md={9} sm={7} className="wdMobile mt15">
                        <Link to={"/album/" + _.get(x, "alb_codigo")}>
                          <h4>{x.alb_nome}</h4>
                          <p><Moment format="DD/MM/YYYY hh:mm:ss">{x.alb_data}</Moment></p>
                        </Link>
                      </Col>
                    </Row>
                  </div>))}
                  <div className="colRow m-t-15">
                    <Paginacao totalPages={_.get(data, "psv_albuns.pagedList.total", 0) > limit ? Math.ceil(_.get(data, "psv_albuns.pagedList.total", 0) / limit) : 1} currentPage={page} onChange={page => setPage(page)}></Paginacao>
                  </div>   
              </div>)
            }}</GraphQLClient>
        </div>
      </Container>
    )
}

export default ListarTodosAlbuns;