import React, { Component, Fragment } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import { CardNoticia, GraphQLClient } from './';
import { graphqlClient } from '../services';
import { getConfig } from '../pages/config';

import './CardUltimasNoticias.css';

export const Menu = (listaNoticias) =>
  listaNoticias.map((noticia, i) => {
      return <div className="cardNoticia" key={i}><CardNoticia ocultarLinkTodas noticia={noticia} widthCard /></div>;
  });

const Arrow = ({ icon }) => {
    if (icon === "<") return <div className="arrow-left"><FontAwesomeIcon icon="arrow-left" /></div>;
    if (icon === ">") return <div className="arrow-right"><FontAwesomeIcon icon="arrow-right" /></div>;
};
 
export const ArrowLeft = Arrow({ icon: "<" });
export const ArrowRight = Arrow({ icon: ">" });
  
class CardUltimasNoticias extends Component {

  constructor(props){
    super(props);
    this.menu = null;
    this.state = { menuNoticias: [] };
    let configNoticia = typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo;
    let filtro = "start: 0, limit: " + (!!configNoticia.qtdeNoticiasExibir ? configNoticia.qtdeNoticiasExibir : "20") + ", sortBy:\"ntc_datapublicacao\", sortDirection:\"desc\"";
    if (!!configNoticia.tipoFiltroAssunto){
      filtro += (configNoticia.tipoFiltroAssunto === "assunto" ? `, asn_codigo:${configNoticia.assuntoRelacionado.asn_codigo}` : (!!this.props.assuntoPagina ? `, asn_codigo:${this.props.assuntoPagina}` : (!!this.props.assuntoPagina ? `, asn_codigo:${this.props.assuntoPagina}` : "")))
    }
    let query=`
        {
          psv_noticias {
            list(${filtro}){
              ntc_codigo,
              ntc_datapublicacao,
              ntc_resumo,
              ntc_titulo,
              ntc_validade,
              arq_capa{
                arq_fullname
              },
              pgt_codigo {
                pgt_rotaacesso
              },
              psv_assuntosnoticia{
                asn_codigo,
                asn_cor,
                asn_titulo
              }
            }
          }
        }
      `;
    graphqlClient.request(query).then((result) => {
      this.setState({menuNoticias: result.psv_noticias.list});
    });
  }

  render() {
    let configNoticia = typeof this.props.conteudo === 'string' ? JSON.parse(this.props.conteudo) : this.props.conteudo;
    const menuCard = !!this.state.menuNoticias ? Menu(this.state.menuNoticias) : [];  
    return (
      <div className="m-t-15">
          
            <div className="colRow text-center p-relative">
                <h2>
                  <FontAwesomeIcon icon="newspaper"/> Últimas Notícias <FontAwesomeIcon icon="newspaper"/>
                </h2>
                <div className="detalhe"></div>
            </div>
            {configNoticia.tipoExibicaoNoticias === 'carrossel' &&
            <div className="colRow p-relative">
              <ScrollMenu
              ref={el => (this.menu = el)}
              data={menuCard}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              wheel={false}
              hideArrows={true}
              hideSingleArrow={true}
              translate={0}
              transition={0.5}
              alignCenter={false}
              hideArrows={true}
              scrollBy={1}
              hideSingleArrow={true}
              clickWhenDrag={false}
              dragging={true} />
              </div>
            }
            {(configNoticia.tipoExibicaoNoticias === 'card' || !!configNoticia.tipoExibicaoNoticias === false) &&
            <div className="colRow p-relative">
              <Row>
                {this.state.menuNoticias.map((noticia, i) => {
                  let largura = parseInt(configNoticia.larguraCard) || 3;
                  return (
                  <Col xs={12} sm={6} md={(largura+1)} lg={largura} key={i}>
                    <div className="cardNoticia"><CardNoticia ocultarLinkTodas noticia={noticia} /></div>
                  </Col> )
                })}
              </Row>
            </div>}
            {configNoticia.tipoExibicaoNoticias === 'lista' &&
              <div className="colRow p-relative">
                {this.state.menuNoticias.map(x => (
                  <div key={x.ntc_codigo} className="coList p-15">
                    <Row>
                      <Col xs={3} md={2} sm={3} className="wdMobile p-0 hide">
                        <Link to={"/noticia/" + _.get(x, "ntc_codigo") + "/" + _.get(x, "pgt_codigo.pgt_rotaacesso")}><img src={getConfig().urlDownloadImagem + _.get(x, "arq_capa.arq_fullname")} style={{width: "100%", marginTop: 15, paddingLeft: 5}} /></Link>
                      </Col>
                      <Col xs={9} md={10} sm={9} className="wdMobile pl-2 centralizar-vertical">
                        <Link to={"/noticia/" + _.get(x, "ntc_codigo") + "/" + _.get(x, "pgt_codigo.pgt_rotaacesso")}>
                          <p style={{fontSize: "14px", fontWeight: "bold", marginTop: "-2px"}}>{x.ntc_titulo} - <Moment format="DD/MM/YYYY hh:mm:ss">{x.ntc_datapublicacao}</Moment></p>
                          <p style={{fontSize: "12px", marginTop: "-4px"}}>{x.ntc_resumo}</p>
                          <div className="colRow" style={{minHeight: '15px'}}>
                            {x.psv_assuntosnoticia.map((assunto, i) => (
                              <div className="tagCards" key={i} style={{backgroundColor: assunto.asn_cor, marginTop: "-4px"}}>
                                {assunto.asn_titulo}
                              </div>
                            ))}
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </div>))}
              </div>
            }
            <div className="colRow p-t-b-30 text-center">
              <a href={getConfig().rotabase + "#/noticias"}>
                <Button variant="outline-info">
                  <FontAwesomeIcon icon="plus"/> MAIS NOTÍCIAS
                </Button>
              </a>
            </div>
      </div>
    )
  }
}

export default CardUltimasNoticias
