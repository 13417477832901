export const TipoComponente = {
    html: 'html',
    htmlV2: 'htmlV2',
    menu: 'menu',
    IFrame: 'IFrame',
    ultimaNoticiaAssunto: 'ultimaNoticiaAssunto',
    cardNoticia: 'cardNoticia',
    cardAlbum: 'cardAlbum',
    cardVideo: 'cardVideo',
    cardUltimasNoticias: 'cardUltimasNoticias',
    cardUltimosVideos: 'cardUltimosVideos',
    cardUltimosAlbuns: 'cardUltimosAlbuns',
    banner: 'banner',
    midia: 'midia',
    imagem: 'imagem',
    album: 'album',
    slideBanner: 'slideBanner',
    assuntosRelacionados: 'assuntosRelacionados',
    acessoRapido: 'acessoRapido',
    arquivosAnexos: 'arquivosAnexos',
    newsletter: 'newsletter',
    titulo: 'titulo',
    pdf: 'pdf'
}