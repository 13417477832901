import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { Query } from "react-apollo";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { NEWSLETTER_ALL, NEWSLETTER_DELETE_ONE } from './NewsletterGql';
import { INSCRITOSNEWSLETTER_ALL } from './InscritosNewsletterGql';
import { client } from '../apollo';
import ReenterRouteListener from "./components/ReenterRouteListener";
import { InputFiltro } from '../../components';
import { getConfig } from '../config';

class Newsletter extends Component {

    constructor(props) {
        super(props);
        this.state = {
          newsletterColumnDefs: [{
            headerName: "Id", field: "nws_codigo", width: 25
          }, {
            headerName: "Assunto", field: "nws_assunto"
          }, {
            headerName: "Cadastrada em", field: "nws_data", width: 50,
            cellRendererFramework: ({ value }) => (
                <span>{moment(new Date(value)).format("DD/MM/YYYY")}</span>
              )
          }, {
            headerName: "Enviar", field: "nws_enviar",width: 25,
            cellRendererFramework: ({ value }) => (
                <span><FontAwesomeIcon icon={(!!value ? "check": "times")} /></span>
              )
          },{
            headerName: "Data Envio", field: "nws_dataenvio", width: 30,
            cellRendererFramework: ({ value }) => (
              <span>{(!!value ? moment(new Date(value)).format("DD/MM/YYYY") : 'Envio Pendente')}</span>
            )
          }],
          inscritosNewsletterColumnDefs: [
            {
                headerName: "Id", field: "inw_codigo", width: 25
            },
            {
                headerName: "Nome", field: "inw_nome"
            },
            {
                headerName: "Email", field: "inw_email", width: 140
            },
            {
                headerName: "Ativo", field: "inw_ativo",width: 25,
                cellRendererFramework: ({ value }) => (
                    <span><FontAwesomeIcon icon={(!!value ? "check": "times")} /></span>
                  )
            },
            {
                headerName: "Inscrito em", field: "inw_datainscricao", width: 30,
                cellRendererFramework: ({ value }) => (
                    <span>{moment(new Date(value)).format("DD/MM/YYYY")}</span>
                  )
              }
          ],
          newsletterLinhaSelecionada: null,
          filtro: null,
          filtroInscritos: null
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        params.api.showLoadingOverlay();
    }

    onGridInscritosReady = params => {
        this.gridInscritosApi = params.api;
        this.gridInscritosApi.sizeColumnsToFit();
        params.api.showLoadingOverlay();
    }

    onNewsletterSelectionChanged() {
        this.setState({ newsletterLinhaSelecionada: this.gridApi.getSelectedRows() })
    }

    onInscritoNewsletterSelectionChanged() { }

    handleChange = (e) => {
        if (e.target.name === 'filtro') this.gridApi.setQuickFilter(e.target.value);
        if (e.target.name === 'filtroInscritos') this.gridInscritosApi.setQuickFilter(e.target.value);
        this.setState(
          {
            [e.target.name]: e.target.value
          }
        );
    }

    onDelete = () => {
        Swal.fire({
          title: "Confirma Exclusão?",
          type: "warning",
          text: "Você deseja realmente excluir o registro ?",
          footer: "",
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#c0c0c0',
          confirmButtonText: 'Sim, Excluir!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.value) {
            client.mutate({
              refetchQueries: ["NewslatterList"],
              mutation: NEWSLETTER_DELETE_ONE,
              variables: {
                id: this.state.newslatterLinhaSelecionada[0].nws_codigo
              }
            })
          }
        });
      }

    renderNewsletter = () => {
        const onEdit = () => {
            this.props.history.push("/newsletter/manutencao-newsletter/" + this.state.newsletterLinhaSelecionada[0].nws_codigo)
        }
        return (
            <div className="colRow">
                <div className="colButtons">
                    <Link to="/newsletter/manutencao-newsletter">
                        <Button size="sm" variant="success">
                            <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Adicionar
                        </Button>
                    </Link>
                    {!!this.state.newsletterLinhaSelecionada && <span>
                    <Button variant="primary" size="sm" onClick={onEdit}>
                        <FontAwesomeIcon icon="edit" />&nbsp;&nbsp;Editar
                    </Button>
                    <Button variant="secondary" size="sm" onClick={this.onDelete}>
                        <FontAwesomeIcon icon="trash" />&nbsp;&nbsp;Excluir
                    </Button>
                    </span>}
                </div>
                <div className="colRow mt-2">
                    <InputFiltro size="sm" name="filtro" handleChange={this.handleChange} value={this.state.filtro} placeholder={"Pesquisar por assunto da Newslatter..."} />
                </div>
                <div className="ag-theme-balham">
                    <Query
                        query={NEWSLETTER_ALL}
                        variables={{ psv_codigo: getConfig().idPortal, query: this.state.filtro }}
                    >{({ data, loading, refetch, error }) => (
                        <React.Fragment>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            columnDefs={this.state.newsletterColumnDefs}
                            rowData={_.get(data, "psv_newsletter.list", [])}
                            rowSelection="single"
                            loadingOverlayComponentFramework={() => <div className={"loading-div"}>Carregando...</div>}
                            onSelectionChanged={this.onNewsletterSelectionChanged.bind(this)}>
                        </AgGridReact>
                        <ReenterRouteListener callback={refetch} />
                        </React.Fragment>
                    )}</Query>
                </div>
            </div>
        )
    }

    renderInscritos = () => {
        return (
            <div className="colRow">
                <div className="colRow mt-2">
                    <InputFiltro size="sm" name="filtroInscritos" handleChange={this.handleChange} value={this.state.filtroInscritos} placeholder={"Pesquisar por nome ou e-mail do inscrito..."} />
                </div>
                <div className="ag-theme-balham">
                    <Query
                        query={INSCRITOSNEWSLETTER_ALL}
                        variables={{ portalId: getConfig().idPortal, query: this.state.filtro }}
                    >{({ data, loading, refetch, error }) => (
                        <React.Fragment>
                        <AgGridReact
                            onGridReady={this.onGridInscritosReady}
                            columnDefs={this.state.inscritosNewsletterColumnDefs}
                            rowData={_.get(data, "psv_inscritosnewsletter.list", [])}
                            rowSelection="single"
                            loadingOverlayComponentFramework={() => <div className={"loading-div"}>Carregando...</div>}
                            onSelectionChanged={this.onInscritoNewsletterSelectionChanged.bind(this)}>
                        </AgGridReact>
                        <ReenterRouteListener callback={refetch} />
                        </React.Fragment>
                    )}</Query>
                </div>
            </div>
        )
    }

  render() {
    return (
        <div className="colPageAdm">
            <div className="colRow">
                <Tabs defaultActiveKey="newsletter" id="tabsNewsletter" onSelect={key => {
                    if (key === "inscritos") this.gridInscritosApi.sizeColumnsToFit();
                }}>
                    <Tab eventKey="newsletter" title="Newsletter">
                        {this.renderNewsletter()}
                    </Tab>
                    <Tab eventKey="inscritos" title="Lista de Inscritos">
                        {this.renderInscritos()}
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
  }
}

export default Newsletter
