/* eslint-disable no-undef */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Query } from "react-apollo";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { InputFiltro } from "../../components";
import { client } from "../apollo";
import { ALBUM_ALL, ALBUM_DELETE_ONE } from "./AlbumGql";
import ReenterRouteListener from "./components/ReenterRouteListener";
class Albuns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Id",
          field: "alb_codigo",
          width: 15
        },
        {
          headerName: "Nome",
          field: "alb_nome"
        },
        {
          headerName: "Data",
          field: "alb_data",
          width: 30,
          cellRendererFramework: ({ value }) => (
            <span>{moment(new Date(value)).format("DD/MM/YYYY")}</span>
          )
        },
        {
          headerName: "Capa",
          field: "arq_capa",
          width: 30,
          cellRendererFramework: ({ value }) => (
            <img src={value.psv_url} className={"img-responsive"} height={70} alt={"Foto capa"} />
          )
        }
      ],
      listaMenuLinhaSelecionada: null,
      filtro: null
    };
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

  };

  onSelectionChanged() {
    this.setState({
      listaMenuLinhaSelecionada: this.gridApi.getSelectedRows()
    });
  }

  handleChange = e => {
    if (e.target.name === "filtro") this.gridApi.setQuickFilter(e.target.value);
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onDelete = () => {
    Swal.fire({
      title: "Confirma Exclusão?",
      type: "warning",
      text: "Você deseja realmente excluir o registro ?",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#c0c0c0",
      confirmButtonText: "Sim, Excluir!",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        client.mutate({
          refetchQueries: ["AlbumList"],
          mutation: ALBUM_DELETE_ONE,
          variables: {
            id: this.state.listaMenuLinhaSelecionada[0].alb_codigo
          }
        });
      }
    });
  };

  render() {
    const onEdit = () => {
      this.props.history.push(
        "/albuns/manutencao-album/" +
        this.state.listaMenuLinhaSelecionada[0].alb_codigo
      );
    };
    return (
      <div className="colPageAdm">
        <div className="colRow">
          <div className="colRow p-relative">
            <h3>Listagem de Álbuns</h3>
            <div className="detalhe"></div>
          </div>
          <div className="colButtons">
            <Link to="/albuns/manutencao-album">
              <Button size="sm" variant="success">
                <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Adicionar
              </Button>
            </Link>
            {!!this.state.listaMenuLinhaSelecionada && <span>
              <Button variant="primary" size="sm" onClick={onEdit}>
                <FontAwesomeIcon icon="edit" />&nbsp;&nbsp;Editar
              </Button>
              <Button variant="secondary" size="sm" onClick={this.onDelete}>
                <FontAwesomeIcon icon="trash" />&nbsp;&nbsp;Excluir
              </Button>
            </span>}
          </div>
          <div className="colRow">
            <InputFiltro size="sm" name="filtro" handleChange={this.handleChange} value={this.state.filtro} />
          </div>
        </div>
        <div className="ag-theme-balham">
          <Query query={ALBUM_ALL} variables={{ query: this.state.filtro }}>
            {({ data, loading, refetch, error }) => (
              <React.Fragment>

                <AgGridReact

                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  rowData={_.get(data, "psv_albuns.list", [])}
                  rowSelection="single"
                  rowHeight={70}
                  onSelectionChanged={this.onSelectionChanged.bind(this)}
                />
                <ReenterRouteListener callback={refetch} />
              </React.Fragment>
            )}
          </Query>
        </div>
      </div>
    );
  }
}

export default Albuns;